import { CWOPA_ROLES, BUSINESS_PARTNER_ROLES } from "@/rbac/roles.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_ROLES = [
  CWOPA_ROLES.BMS_BROWSER,
  CWOPA_ROLES.BMS_BROWSER_INSPECTION,
  CWOPA_ROLES.BMS_BROWSER_INVENTORY,
  CWOPA_ROLES.BMS_INSPECTOR,
  CWOPA_ROLES.BMS_INSPECTOR_SUPERVISOR,
  CWOPA_ROLES.BMS_POWERUSER,
  CWOPA_ROLES.BMS_SUPERUSER,
  BUSINESS_PARTNER_ROLES.BMSBP_BROWSER,
  BUSINESS_PARTNER_ROLES.BMSBP_BROWSER_INSPECTION,
  BUSINESS_PARTNER_ROLES.BMSBP_BROWSER_INVENTORY,
  BUSINESS_PARTNER_ROLES.BMSBP_CONSULTANT_INSPECTOR,
  BUSINESS_PARTNER_ROLES.BMSBP_CONSULTANT_INSPECTOR_SUPERVISOR,
  BUSINESS_PARTNER_ROLES.BMSBP_CONSULTANT_OWNER_AGENT,
  BUSINESS_PARTNER_ROLES.BMSBP_GOVT_BRIDGE_OWNER,
  BUSINESS_PARTNER_ROLES.BMSBP_GOVT_BRIDGE_OWNER_AGENT,
  BUSINESS_PARTNER_ROLES.BMSBP_GOVT_BRIDGE_OWNER_INSPECTOR,
  BUSINESS_PARTNER_ROLES.BMSBP_GOVT_BRIDGE_OWNER_INSPECTOR_SUPERVISOR,
  BUSINESS_PARTNER_ROLES.BMSBP_PRIV_BRIDGE_OWNER,
  BUSINESS_PARTNER_ROLES.BMSBP_PRIV_BRIDGE_OWNER_AGENT,
  BUSINESS_PARTNER_ROLES.BMSBP_PRIV_BRIDGE_OWNER_INSPECTOR,
  BUSINESS_PARTNER_ROLES.BMSBP_PRIV_BRIDGE_OWNER_INSPECTOR_SUPERVISOR,
];

const EDIT_ROLES = [
  CWOPA_ROLES.BMS_INSPECTOR,
  CWOPA_ROLES.BMS_INSPECTOR_SUPERVISOR,
  CWOPA_ROLES.BMS_POWERUSER,
  CWOPA_ROLES.BMS_SUPERUSER,
  BUSINESS_PARTNER_ROLES.BMSBP_CONSULTANT_INSPECTOR_SUPERVISOR,
  BUSINESS_PARTNER_ROLES.BMSBP_CONSULTANT_OWNER_AGENT,
  BUSINESS_PARTNER_ROLES.BMSBP_GOVT_BRIDGE_OWNER,
  BUSINESS_PARTNER_ROLES.BMSBP_GOVT_BRIDGE_OWNER_AGENT,
  BUSINESS_PARTNER_ROLES.BMSBP_GOVT_BRIDGE_OWNER_INSPECTOR_SUPERVISOR,
  BUSINESS_PARTNER_ROLES.BMSBP_PRIV_BRIDGE_OWNER,
  BUSINESS_PARTNER_ROLES.BMSBP_PRIV_BRIDGE_OWNER_AGENT,
  BUSINESS_PARTNER_ROLES.BMSBP_PRIV_BRIDGE_OWNER_INSPECTOR_SUPERVISOR,
];

const EDIT_VERTICAL_CLR = [
  CWOPA_ROLES.BMS_POWERUSER,
  CWOPA_ROLES.BMS_SUPERUSER,
];

export const INVENTORY_FEATURES_PERMITTED_ROLES = {
  [ACCESS_LEVELS.VIEW]: [...VIEW_ROLES],
  [ACCESS_LEVELS.EDIT]: [...EDIT_ROLES],
  editVerticalClearance: [...EDIT_VERTICAL_CLR],
};

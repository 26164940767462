import { defineStore } from "pinia";
import { ref } from "vue";
import { INSPECTION_LINKS_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import { clone } from "@/util/clone";
import { MODULE_TYPES } from "@/constants/InspectionConstants";

export const useApproachStore = defineStore("approach", () => {
  //data is loaded either from idb(pouch db for inspection module) or service(for management module)
  let pageData = ref({});
  let isSearching = ref(false);

  const loadDataObject = async (moduleType, brkey, inspectionId, structure) => {
    if (moduleType === MODULE_TYPES.INSPECTION) {
      //if moduleType is inspection, load data from pouchDB
      convertToInspModuleObject(structure);
    } else if (moduleType === MODULE_TYPES.MANAGEMENT && brkey && inspectionId) {
      //if moduleType is management, load data from service call
      await getInspLinkDataObject(brkey, inspectionId);
    }
  };

  const getApproachObject = () => {
    return clone(pageData.value);
  };

  const saveData = async (moduleType, value, structure) => {
    let isSuccess = false;
    if (moduleType === MODULE_TYPES.INSPECTION) {
      convertToSaveInspModuleObject(value, structure);
      isSuccess = true;
    } else if (moduleType === MODULE_TYPES.MANAGEMENT) {
      isSuccess = await updateData(convertToSaveInspLinkObject(value));
    }
    return isSuccess;
  };

  const updateData = async (payload) => {
    let isSuccess = true;
    const url = INSPECTION_LINKS_ENDPOINT.UPDATE_APPROACH;
    await axios
      .put(url, payload)
      .then((response) => {
        LOGGER.logEvent(LOGGER.EventAction.Update, LOGGER.EventCat.RequestResponse, "Update Approach", response);
        convertToInspLinkObject(response.data.data);
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const convertToSaveInspLinkObject = (approach) => {
    return {
      brkey: approach.BRKEY,
      inspectionId: approach.INSPECTIONID,
      inspectionComments: approach.inspectionComments,
      apprAlignDuration: approach.APPRALIGN_DURATION,
      apprRoadwayDuration: approach.APPR_ROADWAY_DURATION,
      apprSlabDuration: approach.APPR_SLAB_DURATION,
      railRatingDuration: approach.RAILRATING_DURATION,
      transRatinDuration: approach.TRANSRATIN_DURATION,
      apprPavementWidth: approach.APPR_PAVEMENT_WIDTH,
      apprAlign: approach.APPRALIGN,
      apprRoadCondRate: approach.APPR_ROAD_COND_RATE,
      apprSlabCondRate: approach.APPR_SLAB_COND_RATE,
      railRating: approach.RAILRATING,
      transRating: approach.TRANSRATIN,
      reliefJoint: approach.RELIEF_JOINT,
      structureSignings: convertToSaveInspLinkSignsObject(approach.SIGNS),
      sfDescBridgeRailings: approach.BRIDGE_RAILING_DESC,
      sfDescTransitions: approach.TRANSITION_DESC,
      crashLevelBridgeRailings: approach.CRASH_LEVEL_BRIDGE_RAILINGS,
      crashLevelTransitions: approach.CRASH_LEVEL_TRANSITIONS,
    };
  };

  const convertToSaveInspModuleObject = (approachValue, structure) => {
    structure.T_Insp_Condition_Hist.APPRALIGN_DURATION = approachValue.APPRALIGN_DURATION;
    structure.T_Insp_Condition_Hist.APPR_ROADWAY_DURATION = approachValue.APPR_ROADWAY_DURATION;
    structure.T_Insp_Condition_Hist.APPR_SLAB_DURATION = approachValue.APPR_SLAB_DURATION;
    structure.T_Insp_Condition_Hist.RAILRATING_DURATION = approachValue.RAILRATING_DURATION;
    structure.T_Insp_Condition_Hist.TRANSRATIN_DURATION = approachValue.TRANSRATIN_DURATION;
    structure.T_Insp_Condition_Hist.ARAILRATIN_DURATION = approachValue.ARAILRATIN_DURATION;
    structure.T_Insp_Condition_Hist.AEBDRATING_DURATION = approachValue.AEBDRATING_DURATION;
    structure.UserBrdg.APPR_PAVEMENT_WIDTH = approachValue.APPR_PAVEMENT_WIDTH;
    structure.InspEvnt.APPRALIGN = approachValue.APPRALIGN;
    structure.UserInsp.APPR_ROAD_COND_RATE = approachValue.APPR_ROAD_COND_RATE;
    structure.UserInsp.APPR_SLAB_COND_RATE = approachValue.APPR_SLAB_COND_RATE;
    structure.InspEvnt.RAILRATING = approachValue.RAILRATING;
    structure.InspEvnt.TRANSRATIN = approachValue.TRANSRATIN;
    structure.InspEvnt.ARAILRATIN = approachValue.ARAILRATIN;
    structure.InspEvnt.AENDRATING = approachValue.AENDRATING;
    structure.UserBrdg.RELIEF_JOIN = approachValue.RELIEF_JOINT;
    //save only the undeleted signs
    structure.T_Signing = approachValue.SIGNS?.filter((s) => !s?.deleted);
  };

  const convertToInspModuleObject = (structure) => {
    pageData.value = {
      APPRALIGN_DURATION: structure.T_Insp_Condition_Hist.APPRALIGN_DURATION,
      APPR_ROADWAY_DURATION: structure?.T_Insp_Condition_Hist?.APPR_ROADWAY_DURATION,
      APPR_SLAB_DURATION: structure?.T_Insp_Condition_Hist?.APPR_SLAB_DURATION,
      RAILRATING_DURATION: structure?.T_Insp_Condition_Hist?.RAILRATING_DURATION,
      TRANSRATIN_DURATION: structure?.T_Insp_Condition_Hist?.TRANSRATIN_DURATION,
      APPR_PAVEMENT_WIDTH: structure.UserBrdg.APPR_PAVEMENT_WIDTH,
      APPRALIGN: structure.InspEvnt.APPRALIGN,
      APPR_ROAD_COND_RATE: structure.UserInsp.APPR_ROAD_COND_RATE,
      APPR_SLAB_COND_RATE: structure.UserInsp.APPR_SLAB_COND_RATE,
      RAILRATING: structure.InspEvnt.RAILRATING,
      TRANSRATIN: structure.InspEvnt.TRANSRATIN,
      RELIEF_JOINT: structure.UserBrdg?.RELIEF_JOINT,
      SIGNS: structure.T_Signing,
    };
  };

  const getInspLinkDataObject = async (brkey, inspectionId) => {
    //clear idb object
    pageData.value.inspection = {};
    isSearching.value = true;
    let url = INSPECTION_LINKS_ENDPOINT.GET_APPROACH.replace("{brkey}", brkey).replace("{inspectionId}", inspectionId);

    await axios
      .get(url)
      .then((resp) => {
        isSearching.value = false;
        convertToInspLinkObject(resp.data.data);
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSearching.value = false;
      });
    isSearching.value = false;
  };

  const convertToInspLinkObject = (response) => {
    pageData.value = {
      BRKEY: response.brkey,
      INSPECTIONID: response.inspectionId,
      APPRALIGN_DURATION: response.apprAlignDuration,
      APPR_ROADWAY_DURATION: response.apprRoadwayDuration,
      APPR_SLAB_DURATION: response.apprSlabDuration,
      RAILRATING_DURATION: response.railRatingDuration,
      TRANSRATIN_DURATION: response.transRatinDuration,
      APPR_PAVEMENT_WIDTH: response.apprPavementWidth,
      APPRALIGN: response.apprAlign,
      APPR_ROAD_COND_RATE: response.apprRoadCondRate,
      APPR_SLAB_COND_RATE: response.apprSlabCondRate,
      RAILRATING: response.railRating,
      TRANSRATIN: response.transRating,
      RELIEF_JOINT: response.reliefJoint,
      SIGNS: convertToInspLinkSignsObject(getUnDeletedSigns(response.structureSignings)),
      BRIDGE_RAILING_DESC: response.sfDescBridgeRailings,
      TRANSITION_DESC: response.sfDescTransitions,
      inspectionComments: response.inspectionComments,
      CRASH_LEVEL_BRIDGE_RAILINGS: response.crashLevelBridgeRailings,
      CRASH_LEVEL_TRANSITIONS: response.crashLevelTransitions,
    };
  };

  const getUnDeletedSigns = (signs) => {
    return signs?.filter((s) => !s.deleted);
  };

  const convertToInspLinkSignsObject = (structureSignings) => {
    return structureSignings?.map((structureSigning) => {
      return {
        ID: structureSigning.signingId,
        BRKEY: structureSigning.brkey,
        INSPKEY: structureSigning.inspectionId,
        SIGN_TYPE: structureSigning.signType,
        FAR_ADVANCE_COND: structureSigning.farAdvanceCond,
        FAR_SITE_COND: structureSigning.farSiteCond,
        NEAR_ADVANCE_COND: structureSigning.nearAdvanceCond,
        NEAR_SITE_COND: structureSigning.nearSiteCond,
        NOTES: structureSigning.notes,
        SIGN_MESSAGE: structureSigning.signMessage,
        SIGN_NEEDED: structureSigning.signNeeded,
        updateDateTime: structureSigning.updateDateTime,
        updateUserId: structureSigning.updateUserId,
        createdDateTime: structureSigning.createdDateTime,
        createdUserId: structureSigning.createdUserId,
        deleted: structureSigning.deleted,
      };
    });
  };

  const convertToSaveInspLinkSignsObject = (structureSignings) => {
    return structureSignings?.map((structureSigning) => {
      return {
        signingId: structureSigning?.ID || null,
        brkey: structureSigning.BRKEY,
        inspectionId: structureSigning.INSPKEY,
        signType: structureSigning.SIGN_TYPE,
        farAdvanceCond: structureSigning.FAR_ADVANCE_COND,
        farSiteCond: structureSigning.FAR_SITE_COND,
        nearAdvanceCond: structureSigning.NEAR_ADVANCE_COND,
        nearSiteCond: structureSigning.NEAR_SITE_COND,
        notes: structureSigning.NOTES,
        signMessage: structureSigning.SIGN_MESSAGE,
        signNeeded: structureSigning.SIGN_NEEDED,
        deleted: structureSigning.deleted || null,
      };
    });
  };

  return {
    getApproachObject,
    saveData,
    loadDataObject,
  };
});

<template>
  <v-row>
    <v-col sm="12" md="12" lg="12" xl="12" class="pa-0">
      <ExpandCollapseToggle
        id="schedulePanelToggle"
        @toggleAllPanels="toggleAllPanels"
        styleClass="mt-3"
        :panel="panel"
      />
      <v-form v-model="valid" ref="modifyInspectionScheduleForm">
        <v-expansion-panels v-model="panel" multiple color="#fff">
          <v-expansion-panel style="margin-top: 20px">
            <v-expansion-panel-title
              class="d-flex justify-start"
              hide-actions
              expand-icon="fa-solid fa-plus"
              collapse-icon="fa-solid fa-minus"
            >
              <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
              <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
              <h3>SNBI Inspection Types Performed</h3>
            </v-expansion-panel-title>

            <v-expansion-panel-text eager>
              <v-row>
                <v-col class="px-0">
                  <v-btn
                    :disabled="!isEditable || anyEditable"
                    :id="`btn_add_insp_type`"
                    variant="outlined"
                    class="my-2 ml-1"
                    @click="addInspType"
                    >Add Inspection Type</v-btn
                  >
                </v-col>
              </v-row>
              <v-row class="mb-8">
                <v-col cols="12">
                  <v-data-table
                    id="inspectionTypes-data-table"
                    v-model:expanded="expandedRows"
                    :items-per-page="-1"
                    :headers="inspTypeHeaders"
                    :items="inspectionTypes"
                    class="compactTable lessPaddingTableColumns"
                    v-model:sort-by="sortBy"
                    hide-default-footer
                    must-sort
                    show-expand
                    return-object
                  >
                    <template v-slot:[`header.data-table-expand`]="{ column }">
                      {{ column.title }}
                    </template>
                    <template v-slot:[`header.INSP_TYPE`]="{ column }">
                      <DataTableHeader :column="column" :sortBy="sortBy" />
                    </template>
                    <template v-slot:[`header.START_DATE`]="{ column }">
                      <DataTableHeader :column="column" :sortBy="sortBy" />
                    </template>
                    <template v-slot:[`header.END_DATE`]="{ column }">
                      <DataTableHeader :column="column" :sortBy="sortBy" />
                    </template>
                    <template v-slot:[`header.TEAM_LEADER`]="{ column }">
                      <DataTableHeader :column="column" :sortBy="sortBy" />
                    </template>
                    <template v-slot:[`header.INSP_INTERVAL`]="{ column }">
                      <DataTableHeader :column="column" :sortBy="sortBy" />
                    </template>
                    <template v-slot:[`header.DUE_DATE`]="{ column }">
                      <DataTableHeader :column="column" :sortBy="sortBy" />
                    </template>
                    <template v-slot:[`header.RBM`]="{ column }">
                      <DataTableHeader :column="column" :sortBy="sortBy" />
                    </template>
                    <template
                      v-if="isEditable"
                      v-slot:[`header.action`]="{ column }"
                    >
                      <DataTableHeader :column="column" :sortBy="sortBy" />
                    </template>
                    <template v-slot:item="{ item }">
                      <tr>
                        <td :class="rowClass(item) + ' align-center'">
                          <template
                            v-if="expandedRows?.length == 0 || !anyEditable"
                          >
                            <button
                              v-if="!expandedRows.includes(item)"
                              @click="toggleRow(item)"
                              aria-label="toggleRowButtonOpen"
                            >
                              <v-icon
                                :id="`icon_Chevron_Right_${item.INSP_TYPE}`"
                                icon="fa-solid fa-chevron-right"
                              ></v-icon>
                            </button>

                            <button
                              v-if="expandedRows.includes(item)"
                              @click="toggleRow(item)"
                              aria-label="toggleRowButtonClosed"
                            >
                              <v-icon
                                :id="`icon_Chevron_Down_${item.INSP_TYPE}`"
                                icon="fa-solid fa-chevron-down"
                              ></v-icon>
                            </button>
                          </template>
                        </td>
                        <td :class="rowClass(item) + ' align-center'">
                          {{
                            item.INSP_TYPE
                              ? configStore.getReferenceValue(
                                  REFERENCE_TABLE.SNBI_INSPECTION_TYPE,
                                  item.INSP_TYPE
                                )
                              : ""
                          }}
                        </td>
                        <td :class="rowClass(item) + ' align-center'">
                          {{ getFormattedDateStringNoTime(item.START_DATE) }}
                        </td>
                        <td :class="rowClass(item) + ' align-center'">
                          {{ getFormattedDateStringNoTime(item.END_DATE) }}
                        </td>
                        <td :class="rowClass(item) + ' align-center'">
                          {{
                            item.TEAM_LEADER
                              ? getTeamLeaders(
                                  item.INSP_TYPE ==
                                    INSPECTION_TYPE_PERFORMED.NSTM
                                ).find((t) => t.value === item.TEAM_LEADER)
                                  ?.title
                              : ""
                          }}
                        </td>
                        <td :class="rowClass(item) + ' align-center'">
                          {{
                            !["Z", "1", "5", "9", "P", "Q"].includes(
                              item.INSP_TYPE
                            )
                              ? item.INSP_INTERVAL
                              : ""
                          }}
                        </td>
                        <td :class="rowClass(item) + ' align-center'">
                          {{ getFormattedDateStringNoTime(item.DUE_DATE) }}
                        </td>
                        <td :class="rowClass(item) + ' align-center'">
                          {{
                            item.RBM
                              ? configStore.getReferenceValue(
                                  REFERENCE_TABLE.RBI_METHOD,
                                  item.RBM
                                )
                              : ""
                          }}
                        </td>
                        <td
                          :class="rowClass(item) + ' align-center'"
                          v-if="isEditable"
                        >
                          <div
                            style="white-space: nowrap"
                            v-if="isExpanded(item) && anyEditable && isEditable"
                          >
                            <IconTooltip
                              id="icon_add"
                              icon="fa-solid fa-check"
                              :tooltip="item.isAdd ? 'Add' : 'Update'"
                              @click="saveInspType(item)"
                            />
                            <IconTooltip
                              id="icon_close"
                              icon="fa-solid fa-xmark"
                              tooltip="Close"
                              @click="closeInspType"
                            />
                          </div>
                          <div
                            style="white-space: nowrap"
                            v-else-if="
                              isEditable &&
                              (expandedRows?.length == 0 || !anyEditable)
                            "
                          >
                            <IconTooltip
                              id="icon_edit"
                              icon="fa-solid fa-pencil"
                              tooltip="Edit"
                              @click="editInspType(item)"
                            />
                            <IconTooltip
                              id="icon_delete"
                              icon="fa-solid fa-trash"
                              tooltip="Delete"
                              @click="deleteInspType(item)"
                            />
                          </div>
                        </td></tr
                    ></template>
                    <template v-slot:expanded-row="{ item }">
                      <td :colspan="inspTypeHeaders.length">
                        <v-form
                          ref="inspTypeDetailForm"
                          v-model="validDetails"
                          class="mt-2"
                          @change="changesDetected = true"
                        >
                          <v-row>
                            <v-col xl="6" lg="6" class="px-0">
                              <LabelSelect
                                labelXl="6"
                                labelLg="6"
                                labelMd="6"
                                labelSm="6"
                                selectXl="6"
                                selectLg="6"
                                selectMd="6"
                                selectSm="6"
                                id="inspectionType"
                                label="B.IE.01 Inspection Type"
                                :options="getAvailableSnbiTypes()"
                                :refTable="REFERENCE_TABLE.SNBI_INSPECTION_TYPE"
                                :isEditable="
                                  isEditable && !item.readOnly && item.isAdd
                                "
                                :isRequired="isEditable && !item.readOnly"
                                v-model="item.INSP_TYPE"
                                @update:modelValue="
                                  setInspTypeFieldDependencies(item)
                                "
                              ></LabelSelect>
                              <LabelDatefield
                                labelXl="6"
                                labelLg="6"
                                labelMd="6"
                                labelSm="6"
                                textXl="6"
                                textLg="6"
                                textMd="6"
                                textSm="6"
                                id="txt_snbi_start_date"
                                label="B.IE.02 Inspection Start Date"
                                v-model="item.START_DATE"
                                :isEditable="isEditable && !item.readOnly"
                                :isRequired="isEditable && !item.readOnly"
                                @update:modelValue="updateEndDate(item)"
                              >
                              </LabelDatefield>
                              <LabelDatefield
                                labelXl="6"
                                labelLg="6"
                                labelMd="6"
                                labelSm="6"
                                textXl="6"
                                textLg="6"
                                textMd="6"
                                textSm="6"
                                id="txt_snbi_end_date"
                                label="B.IE.03 Inspection End Date"
                                v-model="item.END_DATE"
                                :isEditable="isEditable && !item.readOnly"
                                :isRequired="isEditable && !item.readOnly"
                                @update:modelValue="setInspTypeDueDate(item)"
                                :minDate="
                                  item.START_DATE
                                    ? new Date(item.START_DATE)
                                    : new Date()
                                "
                              >
                              </LabelDatefield>
                              <LabelSelect
                                labelXl="6"
                                labelLg="6"
                                labelMd="6"
                                labelSm="6"
                                selectXl="6"
                                selectLg="6"
                                selectMd="6"
                                selectSm="6"
                                id="select_snbi_teamleader"
                                label="B.IE.04 NCBI (Team Leader)"
                                :options="
                                  getTeamLeaders(
                                    item.INSP_TYPE ==
                                      INSPECTION_TYPE_PERFORMED.NSTM
                                  )
                                "
                                :isEditable="isEditable && !item.readOnly"
                                :isRequired="isEditable && !item.readOnly"
                                v-model="item.TEAM_LEADER"
                                @update:modelValue="changesDetected = true"
                              ></LabelSelect>
                              <LabelText
                                labelXl="6"
                                labelLg="6"
                                labelMd="6"
                                labelSm="6"
                                textXl="6"
                                textLg="6"
                                textMd="6"
                                textSm="6"
                                id="txt_snbi_interval"
                                label="B.IE.05 Inspection Interval"
                                v-model="item.INSP_INTERVAL"
                                type="number"
                                :maxlength="2"
                                appendWith=" mos"
                              ></LabelText>
                            </v-col>
                            <v-col xl="6" lg="6" class="px-0">
                              <LabelDatefield
                                labelXl="6"
                                labelLg="6"
                                labelMd="6"
                                labelSm="6"
                                textXl="6"
                                textLg="6"
                                textMd="6"
                                textSm="6"
                                id="txt_snbi_due_date"
                                label="B.IE.06 Inspection Due Date"
                                v-model="item.DUE_DATE"
                              >
                              </LabelDatefield>
                              <LabelSelect
                                labelXl="6"
                                labelLg="6"
                                labelMd="6"
                                labelSm="6"
                                selectXl="6"
                                selectLg="6"
                                selectMd="6"
                                selectSm="6"
                                id="select_snbi_rbi"
                                label="B.IE.07 RBI Method"
                                :refTable="REFERENCE_TABLE.RBI_METHOD"
                                v-model="item.RBM"
                              ></LabelSelect>
                              <LabelDatefield
                                labelXl="6"
                                labelLg="6"
                                labelMd="6"
                                labelSm="6"
                                textXl="6"
                                textLg="6"
                                textMd="6"
                                textSm="6"
                                id="txt_snbi_quality_control_date"
                                label="B.IE.08 Quality Control Date"
                                v-model="item.QC_DATE"
                              >
                              </LabelDatefield>
                              <LabelDatefield
                                labelXl="6"
                                labelLg="6"
                                labelMd="6"
                                labelSm="6"
                                textXl="6"
                                textLg="6"
                                textMd="6"
                                textSm="6"
                                id="txt_snbi_modified_date"
                                label="B.IE.10 Modified Date"
                                v-model="item.MOD_DATE"
                              >
                              </LabelDatefield> </v-col
                          ></v-row>
                          <v-row>
                            <v-col cols="12">
                              <LabelText
                                labelXl="3"
                                labelLg="3"
                                labelMd="3"
                                labelSm="3"
                                textXl="9"
                                textLg="9"
                                textMd="9"
                                textSm="9"
                                type="textarea"
                                label="B.IE.11 Limited Scope Description"
                                id="txt_scope_desc"
                                v-model="item.SCOPE_NOTE"
                                :isEditable="isEditable && !item.readOnly"
                                :counter="300"
                                :maxlength="300"
                                :isRequired="
                                  !!item.INSP_TYPE &&
                                  !['Z', '1', '2', 'E', 'Q'].includes(
                                    item.INSP_TYPE
                                  )
                                "
                                @update:modelValue="changesDetected = true"
                                style="padding-right: 3px !important"
                              />
                            </v-col>
                          </v-row>
                          <v-row
                            ><v-col
                              cols="12"
                              class="px-0"
                              style="white-space: break-word"
                            >
                              <LabelText
                                labelXl="3"
                                labelLg="3"
                                labelMd="3"
                                labelSm="3"
                                textXl="9"
                                textLg="9"
                                textMd="9"
                                textSm="9"
                                label="B.IE.12 Inspection Equipment"
                                id="txt_insp_equip"
                                v-model="item.EQUIP_CONCAT"
                                :isEditable="false"
                                class="wrapValue"
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              v-if="
                                equipmentError.message?.length > 0 &&
                                equipmentError.message.find((a) =>
                                  a.includes('Access')
                                ) &&
                                !item.readonly
                              "
                              class="errorMessage"
                            >
                              {{
                                equipmentError.message.find((a) =>
                                  a.includes("Access")
                                )
                              }}
                            </v-col>
                            <v-col class="align-center sub-section-header">
                              Access Equipment (Check all that apply, at least
                              one is required)
                            </v-col>
                          </v-row>
                          <v-row>
                            <div
                              v-for="accessEquip in accessEquipment"
                              :key="accessEquip.value"
                              style="float: left; width: 12%"
                              class="equipTable"
                            >
                              <v-checkbox
                                :id="`checkbox_access_equip_${accessEquip.value}`"
                                :aria-describedby="`checkbox_access_equip_${accessEquip.value}`"
                                :aria-label="accessEquip.title"
                                :value="accessEquip.value"
                                v-model="item.selectedEquipment"
                                :label="accessEquip.title"
                                hide-details
                                @update:model-value="updateEquipment(item)"
                                :disabled="item.readOnly"
                              />
                            </div>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              v-if="
                                equipmentError.message?.length > 0 &&
                                equipmentError.message.find((a) =>
                                  a.includes('Inspection')
                                ) &&
                                !item.readonly
                              "
                              class="errorMessage"
                            >
                              {{
                                equipmentError.message.find((a) =>
                                  a.includes("Inspection")
                                )
                              }}
                            </v-col>
                            <v-col class="align-center sub-section-header">
                              Inspection Equipment (Check all that apply, at
                              least one is required)
                            </v-col>
                          </v-row>
                          <v-row>
                            <div
                              v-for="inspEquip in inspectionEquipment"
                              :key="inspEquip.value"
                              style="float: left; width: 11%"
                              class="equipTable"
                            >
                              <v-checkbox
                                :id="`checkbox_access_equip_${inspEquip.value}`"
                                :aria-describedby="`checkbox_access_equip_${inspEquip.value}`"
                                :aria-label="inspEquip.title"
                                :value="inspEquip.value"
                                v-model="item.selectedEquipment"
                                :label="inspEquip.title"
                                hide-details
                                @update:model-value="updateEquipment(item)"
                                :disabled="item.readOnly"
                              />
                            </div>
                          </v-row>
                        </v-form></td
                    ></template>
                    <template v-slot:bottom> </template>
                  </v-data-table> </v-col
              ></v-row> </v-expansion-panel-text
          ></v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              class="d-flex justify-start"
              hide-actions
              expand-icon="fa-solid fa-plus"
              collapse-icon="fa-solid fa-minus"
            >
              <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />
              <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />
              <h3>SNBI Future Scheduling Information</h3>
            </v-expansion-panel-title>

            <v-expansion-panel-text eager>
              <v-row>
                <v-col>
                  <FutureScheduleTable
                    @update-interval="updateInterval"
                    @update-next-date="updateNextDate"
                    ref="futureScheduleTable"
                  />
                </v-col>
              </v-row> </v-expansion-panel-text
          ></v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              class="d-flex justify-start"
              hide-actions
              expand-icon="fa-solid fa-plus"
              collapse-icon="fa-solid fa-minus"
            >
              <v-icon v-if="!panel.includes(2)" icon="fa-solid fa-plus" />

              <v-icon v-if="panel.includes(2)" icon="fa-solid fa-minus" />

              <h3>Inspection Information</h3>
            </v-expansion-panel-title>

            <v-expansion-panel-text eager>
              <v-row dense>
                <v-col sm="12" md="6" lg="6" xl="6">
                  <LabelSelect
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    selectXl="6"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    id="sel_inspection_performed_by"
                    label="7A05 Inspection Performed By"
                    :refTable="REFERENCE_TABLE.INSPECTION_PERFORMED_BY"
                    :isEditable="isEditable"
                    v-model="structure.InspEvnt.INSPECTCONTROLID"
                    isNumericValue
                  ></LabelSelect>

                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="txt_inspection_organization"
                    label="7A05a Inspection Organization"
                    v-model="structure.UserInsp.INSP_ORG_NAME"
                  >
                  </LabelText>

                  <LabelText
                    labelXl="12"
                    labelLg="12"
                    labelMd="12"
                    labelSm="12"
                    textXl="12"
                    textLg="12"
                    textMd="12"
                    textSm="12"
                    id="txt_inspection_team_members"
                    label="6B23 Inspection Team Member(s)"
                    :isEditable="isEditable"
                    v-model="structure.UserInsp.INSP_TEAM_HELPER"
                    maxlength="120"
                    type="textarea"
                    counter
                    persistentCounter
                  >
                  </LabelText>
                  <LabelSelect
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    selectXl="6"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    id="input_underwater_diver_name"
                    label="7A22 Underwater Diver Name"
                    :options="underwaterDivers"
                    :isEditable="isEditable"
                    v-model="structure.UserInsp.UW_DIVER_NAME"
                  ></LabelSelect>
                  <LabelSelect
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    selectXl="6"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    id="input_consultant_hired_By"
                    label="6B24 Consultant Hired By"
                    :refTable="REFERENCE_TABLE.CONSULTANT_HIRED_BY"
                    :isEditable="isEditable"
                    v-model="structure.UserInsp.CONSULTANT_HIRED_BY"
                  ></LabelSelect>

                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="input_inspection_agreement_number"
                    label="6B25 Inspection Agreement Number"
                    :maxlength="6"
                    :counter="false"
                    v-model="structure.UserInsp.LOCAL_INSP_CONTRACT_NUM"
                    :isEditable="isEditable"
                  >
                  </LabelText>
                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="txt_extended_inspection_interval_eligibility"
                    label="7A19 Extended Inspection Interval Eligibility"
                    v-model="extendedInspectionIntervalEligibility"
                  >
                  </LabelText>

                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="txt_extended_inspection_interval_concurrence"
                    label="7A20 Extended Inspection Interval Concurrence"
                    v-model="extendedInspectionIntervalConcurrence"
                  ></LabelText>
                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="txt_extended_inspection_interval_eligibility"
                    label="7A21 NBIS Extended Inspection Interval Eligibility"
                    v-model="methodOneEligibility"
                  ></LabelText>
                </v-col>
                <v-col sm="12" md="6" lg="6" xl="6">
                  <LabelSelect
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    selectXl="6"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    id="input_inaccessible_area"
                    label="6B49 Inaccessible Area"
                    :refTable="REFERENCE_TABLE.INACCESSIBLE_AREA"
                    :isEditable="isEditable"
                    v-model="structure.UserInsp.INACCESS_PORTION_STRUCT"
                  ></LabelSelect>

                  <LabelSelect
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    selectXl="6"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    id="sel_weather_conditions"
                    label="6B09 Weather"
                    :refTable="REFERENCE_TABLE.WEATHER_CONDITION"
                    :isEditable="isEditable"
                    v-model="structure.UserInsp.WEATHER_COND"
                  ></LabelSelect>
                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="input_temperature"
                    :isEditable="isEditable"
                    label="6B12 Temperature"
                    v-model="structure.UserInsp.TEMP"
                    allowNegativeInput
                    type="number"
                    allowDecimalInput
                    :maxlength="6"
                    :decimalPlaces="1"
                    :rules="
                      isEditable
                        ? [
                            (value) => {
                              return valueInRangeRule(value, -50, 200);
                            },
                          ]
                        : []
                    "
                  ></LabelText>
                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="input_nbi_crew_hours"
                    :isEditable="isEditable"
                    label="6B26 NBI Crew Hours"
                    v-model="crewHrs"
                    type="number"
                    allowDecimalInput
                    :maxlength="5"
                    :rules="
                      isEditable
                        ? [
                            (value) => {
                              return valueInRangeRule(value, 0, 999);
                            },
                          ]
                        : []
                    "
                  >
                  </LabelText>
                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="input_crane_hours"
                    :isEditable="isEditable"
                    label="6B27 Crane Hours"
                    v-model="craneHrs"
                    type="number"
                    allowDecimalInput
                    :maxlength="5"
                    :rules="
                      isEditable
                        ? [
                            (value) => {
                              return valueInRangeRule(value, 0, 999);
                            },
                          ]
                        : []
                    "
                  ></LabelText>
                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="input_nstm_hours"
                    :isEditable="isEditable"
                    label="6B28 NSTM Hours"
                    v-model="nstmHrs"
                    type="number"
                    allowDecimalInput
                    :maxlength="5"
                    :rules="
                      isEditable
                        ? [
                            (value) => {
                              return valueInRangeRule(value, 0, 999);
                            },
                          ]
                        : []
                    "
                  ></LabelText>
                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="input_uw_hours"
                    :isEditable="isEditable"
                    label="6B30 UW Hours"
                    v-model="helperHrs"
                    type="number"
                    allowDecimalInput
                    :maxlength="5"
                    :rules="
                      isEditable
                        ? [
                            (value) => {
                              return valueInRangeRule(value, 0, 999);
                            },
                          ]
                        : []
                    "
                  ></LabelText>
                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="input_engineering_costs"
                    :isEditable="isEditable"
                    label="6B32 Engineering Costs"
                    v-model="structure.UserInsp.FIELD_ENG_INSP_COST"
                    type="number"
                    maxlength="6"
                  ></LabelText>
                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="input_rigging_costs"
                    :isEditable="isEditable"
                    label="6B33 Rigging Costs"
                    v-model="structure.UserInsp.RIGGING_INSP_COST"
                    type="number"
                    maxlength="6"
                  ></LabelText>
                  <LabelText
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="input_office_costs"
                    :isEditable="isEditable"
                    label="6B34 Office Costs"
                    v-model="structure.UserInsp.OFFICE_INSP_COST"
                    type="number"
                    maxlength="6"
                  ></LabelText>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              class="d-flex justify-start"
              hide-actions
              expand-icon="fa-solid fa-plus"
              collapse-icon="fa-solid fa-minus"
            >
              <v-icon v-if="!panel.includes(3)" icon="fa-solid fa-plus" />

              <v-icon v-if="panel.includes(3)" icon="fa-solid fa-minus" />

              <h3>Legacy Schedule Information</h3>
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <v-row dense>
                <v-col sm="12" md="6" lg="6" xl="6">
                  <LabelDatefield
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    id="txt_inspection_start_date"
                    label="7A01 Inspection Start Date"
                    v-model="structure.InspEvnt.INSPDATE"
                  >
                  </LabelDatefield>
                  <LabelDatefield
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    textXl="6"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    label="7A01e Inspection End Date"
                    v-model="structure.UserInsp.OVERALL_END_DATE"
                  >
                  </LabelDatefield>

                  <LabelSelect
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    selectXl="6"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    id="sel_team_leader"
                    label="7A02 Team Leader"
                    :options="getTeamLeaders(false)"
                    v-model="structure.InspEvnt.INSPUSRKEY"
                    isNumericValue
                  ></LabelSelect>

                  <LabelSelect
                    labelXl="6"
                    labelLg="7"
                    labelMd="7"
                    labelSm="7"
                    selectXl="6"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    id="sel_primary_inspection_type"
                    label="7A03 Primary Inspection Type"
                    :refTable="REFERENCE_TABLE.INSPECTION_TYPE"
                    v-model="structure.InspEvnt.INSPTYPE"
                    isRequired
                  ></LabelSelect>
                </v-col>

                <v-col v-if="$vuetify?.display?.mdAndUp" md="6" lg="6" xl="6">
                  <v-col class="pa-0 mb-8" cols="12" md="12" lg="12" xl="12">
                    <v-label class="bold">7A06 Inspection Performed:</v-label>

                    <LabelCheckbox
                      labelSm="9"
                      labelMd="9"
                      labelLg="9"
                      labelXl="6"
                      id="checkbox_NBI"
                      label="NBI"
                      v-model="structure.InspEvnt.NBINSPDONE"
                    >
                    </LabelCheckbox>
                    <LabelCheckbox
                      labelSm="9"
                      labelMd="9"
                      labelLg="9"
                      labelXl="6"
                      id="checkbox_NSTM"
                      label="NSTM"
                      v-model="structure.InspEvnt.FCINSPDONE"
                    >
                    </LabelCheckbox>
                    <LabelCheckbox
                      labelSm="9"
                      labelMd="9"
                      labelLg="9"
                      labelXl="6"
                      id="checkbox_Underwater"
                      label="Underwater"
                      v-model="structure.InspEvnt.UWINSPDONE"
                    >
                    </LabelCheckbox>
                    <LabelCheckbox
                      labelSm="9"
                      labelMd="9"
                      labelLg="9"
                      labelXl="6"
                      id="checkbox_Other_Special"
                      label="Other Special"
                      v-model="structure.InspEvnt.OSINSPDONE"
                    >
                    </LabelCheckbox>
                    <LabelCheckbox
                      labelSm="9"
                      labelMd="9"
                      labelLg="9"
                      labelXl="6"
                      id="checkbox_Element"
                      label="Element"
                      v-model="structure.InspEvnt.ELINSPDONE"
                    >
                    </LabelCheckbox>
                  </v-col>

                  <v-col class="pa-0 mb-8" md="12" lg="12" xl="12">
                    <v-table class="schedule-table compactTable">
                      <thead>
                        <tr>
                          <th id="th_none" class="col-none"></th>
                          <th id="th_7A07" class="col-7A07">
                            7A07<br />Required
                          </th>
                          <th id="th_7A08" class="col-7A08">
                            7A08<br />Last Inspection<br />Date
                          </th>
                          <th id="th_7A09" class="col-7A09">
                            7A09<br />Inspection<br />Interval
                          </th>
                          <th id="th_7A10" class="col-7A10">
                            7A10<br />Next Inspection<br />Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(specificSpan, idx) in tableData" :key="idx">
                          <td class="col-none">
                            <strong>{{ specificSpan.Title }}</strong>
                          </td>
                          <td class="col-7A07">
                            <v-checkbox
                              v-if="specificSpan.showCheckbox"
                              :id="'checkbox_selectStructure_' + idx"
                              :aria-describedby="
                                'checkbox_selectStructure_' + idx
                              "
                              :aria-label="'checkbox_selectStructure_' + idx"
                              v-model="specificSpan.isRequired"
                              :true-value="'1'"
                              :false-value="'0'"
                              hide-details
                              disabled
                            ></v-checkbox>
                          </td>
                          <td class="col-7A08 align-center">
                            <LabelDatefield
                              textXl="12"
                              textLg="12"
                              textMd="12"
                              textSm="12"
                              :id="'txt_last_inspection_date_' + idx"
                              v-model="specificSpan.LastInspectionDate"
                            >
                            </LabelDatefield>
                          </td>
                          <td class="col-7A09 text-align_end">
                            <LabelText
                              textXl="12"
                              textLg="12"
                              textMd="12"
                              textSm="12"
                              :id="'txt_inspection_interval_' + idx"
                              v-model="specificSpan.InspectionInterval"
                              appendWith=" mos"
                              type="number"
                              allowNegativeInput
                            >
                            </LabelText>
                          </td>
                          <td class="col-7A10 align-center">
                            <LabelDatefield
                              textXl="12"
                              textLg="12"
                              textMd="12"
                              textSm="12"
                              :id="'txt_next_inspection_date_' + idx"
                              v-model="specificSpan.nextInspectionDate"
                            >
                            </LabelDatefield>
                          </td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                </v-col>
                <v-col v-else cols="12" sm="12">
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-label class="bold">7A06 Inspection Performed:</v-label>
                    </v-col>
                  </v-row>
                  <LabelCheckbox
                    labelSm="7"
                    id="checkbox_NBI"
                    label="NBI"
                    v-model="structure.InspEvnt.NBINSPDONE"
                  >
                  </LabelCheckbox>
                  <LabelCheckbox
                    labelSm="7"
                    id="checkbox_NSTM"
                    label="NSTM"
                    v-model="structure.InspEvnt.FCINSPDONE"
                  >
                  </LabelCheckbox>
                  <LabelCheckbox
                    labelSm="7"
                    id="checkbox_Underwater"
                    label="Underwater"
                    v-model="structure.InspEvnt.UWINSPDONE"
                  >
                  </LabelCheckbox>
                  <LabelCheckbox
                    labelSm="7"
                    id="checkbox_Other_Special"
                    label="Other Special"
                    v-model="structure.InspEvnt.OSINSPDONE"
                  >
                  </LabelCheckbox>
                  <LabelCheckbox
                    labelSm="7"
                    id="checkbox_Element"
                    label="Element"
                    v-model="structure.InspEvnt.ELINSPDONE"
                  >
                  </LabelCheckbox>
                  <v-col class="pa-0 mt-8 mb-8" md="12" lg="12" xl="12">
                    <v-table class="schedule-table compactTable">
                      <thead>
                        <tr>
                          <th id="th_none" class="col-none"></th>
                          <th id="th_7A07" class="col-7A07">
                            7A07<br />Required
                          </th>
                          <th id="th_7A08" class="col-7A08">
                            7A08<br />Last Inspection<br />Date
                          </th>
                          <th id="th_7A09" class="col-7A09">
                            7A09<br />Inspection<br />Interval
                          </th>
                          <th id="th_7A10" class="col-7A10">
                            7A10<br />Next Inspection<br />Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(specificSpan, idx) in tableData" :key="idx">
                          <td class="col-none">
                            <strong>{{ specificSpan.Title }}</strong>
                          </td>
                          <td class="col-7A07">
                            <v-checkbox
                              v-if="specificSpan.showCheckbox"
                              :id="'checkbox_selectStructure_' + idx"
                              v-model="specificSpan.isRequired"
                              :true-value="'1'"
                              :false-value="'0'"
                              hide-details
                              disabled
                            ></v-checkbox>
                          </td>
                          <td class="col-7A08 align-center">
                            <LabelDatefield
                              textXl="12"
                              textLg="12"
                              textMd="12"
                              textSm="12"
                              :id="'txt_last_inspection_date_' + idx"
                              v-model="specificSpan.LastInspectionDate"
                            >
                            </LabelDatefield>
                          </td>
                          <td class="col-7A09 text-align_end">
                            <LabelText
                              textXl="12"
                              textLg="12"
                              textMd="12"
                              textSm="12"
                              :id="'txt_inspection_interval_' + idx"
                              v-model="specificSpan.InspectionInterval"
                              appendWith=" mos"
                              type="number"
                              allowNegativeInput
                            >
                            </LabelText>
                          </td>
                          <td class="col-7A10 align-center">
                            <LabelDatefield
                              textXl="12"
                              textLg="12"
                              textMd="12"
                              textSm="12"
                              :id="'txt_next_inspection_date_' + idx"
                              v-model="specificSpan.nextInspectionDate"
                            >
                            </LabelDatefield>
                          </td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
    </v-col>
  </v-row>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  nextTick,
} from "vue";
import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import { requiredRule, valueInRangeRule } from "@/composables/validationRules";
import LabelText from "@/components/common/LabelText.vue";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelDatefield from "@/components/common/LabelDatefield.vue";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import inspectionType from "@/data/inspectionType";
import { clone } from "@/util/clone";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import IconTooltip from "@/components/common/IconTooltip.vue";
import useDateField from "@/composables/dateField.js";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import { INSPECTION_TYPE_PERFORMED } from "@/constants/Inspections";
import { BUTTONS } from "@/constants/CommonWebConstants";
import { LOGGER } from "@/util/logger";
import { scrollToDataTable } from "@/util/scrollToElement";
import FutureScheduleTable from "@/components/shared/FutureScheduleTable.vue";
import { sortEquipmentArray } from "@/composables/util";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";

export default defineComponent({
  name: "InspectionSchedule",
  setup() {
    const allPanels = [0, 1, 2, 3];
    const panel = ref(allPanels);

    const inspectionStore = useInspectionStore();
    const configStore = useConfigStore();
    const snackbarStore = useSnackbarStore();
    const { getFormattedDateStringNoTime } = useDateField();

    let valid = ref(true);
    let modifyInspectionScheduleForm = ref(null);
    const structure = computed(() => inspectionStore.selectedInspection);
    const isEditable = computed(() => inspectionStore.isEditable);

    let equipmentError = ref({ message: [] });
    let futureScheduleTable = ref(null);
    let snackBarId = ref("");
    let onlyNSTMErrorId = ref("");
    let inspTypeBothErrorId = ref("");
    let equipmentErrorId = ref("");

    onMounted(async () => {
      await validate();
      await futureScheduleTable?.value?.validateForm();
    });

    async function validate() {
      await modifyInspectionScheduleForm.value.validate();
    }

    async function validateFutureSchedule() {
      return await futureScheduleTable?.value.validateForm();
    }

    async function resetFutureScheduleFormValidation() {
      return await futureScheduleTable?.value.resetFormValidation();
    }

    const tableData = computed({
      get() {
        return [
          {
            Title: "NBI:",
            showCheckbox: false,
            isRequired: "",
            LastInspectionDate: structure.value?.InspEvnt?.LASTINSP,
            InspectionInterval: structure.value?.InspEvnt?.BRINSPFREQ,
            nextInspectionDate: structure.value?.InspEvnt?.NEXTINSP,
          },
          {
            Title: "NSTM:",
            showCheckbox: true,
            isRequired: structure.value?.InspEvnt?.FCINSPREQ,

            LastInspectionDate: structure.value?.InspEvnt?.FCLASTINSP,
            InspectionInterval: structure.value?.InspEvnt?.FCINSPFREQ,
            nextInspectionDate: structure.value?.InspEvnt?.FCNEXTDATE,
          },
          {
            Title: "Underwater:",
            showCheckbox: true,
            isRequired: structure.value?.InspEvnt?.UWINSPREQ,
            LastInspectionDate: structure.value?.InspEvnt?.UWLASTINSP,
            InspectionInterval: structure.value?.InspEvnt?.UWINSPFREQ,
            nextInspectionDate: structure.value?.InspEvnt?.UWNEXTDATE,
          },
          {
            Title: "Other Special:",
            showCheckbox: true,
            isRequired: structure.value?.InspEvnt?.OSINSPREQ,
            LastInspectionDate: structure.value?.InspEvnt?.OSLASTINSP,
            InspectionInterval: structure.value?.InspEvnt?.OSINSPFREQ,
            nextInspectionDate: structure.value?.InspEvnt?.OSNEXTDATE,
          },
          {
            Title: "Element:",
            showCheckbox: true,
            isRequired: structure.value?.InspEvnt?.ELINSPREQ,
            LastInspectionDate: structure.value?.InspEvnt?.ELLASTINSP,
            InspectionInterval: structure.value.InspEvnt.ELINSPFREQ,
            nextInspectionDate: structure.value?.InspEvnt?.ELNEXTDATE,
          },
        ];
      },
    });

    const underwaterDivers = computed(() => configStore.getUnderwaterDivers);

    const getTeamLeaders = (isNSTM) => {
      return configStore.getTeamLeaders(structure.value, isNSTM);
    };

    let organizations = computed(() => configStore.getOrganizations);
    let users = computed(() => configStore.getUsers);

    let updateInspectionOrganization = () => {
      let bpId = users.value.find(
        (a) => a.userId == structure.value.InspEvnt.INSPUSRKEY
      )?.bpId;
      if (bpId == null) {
        structure.value.UserInsp.INSP_ORG_NAME = "Penndot";
      } else {
        structure.value.UserInsp.INSP_ORG_NAME = organizations.value.find(
          (a) => a.organization.bpId == bpId
        )?.organization?.bpName;
      }
    };

    const extendedInspectionIntervalEligibility = computed(() =>
      configStore.getReferenceValue(
        REFERENCE_TABLE.EXTENDED_INSPECTION_INTERVAL_ELIGIBILITY,
        structure.value.UserInsp.EXTINSPFREQ_ELIG
      )
    );

    const extendedInspectionIntervalConcurrence = computed(() =>
      configStore.getReferenceValue(
        REFERENCE_TABLE.INSPECTION_INTERVAL_CONCURRENCE,
        structure.value.UserInsp.EXTINSPFREQ_CONC
      )
    );

    const methodOneEligibility = computed(() =>
      configStore.getReferenceValue(
        REFERENCE_TABLE.METHOD1_ELIGIBILITY,
        structure.value.UserInsp.METHOD1_ELIG
      )
    );
    let crewHrs = computed({
      get() {
        return parseFloat(structure.value?.UserInsp?.CREWHRS).toFixed(0);
      },
      set(crewHrsParam) {
        if (crewHrsParam || crewHrsParam == 0) {
          structure.value.UserInsp.CREWHRS =
            parseFloat(crewHrsParam).toFixed(0);
        } else {
          structure.value.UserInsp.CREWHRS = null;
        }
      },
    });

    let craneHrs = computed({
      get() {
        return parseFloat(structure.value?.UserInsp?.SNOOPERHRS).toFixed(0);
      },
      set(craneHrsParam) {
        if (craneHrsParam || craneHrsParam == 0) {
          structure.value.UserInsp.SNOOPERHRS =
            parseFloat(craneHrsParam).toFixed(0);
        } else {
          structure.value.UserInsp.SNOOPERHRS = null;
        }
      },
    });

    let nstmHrs = computed({
      get() {
        return parseFloat(structure.value?.UserInsp?.FLAGGERHRS).toFixed(0);
      },
      set(nstmHrsParam) {
        if (nstmHrsParam || nstmHrsParam == 0) {
          structure.value.UserInsp.FLAGGERHRS =
            parseFloat(nstmHrsParam).toFixed(0);
        } else {
          structure.value.UserInsp.FLAGGERHRS = null;
        }
      },
    });

    let helperHrs = computed({
      get() {
        return parseFloat(structure.value?.UserInsp?.HELPERHRS).toFixed(0);
      },
      set(helperHrsParam) {
        if (helperHrsParam || helperHrsParam == 0) {
          structure.value.UserInsp.HELPERHRS =
            parseFloat(helperHrsParam).toFixed(0);
        } else {
          structure.value.UserInsp.HELPERHRS = null;
        }
      },
    });
    let expandedRows = ref([]);
    let inspectionTypes = ref([]);
    let sortBy = ref([{ key: "INSP_TYPE", order: "asc" }]);
    let previousSort = ref([{ key: "INSP_TYPE", order: "asc" }]);

    let validDetails = ref(false);
    let inspTypeDetailForm = ref(null);
    let changesDetected = ref(false);
    const closeConfirm = ref(null);

    const anyEditable = computed(() => {
      return expandedRows.value?.some((element) => !element.readOnly);
    });
    const getNewInspectionType = () => {
      return clone(inspectionType);
    };

    const inspTypeHeaders = [
      {
        key: "data-table-expand",
        width: "1%",
      },
      {
        line1: "B.IE.01",
        line2: "Inspection",
        line3: "Type",
        key: "INSP_TYPE",
        sortable: true,
        width: "15%",
      },
      {
        line1: "B.IE.02",
        line2: "Inspection",
        line3: "Start Date",
        key: "START_DATE",
        sortable: false,
        width: "10%",
      },
      {
        line1: "B.IE.03",
        line2: "Inspection",
        line3: "End Date",
        key: "END_DATE",
        sortable: false,
        width: "10%",
      },
      {
        line1: "B.IE.04",
        line2: "NCBI",
        line3: "(Team Leader)",
        key: "TEAM_LEADER",
        sortable: false,
        width: "15%",
      },
      {
        line1: "B.IE.05",
        line2: "Inspection",
        line3: "Interval",
        key: "INSP_INTERVAL",
        sortable: false,
        width: "10%",
      },
      {
        line1: "B.IE.06",
        line2: "Inspection",
        line3: "Due Date",
        key: "DUE_DATE",
        sortable: false,
        width: "10%",
      },
      {
        line1: "B.IE.07",
        line2: "RBI",
        line3: "Method",
        key: "RBM",
        sortable: false,
      },
      {
        line1: null,
        line2: "Action",
        line3: null,
        key: "action",
        sortable: false,
        width: "5%",
      },
    ];

    const getAllInspectionTypes = () => {
      expandedRows.value = [];
      inspectionTypes.value = clone(structure.value.Snbi_Insp_Event);
      if (inspectionTypes.value?.length > 0) {
        inspectionTypes.value?.forEach((event) => {
          //Update equipment
          const selectedEquip = [];
          for (let prop in event) {
            if (prop.startsWith("EQUIP_") && event[prop] === "1") {
              selectedEquip.push(prop.replace("EQUIP_", ""));
            }
          }
          event.selectedEquipment = selectedEquip;
          event.readOnly = true;
        });
      } else {
        inspectionTypes.value = [];
      }
    };
    getAllInspectionTypes();

    const setScheduleValues = (detail) => {
      switch (detail.INSP_TYPE) {
        case "2":
          detail.INSP_INTERVAL =
            structure.value?.Snbi_Insp_Schedule?.ROU_INTERVAL || 0;
          detail.DUE_DATE = structure.value?.Snbi_Insp_Schedule?.ROU_NEXT_DATE;
          break;
        case "3":
          detail.INSP_INTERVAL =
            structure.value?.Snbi_Insp_Schedule?.UW_INTERVAL || 0;
          detail.DUE_DATE = structure.value?.Snbi_Insp_Schedule?.UW_NEXT_DATE;
          break;
        case "4":
          detail.INSP_INTERVAL =
            structure.value?.Snbi_Insp_Schedule?.NSTM_INTERVAL || 0;
          detail.DUE_DATE = structure.value?.Snbi_Insp_Schedule?.NSTM_NEXT_DATE;
          break;
        case "6":
          detail.INSP_INTERVAL =
            structure.value?.Snbi_Insp_Schedule?.IND_INTERVAL || 0;
          detail.DUE_DATE = structure.value?.Snbi_Insp_Schedule?.IND_NEXT_DATE;
          break;
        case "7":
          detail.INSP_INTERVAL =
            structure.value?.Snbi_Insp_Schedule?.OS_INTERVAL || 0;
          detail.DUE_DATE = structure.value?.Snbi_Insp_Schedule?.OS_NEXT_DATE;
          break;
        case "8":
          detail.INSP_INTERVAL =
            structure.value?.Snbi_Insp_Schedule?.SERV_INTERVAL || 0;
          detail.DUE_DATE = structure.value?.Snbi_Insp_Schedule?.SERV_NEXT_DATE;
          break;
        case "E":
          detail.INSP_INTERVAL =
            structure.value?.Snbi_Insp_Schedule?.ELEM_INTERVAL || 0;
          detail.DUE_DATE = structure.value?.Snbi_Insp_Schedule?.ELEM_NEXT_DATE;
          break;
      }
    };

    const addInspType = () => {
      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.SNBI_EVENTS_DATATABLE,
      });
      let inspType = getNewInspectionType();
      inspType.isAdd = true;
      inspType.readOnly = false;
      inspType.selectedEquipment = [];
      inspType.BRKEY = structure.value.Bridge.BRKEY;
      inspType.INSPKEY = structure.value.InspEvnt.INSPKEY;
      inspectionTypes.value.unshift(inspType);
      previousSort.value = sortBy.value.map((x) => x);
      sortBy.value = [];
      expandedRows.value = [inspType];
      scrollToDataTable("inspectionTypes-data-table");
      const isEquipValid = checkEquipmentValidations(inspType);
      equipmentError.value.message = isEquipValid.message;

      nextTick(() => {
        inspTypeDetailForm.value?.validate();
      });
    };
    const editInspType = (detail) => {
      equipmentError.value.message = [];
      previousSort.value = sortBy.value.map((x) => x);
      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.SNBI_EVENTS_DATATABLE,
      });
      detail.readOnly = false;
      //if all required fields entered - close the expanded row
      expandedRows.value = [detail];
      const isEquipValid = checkEquipmentValidations(detail);
      equipmentError.value.message = isEquipValid.message;
      nextTick(() => {
        inspTypeDetailForm.value?.validate();
        const isEquipValid = checkEquipmentValidations(detail);
        equipmentError.value.message = isEquipValid.message;
      });
      sortBy.value = [];
    };

    const deleteInspType = (detail) => {
      const deleteIndex = structure.value.Snbi_Insp_Event.findIndex(
        (a) => a.INSP_TYPE === detail.INSP_TYPE
      );
      if (deleteIndex > -1) {
        structure.value.Snbi_Insp_Event.splice(deleteIndex, 1);
        expandedRows.value = [];
        getAllInspectionTypes();
      }
    };

    const closeInspType = () => {
      if (isEditable.value && changesDetected.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              getAllInspectionTypes();
              expandedRows.value = [];
              sortBy.value = previousSort.value?.map((x) => x);
              inspectionStore.setAddingOrEditing(false);
              snackbarStore.removeMessage(snackBarId.value);
              changesDetected.value = false;
              equipmentError.value.message = [];
            }
          });
      } else {
        sortBy.value = previousSort.value?.map((x) => x);
        getAllInspectionTypes();
        expandedRows.value = [];
        inspectionStore.setAddingOrEditing(false);
        snackbarStore.removeMessage(snackBarId.value);
        equipmentError.value.message = [];
      }
    };
    const saveInspType = (detail) => {
      const isEquipValid = checkEquipmentValidations(detail);
      //EQUIP_CONCAT field <=120
      const selectedEquipInRange = validateEquipment(detail);
      //check date fields
      const areDatesValid = detail.END_DATE && detail.START_DATE;
      inspTypeDetailForm.value
        .validate()
        .then(() => {
          if (
            validDetails.value &&
            isEquipValid?.valid &&
            areDatesValid &&
            selectedEquipInRange?.valid
          ) {
            inspectionStore.setShowValidationSnackbar(false);
            if (!structure.value?.Snbi_Insp_Event?.[0]) {
              //remove null values from array(if any)
              structure.value.Snbi_Insp_Event =
                structure.value.Snbi_Insp_Event?.filter((a) => !!a);
            }
            detail.MOD_DATE = new Date().toISOString();
            if (detail.isAdd) {
              structure.value.Snbi_Insp_Event.push(
                convertToInspEventObject(detail)
              );
              updateFutureNextDate(detail);
            } else if (changesDetected.value) {
              //update existing detail
              const updateIndex = structure.value.Snbi_Insp_Event.findIndex(
                (a) => a.INSP_TYPE === detail.INSP_TYPE
              );
              structure.value.Snbi_Insp_Event.splice(
                updateIndex,
                1,
                convertToInspEventObject(detail)
              );
            }
            changesDetected.value = false;
            //if all required fields entered - close the expanded row
            expandedRows.value = [];
            inspectionStore.setAddingOrEditing(false);
            snackbarStore.removeMessage(snackBarId.value);
            getAllInspectionTypes();
            sortBy.value = previousSort.value?.map((x) => x);
          } else if (!validDetails.value) {
            inspectionStore.setShowValidationSnackbar(true);
          } else if (!isEquipValid?.valid) {
            inspectionStore.setShowValidationSnackbar(true);
            showEquipmentError(isEquipValid);
          } else if (!selectedEquipInRange?.valid) {
            inspectionStore.setShowValidationSnackbar(true);
            showEquipmentError(selectedEquipInRange);
          }
        })
        .catch((err) => {
          LOGGER.logException(err);
          changesDetected.value = false;
        });
    };

    const showEquipmentError = (isEquipValid) => {
      if (isEquipValid.snackbarMessage) {
        equipmentErrorId.value = snackbarStore.showMessage({
          displayText: isEquipValid.snackbarMessage,
          timeout: -1,
          messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
        });
      } else {
        equipmentError.value.message = isEquipValid.message;
      }
    };

    const convertToInspEventObject = (details) => {
      let snbiEventObj = getNewInspectionType();
      snbiEventObj.BRKEY = details.BRKEY;
      snbiEventObj.CREATE_DATE = details.CREATE_DATE;
      snbiEventObj.DUE_DATE = details.DUE_DATE;
      snbiEventObj.END_DATE = details.END_DATE;
      snbiEventObj.EQUIP_A01 = details.EQUIP_A01;
      snbiEventObj.EQUIP_A02 = details.EQUIP_A02;
      snbiEventObj.EQUIP_A03 = details.EQUIP_A03;
      snbiEventObj.EQUIP_A04 = details.EQUIP_A04;
      snbiEventObj.EQUIP_A05 = details.EQUIP_A05;
      snbiEventObj.EQUIP_A06 = details.EQUIP_A06;
      snbiEventObj.EQUIP_A07 = details.EQUIP_A07;
      snbiEventObj.EQUIP_A08 = details.EQUIP_A08;
      snbiEventObj.EQUIP_A09 = details.EQUIP_A09;
      snbiEventObj.EQUIP_A10 = details.EQUIP_A10;
      snbiEventObj.EQUIP_A11 = details.EQUIP_A11;
      snbiEventObj.EQUIP_A12 = details.EQUIP_A12;
      snbiEventObj.EQUIP_A13 = details.EQUIP_A13;
      snbiEventObj.EQUIP_A14 = details.EQUIP_A14;
      snbiEventObj.EQUIP_AN = details.EQUIP_AN;
      snbiEventObj.EQUIP_AX = details.EQUIP_AX;
      snbiEventObj.EQUIP_CONCAT = details.EQUIP_CONCAT;
      snbiEventObj.EQUIP_IN = details.EQUIP_IN;
      snbiEventObj.EQUIP_I01 = details.EQUIP_I01;
      snbiEventObj.EQUIP_I02 = details.EQUIP_I02;
      snbiEventObj.EQUIP_I03 = details.EQUIP_I03;
      snbiEventObj.EQUIP_I04 = details.EQUIP_I04;
      snbiEventObj.EQUIP_I05 = details.EQUIP_I05;
      snbiEventObj.EQUIP_I06 = details.EQUIP_I06;
      snbiEventObj.EQUIP_I07 = details.EQUIP_I07;
      snbiEventObj.EQUIP_I08 = details.EQUIP_I08;
      snbiEventObj.EQUIP_I09 = details.EQUIP_I09;
      snbiEventObj.EQUIP_I10 = details.EQUIP_I10;
      snbiEventObj.EQUIP_I11 = details.EQUIP_I11;
      snbiEventObj.EQUIP_I12 = details.EQUIP_I12;
      snbiEventObj.EQUIP_I13 = details.EQUIP_I13;
      snbiEventObj.EQUIP_I14 = details.EQUIP_I14;
      snbiEventObj.EQUIP_I15 = details.EQUIP_I15;
      snbiEventObj.EQUIP_IX = details.EQUIP_IX;
      snbiEventObj.INSP_INTERVAL = details.INSP_INTERVAL;
      snbiEventObj.INSP_TYPE = details.INSP_TYPE;
      snbiEventObj.INSPECTED_BY = details.INSPECTED_BY;
      snbiEventObj.INSPECTED_BY_NAME = details.INSPECTED_BY_NAME;
      snbiEventObj.INSPKEY = details.INSPKEY;
      snbiEventObj.MOD_DATE = details.MOD_DATE;
      snbiEventObj.QA_DATE = details.QA_DATE;
      snbiEventObj.QC_DATE = details.QC_DATE;
      snbiEventObj.RBM = details.RBM;
      snbiEventObj.SCOPE_NOTE = details.SCOPE_NOTE;
      snbiEventObj.START_DATE = details.START_DATE;
      snbiEventObj.TEAM_HELPER = details.TEAM_HELPER;
      snbiEventObj.TEAM_LEADER = details.TEAM_LEADER;
      return snbiEventObj;
    };

    const rowClass = (data) => {
      return expandedRows.value.includes(data) ? "rowSelect" : "rowUnselect";
    };

    const toggleRow = (detail) => {
      if (expandedRows.value.includes(detail)) {
        const index = expandedRows.value.findIndex(
          (d) => d.INSP_TYPE == detail.INSP_TYPE
        );
        expandedRows.value.splice(index, 1);
      } else {
        expandedRows.value.push(detail);
      }
    };
    const isExpanded = (details) => {
      return (
        expandedRows.value?.findIndex(
          (a) => a.INSP_TYPE == details.INSP_TYPE
        ) >= 0
      );
    };

    const getAllEquipment = () => {
      const accessEquipment = [];
      const inspectionEquipment = [];
      const allEquips = configStore.getReferenceValues(
        REFERENCE_TABLE.INSPECTION_EQUIPMENT,
        null,
        null,
        true
      );
      allEquips?.forEach((equipment) => {
        if (equipment.value.startsWith("A")) {
          accessEquipment.push(equipment);
        } else {
          inspectionEquipment.push(equipment);
        }
      });

      return [
        sortEquipmentArray(accessEquipment),
        sortEquipmentArray(inspectionEquipment),
      ];
    };
    const [accessEquipment, inspectionEquipment] = getAllEquipment();

    const updateEquipment = (inspType) => {
      snackbarStore.removeMessage(equipmentErrorId.value);
      if (
        inspType.selectedEquipment?.some((a) => a.startsWith("A")) &&
        equipmentError.value.message.findIndex((a) => a.includes("Access")) > -1
      ) {
        equipmentError.value.message?.splice(
          equipmentError.value.message.findIndex((a) => a.includes("Access")),
          1
        );
      } else if (
        inspType.selectedEquipment?.some((a) => a.startsWith("I")) &&
        equipmentError.value.message.findIndex((a) =>
          a.includes("Inspection")
        ) > -1
      ) {
        equipmentError.value.message?.splice(
          equipmentError.value.message.findIndex((a) =>
            a.includes("Inspection")
          ),
          1
        );
      }
      if (
        !inspType.selectedEquipment?.some((a) => a.startsWith("A")) ||
        !inspType.selectedEquipment?.some((a) => a.startsWith("I"))
      ) {
        const isEquipValid = checkEquipmentValidations(inspType);
        equipmentError.value.message = isEquipValid.message;
      }
      snackbarStore.removeMessage(equipmentErrorId.value);

      //reset all equipments
      Object.keys(inspType)?.forEach((key) => {
        if (key.startsWith("EQUIP_")) {
          inspType[key] = "0";
        }
      });
      //set values for individual properties
      inspType.selectedEquipment.forEach((e) => {
        inspType[`EQUIP_${e}`] = "1";
      });
      inspType.EQUIP_CONCAT = inspType.selectedEquipment?.sort()?.join("|");
    };
    //Map to set the SNBI insp type hierarchy(rank)
    const inspTypeHierarchy = new Map();
    inspTypeHierarchy.set("Z", {
      snbiInspType: "Z",
      rank: "1",
      inspType: "Z", //7A03
    });
    inspTypeHierarchy.set("1", {
      snbiInspType: "1",
      rank: "2",
      inspType: "F", //7A03
      inspectionPerformed: "NBINSPDONE", //7A06
      nextInspectionDate: "NEXTINSP", //7A10
    });
    inspTypeHierarchy.set("2", {
      snbiInspType: "2",
      rank: "3",
      inspType: "R", //7A03
      inspectionPerformed: "NBINSPDONE", //7A06
      nextInspectionDate: "NEXTINSP", //7A10
    });
    inspTypeHierarchy.set("7", {
      snbiInspType: "7",
      rank: "4",
      inspType: "I", //7A03
      inspectionPerformed: "OSINSPDONE", //7A06
      nextInspectionDate: "OSNEXTDATE", //7A10
    });
    inspTypeHierarchy.set("3", {
      snbiInspType: "3",
      rank: "5",
      inspType: "U", //7A03
      inspectionPerformed: "UWINSPDONE", //7A06
      nextInspectionDate: "UWNEXTDATE", //7A10
    });
    inspTypeHierarchy.set("5", {
      snbiInspType: "5",
      rank: "6",
      inspType: "B", //7A03
    });
    inspTypeHierarchy.set("6", {
      snbiInspType: "6",
      rank: "7",
      inspType: "D", //7A03
    });
    inspTypeHierarchy.set("9", {
      snbiInspType: "9",
      rank: "8",
      inspType: "P", //7A03
    });
    inspTypeHierarchy.set("P", {
      snbiInspType: "P",
      rank: "9",
      inspType: "P", //7A03
    });
    inspTypeHierarchy.set("8", {
      snbiInspType: "8",
      rank: "10",
      inspType: "H", //7A03
    });
    inspTypeHierarchy.set("E", {
      snbiInspType: "E",
      rank: "11",
      inspType: "E", //7A03
      inspectionPerformed: "ELINSPDONE", //7A06
      nextInspectionDate: "ELNEXTDATE", //7A10
    });
    inspTypeHierarchy.set("4", {
      snbiInspType: "4",
      rank: "12",
      inspectionPerformed: "FCINSPDONE", //7A06
      nextInspectionDate: "FCNEXTDATE", //7A10
    });
    inspTypeHierarchy.set("Q", {
      snbiInspType: "Q",
      rank: "13",
      inspType: "M", //7A03
    });

    const isValidInspTypes = () => {
      snackbarStore.removeMessage(inspTypeBothErrorId.value);
      snackbarStore.removeMessage(onlyNSTMErrorId.value);

      if (
        inspectionTypes.value.length === 1 &&
        inspectionTypes.value[0].INSP_TYPE == "4"
      ) {
        onlyNSTMErrorId.value = snackbarStore.showMessage({
          displayText: `The page cannot be saved since it would make "4-NSTM" the only inspection
      type and that's invalid.`,
          messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
        });
        return false;
      } else if (
        inspectionTypes.value?.find((a) => a.INSP_TYPE == "1") &&
        inspectionTypes.value?.find((a) => a.INSP_TYPE == "2")
      ) {
        inspTypeBothErrorId.value = snackbarStore.showMessage({
          displayText: `Cannot perform a "${configStore.getReferenceValue(
            REFERENCE_TABLE.SNBI_INSPECTION_TYPE,
            1
          )}" and "${configStore.getReferenceValue(
            REFERENCE_TABLE.SNBI_INSPECTION_TYPE,
            2
          )}" types on the same inspection.`,
          timeout: 10000,
          messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
        });
        return false;
      }
      return true;
    };

    //Update fields based on SNBI inspections
    const updateFieldDependencies = () => {
      updateInspPerformed();
      //update legacy schedule fields based on future table data
      futureScheduleTable.value.updateLegacySchedule();

      if (structure.value.Snbi_Insp_Event?.length > 0) {
        updatePrimaryInspType();
        updateInspStartDate();
        updateMaxEndDate();
        updateTeamLeader();
        updateInspectionOrganization();
      }
    };

    const updatePrimaryInspType = () => {
      const selectedInspTypeArray = [];
      const selectedSnbiInspTypeValueArray = [];
      inspectionTypes.value?.forEach((type) => {
        const inspType = inspTypeHierarchy.get(type.INSP_TYPE);
        selectedInspTypeArray.push(inspType);
        selectedSnbiInspTypeValueArray.push(inspType?.snbiInspType);
      });
      if (
        selectedSnbiInspTypeValueArray.includes("2") &&
        selectedSnbiInspTypeValueArray.includes("3")
      ) {
        //2,3 is present set the primary to W
        structure.value.InspEvnt.INSPTYPE = "W";
      } else {
        //get the lowest rank element's inspType value
        structure.value.InspEvnt.INSPTYPE = selectedInspTypeArray?.reduce(
          (prev, current) => (+prev.rank < +current.rank ? prev : current)
        )?.inspType;
      }
    };

    const updateInspPerformed = () => {
      const selectedInspTypeArray = [];
      inspectionTypes.value?.forEach((type) => {
        selectedInspTypeArray.push(inspTypeHierarchy.get(type.INSP_TYPE));
      });
      //reset all checkboxes - 7A06
      inspTypeHierarchy.forEach((value) => {
        const inspPerformed = value.inspectionPerformed;
        if (inspPerformed) {
          structure.value.InspEvnt[inspPerformed] = "0";
        }
      });

      selectedInspTypeArray.forEach((type) => {
        if (type.inspectionPerformed) {
          structure.value.InspEvnt[type.inspectionPerformed] = "1";
        }
      });
    };

    const updateInspStartDate = () => {
      const selectedInspTypeArray = [];
      inspectionTypes.value?.forEach((type) => {
        selectedInspTypeArray.push(inspTypeHierarchy.get(type.INSP_TYPE));
      });
      //get the lowest rank element's inspType value
      const inspType = selectedInspTypeArray?.reduce((prev, current) =>
        +prev.rank < +current.rank ? prev : current
      )?.snbiInspType;

      structure.value.InspEvnt.INSPDATE = inspectionTypes.value.find(
        (t) => t.INSP_TYPE == inspType
      )?.START_DATE;
    };

    const setInspTypeFieldDependencies = (detail) => {
      changesDetected.value = true;
      detail.TEAM_LEADER = "";
      //set interval value 0 for the following types
      if (["Z", "1", "5", "9", "P", "Q"].includes(detail.INSP_TYPE)) {
        detail.INSP_INTERVAL = 0;
        detail.DUE_DATE = null;
      } else {
        // get values from schedule object and set
        setScheduleValues(detail);
      }
      updateRBI(detail);
      inspTypeDetailForm.value?.validate();
    };

    const updateRBI = (detail) => {
      if (
        ["S1", "A1", "L1"].includes(structure.value.Bridge.REPORTGROUP) &&
        ["2", "3", "4"].includes(detail.INSP_TYPE)
      ) {
        detail.RBM = "1";
      } else {
        detail.RBM = "N";
      }
    };

    //Update interval value in the SNBI Inspection Types performed section
    const updateInterval = (inspTypeDetail) => {
      //update interval
      let snbiEvent = structure.value.Snbi_Insp_Event.find(
        (t) => t.INSP_TYPE === inspTypeDetail.inspType
      );
      if (snbiEvent) {
        snbiEvent.INSP_INTERVAL = inspTypeDetail.inspectionInterval;
      }

      //instead of refreshing the entire inspections- updated the cloned insp used for display
      let clonedSnbiEvent = inspectionTypes.value.find(
        (t) => t.INSP_TYPE === inspTypeDetail.inspType
      );
      if (clonedSnbiEvent) {
        clonedSnbiEvent.INSP_INTERVAL = inspTypeDetail.inspectionInterval;
      }
    };

    //Update next Date value in the SNBI Inspection Types performed section
    const updateNextDate = (inspTypeDetail) => {
      //update insp type due date
      let snbiEvent = structure.value.Snbi_Insp_Event.find(
        (t) => t.INSP_TYPE === inspTypeDetail.inspType
      );
      if (snbiEvent) {
        snbiEvent.DUE_DATE = inspTypeDetail.inspectionDueDate;
      }

      //instead of refreshing the entire inspections- updated the cloned insp used for display
      let clonedSnbiEvent = inspectionTypes.value.find(
        (t) => t.INSP_TYPE === inspTypeDetail.inspType
      );
      if (clonedSnbiEvent) {
        clonedSnbiEvent.DUE_DATE = inspTypeDetail.inspectionDueDate;
      }
    };

    //Update next Date value in the SNBI Inspection Types performed section
    const updateFutureNextDate = (detail) => {
      switch (detail.INSP_TYPE) {
        case "2":
          structure.value.Snbi_Insp_Schedule.ROU_NEXT_DATE = detail.DUE_DATE;
          break;
        case "3":
          structure.value.Snbi_Insp_Schedule.UW_NEXT_DATE = detail.DUE_DATE;
          break;
        case "4":
          structure.value.Snbi_Insp_Schedule.NSTM_NEXT_DATE = detail.DUE_DATE;
          break;
        case "6":
          structure.value.Snbi_Insp_Schedule.IND_NEXT_DATE = detail.DUE_DATE;
          break;
        case "7":
          structure.value.Snbi_Insp_Schedule.OS_NEXT_DATE = detail.DUE_DATE;
          break;
        case "8":
          structure.value.Snbi_Insp_Schedule.SERV_NEXT_DATE = detail.DUE_DATE;
          break;
        case "E":
          structure.value.Snbi_Insp_Schedule.ELEM_NEXT_DATE = detail.DUE_DATE;
          break;
      }
    };

    const updateMaxEndDate = () => {
      structure.value.UserInsp.OVERALL_END_DATE = new Date(
        Math.max(...inspectionTypes.value.map((i) => new Date(i.END_DATE)))
      ).toISOString();
    };

    const updateTeamLeader = () => {
      const selectedInspTypeArray = [];
      inspectionTypes.value?.forEach((type) => {
        selectedInspTypeArray.push(inspTypeHierarchy.get(type.INSP_TYPE));
      });
      //get the lowest rank element's inspType value
      const inspType = selectedInspTypeArray?.reduce((prev, current) =>
        +prev.rank < +current.rank ? prev : current
      )?.snbiInspType;

      structure.value.InspEvnt.INSPUSRKEY = inspectionTypes.value.find(
        (t) => t.INSP_TYPE == inspType
      )?.TEAM_LEADER;
    };

    const allSnbiInspTypes = computed(() =>
      //Filter Z and Q types
      configStore
        .getReferenceValues(REFERENCE_TABLE.SNBI_INSPECTION_TYPE)
        .filter((i) => !["Z", "Q"].includes(i.value))
    );

    const getAvailableSnbiTypes = () => {
      const addedInspTypes = structure.value?.Snbi_Insp_Event?.map(
        (i) => i.INSP_TYPE
      );
      //exclude already added inspTypes and show them as available
      return allSnbiInspTypes.value.filter(
        (a) => !addedInspTypes.includes(a.value)
      );
    };

    const checkEquipmentValidations = (detail) => {
      // Atleast one of each type should be checked
      const selectedAccessEquipment = detail.selectedEquipment?.filter((e) =>
        e.startsWith("A")
      );
      const selectedInspEquipment = detail.selectedEquipment?.filter((e) =>
        e.startsWith("I")
      );
      if (
        selectedAccessEquipment?.length == 0 &&
        selectedInspEquipment?.length == 0
      ) {
        return {
          valid: false,
          message: [
            "At least one Access Equipment Required.",
            "At least one Inspection Equipment Required.",
          ],
        };
      } else if (selectedAccessEquipment?.length == 0) {
        return {
          valid: false,
          message: ["At least one Access Equipment Required."],
        };
      } else if (selectedInspEquipment?.length == 0) {
        return {
          valid: false,
          message: ["At least one Inspection Equipment Required."],
        };
      }
      //if "No Equip" is selected no other equip should be selected.
      if (
        selectedAccessEquipment?.includes("AN") &&
        selectedAccessEquipment?.length > 1
      ) {
        //show error
        return {
          valid: false,
          snackbarMessage:
            'Unable to save - Access Equipment is checked with "No Acc. Equip. Used" also checked.',
          message: [],
        };
      }

      if (
        selectedInspEquipment?.includes("IN") &&
        selectedInspEquipment?.length > 1
      ) {
        //show error
        return {
          valid: false,
          snackbarMessage:
            'Unable to save - Inspection Equipment is checked with "No Insp Equip Used" also checked.',
        };
      }

      return { valid: true, message: [] };
    };

    const updateEndDate = (detail) => {
      changesDetected.value = true;
      const startDate = detail.START_DATE ? new Date(detail.START_DATE) : null;
      const endDate = detail.END_DATE ? new Date(detail.END_DATE) : null;
      if (startDate > endDate) {
        detail.END_DATE = null;
      }
    };

    const validateEquipment = (detail) => {
      // limitation on the SNBI side - only 31 equipment can be selected.
      if (detail.EQUIP_CONCAT?.length > 120) {
        return {
          valid: false,
          snackbarMessage:
            "Unable to save - Too many pieces of equipment in use.  Please review the equipment selected.",
        };
      } else {
        return { valid: true };
      }
    };

    const updateInspectionArr = () => {
      let latestInspectionHeaderIndex =
        inspectionStore.getInspectionArray.findIndex(
          (i) => i.value == structure.value.InspEvnt.INSPKEY
        );
      let latestInspectionHeader =
        inspectionStore.getInspectionArray[latestInspectionHeaderIndex];
      latestInspectionHeader.title = `${getFormattedDateStringNoTime(
        structure.value.InspEvnt.INSPDATE
      )} - ${getStructureInspectionTypes(
        structure.value.InspEvnt.INSPTYPE,
        getSnbiTypes(structure.value)
      )}`;
      inspectionStore.getInspectionArray[latestInspectionHeaderIndex] =
        latestInspectionHeader;
    };

    const getStructureInspectionTypes = (primaryInspType, snbiInspTypes) => {
      if (snbiInspTypes?.length > 0) {
        return (
          " Type " +
          snbiInspTypes
            .map((e) => e)
            ?.sort()
            .join("")
        );
      } else {
        return configStore.getReferenceValue(
          REFERENCE_TABLE.INSPECTION_TYPE,
          primaryInspType
        );
      }
    };

    const getSnbiTypes = (structureValue) => {
      return structureValue.Snbi_Insp_Event?.map((i) => i.INSP_TYPE);
    };

    const setInspTypeDueDate = (detail) => {
      changesDetected.value = true;
      if (
        detail.isAdd &&
        detail.END_DATE &&
        detail.INSP_INTERVAL > 0 &&
        !["Z", "1", "5", "9", "P", "Q"].includes(detail.INSP_TYPE)
      ) {
        let endDateValue = new Date(detail.END_DATE);
        let day = endDateValue.getDate();
        const calculatedDueDate = new Date(
          endDateValue.setMonth(endDateValue.getMonth() + detail.INSP_INTERVAL)
        );
        if (calculatedDueDate.getDate() !== day) {
          //we crossed the month boundary
          calculatedDueDate.setDate(0); //set the last day of previous month
        }
        detail.DUE_DATE = calculatedDueDate.toISOString();
      }
    };

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value.InspEvnt,
        structure.value.UserInsp,
        structure.value.Snbi_Insp_Event,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    watch(
      expandedRows,
      (newValue) => {
        if (newValue?.length == 0) {
          snackbarStore.removeMessage(snackBarId.value);
        }
      },
      { immediate: true }
    );

    return {
      underwaterDivers,
      INSPECTION_TYPE_PERFORMED,
      getTeamLeaders,
      ROUNDING_FACTOR,
      updateInspectionOrganization,
      methodOneEligibility,
      extendedInspectionIntervalConcurrence,
      helperHrs,
      nstmHrs,
      craneHrs,
      crewHrs,
      extendedInspectionIntervalEligibility,
      panel,
      tableData,
      structure,
      isEditable,
      valid,
      validate,
      modifyInspectionScheduleForm,
      requiredRule,
      REFERENCE_TABLE,
      valueInRangeRule,
      anyEditable,
      addInspType,
      expandedRows,
      inspTypeHeaders,
      inspectionTypes,
      sortBy,
      rowClass,
      toggleRow,
      isExpanded,
      editInspType,
      deleteInspType,
      saveInspType,
      closeInspType,
      getFormattedDateStringNoTime,
      validDetails,
      inspTypeDetailForm,
      changesDetected,
      configStore,
      accessEquipment,
      inspectionEquipment,
      updateEquipment,
      closeConfirm,
      BUTTONS,
      INSPECTION_MESSAGES,
      inspectionStore,
      setInspTypeFieldDependencies,
      updateInterval,
      getAvailableSnbiTypes,
      equipmentError,
      updateFieldDependencies,
      futureScheduleTable,
      isValidInspTypes,
      getAllInspectionTypes,
      updateEndDate,
      validateFutureSchedule,
      updateInspectionArr,
      updateNextDate,
      setInspTypeDueDate,
      resetFutureScheduleFormValidation,
      toggleAllPanels,
    };
  },
  components: {
    LabelCheckbox,
    LabelText,
    LabelSelect,
    LabelDatefield,
    DataTableHeader,
    IconTooltip,
    ConfirmDialog,
    FutureScheduleTable,
    ExpandCollapseToggle,
  },
});
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

.v-expansion-panel-title,
.v-expansion-panel-title:hover {
  background-color: c.$p-pa-light-blue !important;
}

h3 {
  order: 1;
  color: c.$p-white !important;
}

.v-expansion-panel-title__icon {
  order: 0;
}

button:focus {
  outline: none !important;
}

.label-radio-btn {
  position: relative !important;
}

.v-input {
  flex: none !important;
  opacity: 1 !important;
}

.schedule-table {
  width: 100%;
  max-width: 900px;
}

.col-none {
  width: 3%;
  min-width: 100px;
}

.col-7A07 {
  width: 1%;
  min-width: 100px;
}

.col-7A08 {
  width: 16%;
  min-width: 190px;
  text-align: center !important;
}

.col-7A09 {
  width: 38%;
  min-width: 150px;
}

.col-7A10 {
  width: 42%;
  min-width: 200px;
  text-align: center !important;
}

td {
  white-space: nowrap;
}

.compactTable th,
.compactTable td {
  padding: 0 0.25rem !important;
}

.col-7A09 :deep(.v-input__details) {
  padding-right: 0 !important;
  padding-left: 0 !important;
  text-align: center !important;
  white-space: normal !important;
}

.compactTable th {
  vertical-align: baseline !important;
  text-align: center !important;
}

.col-7A07 :deep(.v-input__control) {
  margin: 0 auto !important;
  display: inline-block !important;
}

.sub-section-header {
  background-color: c.$p-pa-light-blue;
  color: c.$p-white;
  font-weight: 600;
}
td.align-center :deep(.v-row) {
  justify-content: center;
}
.alignCenter {
  text-align: center;
}

.wrapValue :deep(span) {
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
}
.equipTable :deep(.v-label) {
  font-size: 0.71rem;
  display: flex !important;
  align-items: center;
}
</style>

export const REFERENCE_TABLE = {
  STRUCTURAL_CONFIGURATION_OF_APPROACH_SPANS: "StructuralConfiguration",
  APPROACH_SPAN_MATERIAL: "ApproachSpanMaterial",
  APPROACH_ROADWAY_ALIGNMENT_APPRAISAL: "ApproachRoadwayAlignmentAppraisal",
  BRIDGE_DECK_TYPE: "DeckType",
  BRIDGE_MEDIAN: "MedianType",
  BUS_PLAN_NETWORK: "BusinessPlanNetwork",
  CONDITION_RATING: "ConditionRating",
  DECK_FORM_TYPE: "FormType",
  DECK_PROTECTION_TYPE: "DeckProtectionType",
  DECK_MEMBRANE_TYPE: "DeckMembraneType",
  DECK_SURFACE_TYPE: "DeckSurfaceType",
  DECK_STRUCTURE_TYPE: "DeckStructureType",
  DRAWING_TYPE: "DrawingType",
  EXTENDED_INSPECTION_INTERVAL_ELIGIBILITY: "InspectionIntervalEligibility",
  ENVIRONMENT: "Environment",
  FRACTURE_CRITICAL_FATIGUE_STRESS_CATEGORY:
    "FractureCriticalFatigueStressCategory",
  FIELD_COND: "FieldConditions",
  FRACTURE_CRITICAL_MEMBER_TYPE: "FractureCriticalMemberType",
  HISTORICAL_SIGNIFIGANCE: "HistoricalSignificance",
  INSPECTION_STATUS: "InspectionStatus",
  INSPECTION_TYPE: "InspectionType",
  MAIN_SPAN_DESIGN: "MainSpanDesign",
  MAIN_SPAN_MATERIAL: "MainSpanMaterial",
  MAINTENENCE_RESPONSIBILITY: "MaintenenceResponsibility",
  MATERIAL_STORED_UNDER: "MaterialStoredUnder",
  MATERIAL_MAKEUP: "MaterialMakeup",
  MEMBER_TYPE: "MemberType",
  PAINT_CONDITION_RATING: "PaintConditionRating",
  PAINT_CONDITION_RATING_EXTENT: "PaintConditionRatingExtent",
  PHYSICAL_MAKEUP: "PhysicalMakeup",
  PLUG_WELD_PROBLEM_TYPE: "PlugWeldProblemType",
  POST_REASON: "PostingReason",
  POSTING_STATUS: "PostingStatus",
  RELIEF_JOINT: "ReliefJoint",
  REPORTING_GROUP: "ReportingGroup",
  SERVICE_TYPE_ON: "ServiceTypeOn",
  MOUNT_TYPE: "MountType",
  MOUNT_BOLT_BASE: "MountBoltBase",
  ROAD_SIDE: "RoadSide",
  FOUND_MATERIAL_TYPE: "FoundMaterialType",
  WALL_FOUNDATION_TYPE: "WallFoundationType",
  WALL_USE: "WallUse",
  WALL_MOUNT_TYPE: "WallMountType",
  WALL_POST_TYPE: "WallPostType",
  REBAR_PROTECTION_TYPE: "RebarProtectionType",
  UNDER_NAVIGABLE_WATERWAY: "UnderNavigableWaterway",
  PROTECTION_FROM_NAVIGATION: "ProtectionFromNavigation",
  SUPPORT_DESCRIPTION: "SupportDescription",
  ARCHITECTURAL_FORMS: "ArchitecturalForms",
  INSTALLED_ROADWAY_TYPE: "InstalledRoadwayType",
  SUB_AGENCY: "AgencySubmittingStructure",
  SERVICE_TYPE_UNDER: "ServiceTypeUnder",
  SPAN_INTERACTION: "SpanInteraction",
  SPECIAL_RESTRICT_POST: "SpecialRestrictivePosting",
  STRUCTURAL_CONFIGURATION: "StructuralConfiguration",
  STRUCTURE_FLARED: "StructureFlared",
  TEMPORARY_STRUCTURE: "TemporaryStructure",
  WEATHER_CONDITION: "WeatherCondition",
  TEAM_LEADER: "TeamLeader",
  INSPECTION_PERFORMED_BY: "InspectionPerformedBy",
  INSPECTION_INTERVAL_CONCURRENCE: "InspectionIntervalConcurrence",
  RAILING_RATING: "RailingRating",
  SIGN_TYPE: "SignType",
  SIGN_CONDITION_RAITING: "SignConditionRating",
  TRANSITION_RATING: "TransitionRating",
  APPROACH_GUIDE_RAIL_RATING: "ApproachGuideRailRating",
  APPROACH_RAIL_END_RATING: "ApproachRailEndRating",
  APPROACH_SPAN_DESIGN: "ApproachSpanDesign",
  OWNER: "Owner",
  NBIS_BRIDGE_LENGTH: "NBISBridgeLength",
  FC_GROUP_NUMBER: "FCGroupNumber",
  CRF_FATIGUE_SUSCEPTIBILITY: "CRFFatigueSusceptibility",
  CRF_MATERIAL: "CRFMaterial",
  CUMULATIVE_AVERAGE_DAILY_TRUCK_TRAFFIC: "CumulativeAverageDailyTruckTraffic",
  STRUCTURE_UNIT_TYPE: "StructureUnitType",
  CONSULTANT_HIRED_BY: "ConsultantHiredBy",
  INACCESSIBLE_AREA: "InaccessibleArea",
  PIER_ABUTMENT_TYPE: "PierAbutmentType",
  PA_FOUNDATION_TYPE: "PAFoundationType",
  STREAM_BED_MATERIAL: "StreamBedMaterial",
  OBSERVED_SCOUR_RATING: "ObservedScourRating",
  SCOUR_CONDITION_RATING: "ScourConditionRating",
  SCOUR_VULNERABILITY: "ScourVulnerability",
  UNDERWATER_INSPECTION_TYPE: "UnderwaterInspectionType",
  CHANGE_SINCE_LAST_INSP: "ChangeSinceLastInspection",
  SCOUR_HOLE: "ScourHole",
  DEBRIS_POTENTIAL: "DebrisPotential",
  SUBSTRUCTURE_SCOURABILITY: "SubstructureScourability",
  OPENING_ADEQUACY_CHANNEL: "OpeningAdequacyChannel",
  SEDIMENT_DEPOSITS: "SedimentDeposits",
  ALIGNMENT: "Alignment",
  VELOCITY_STREAM_SLOPE: "VelocityStreamSlope",
  JOINT_CONDITION_RATING: "JointConditionRating",
  JOINT_TYPE: "JointType",
  JOINT_MOVEMENT: "JointMovement",
  JOINT_MANUFACTURER: "JointManufacturer",
  JOINT_REPLACEMENT_REASON: "JointReplacementReason",
  BEARING_CONDITION_RATING: "BearingConditionRating",
  BEARING_TYPE: "BearingType",
  BEARING_SUBSTRUCTURE_UNIT: "BearingSubstructureUnit",
  BEARING_MOVEMENT: "BearingMovement",
  BEARING_CORROSION: "BearingCorrosion",
  BEARING_ALLIGNMENT_ISSUES: "BearingAlignmentIssues",
  BEARING_ANCHOR_BOLT: "BearingAnchorBoltIssues",
  BEARING_LOSS_AREA: "BearingLossAreas",
  BEARING_REPLACEMENT_REASON: "BearingReplacementReason",
  BB_MESSAGE_STATUS: "BulletinBoardMessageStatus",
  REVIEWER_ACTION: "ReviewerAction",
  LOAD_TYPE: "LoadType",
  NBI_RATING: "NBIRating",
  LOAD_RATING_METHOD: "LoadRatingMethod",
  CONTROLLING_MEMBER_TYPE: "ControllingMemberType",
  CONTROLLING_MEMBER_FATIGUE_STRESS_CATEGORY:
    "ControllingMemberFatigueStressCategory",
  FATIGUE_LOAD_TYPE: "FatigueLoadType",
  GOVERNING_CRITERIA: "GoverningCriteria",
  DESIGN_LOAD: "DesignLoad",
  POSTING: "Posting",
  YES_NO: "YesNo",
  TUNNEL_LOAD_RATING_METHOD: "TunnelLoadRatingMethod",
  CULVERT_OPENING_TYPE: "CulvertOpeningType",
  CULVERT_TIE_TYPE: "CulvertTieType",
  CULVERT_FLOOR_TYPE: "CulvertFloorType",
  SIGN_LIGHT_CONDITION_RATING: "SignLightConditionRating",
  SIGN_ASSET_TAG: "SignAssetTag",
  WALL_CONDITION_RATING: "WallConditionRating",
  BACKFILL_MATERIAL: "BackfillMaterial",
  TUNNEL_SHAPE: "TunnelShape",
  PORTAL_SHAPE: "PortalShape",
  GROUND_CONDITION: "GroundCondition",
  COMPLEX: "Complex",
  SERVICE_IN_TUNNEL: "ServiceInTunnel",
  DIRECTION_OF_TRAVEL: "DirectionOfTravel",
  TOLL: "Toll",
  TUNNEL_FUNCTIONAL_CLASSIFICATION: "TunnelFunctionalClassification",
  URBAN_CODE: "UrbanCode",
  IN_DEPTH: "InDepthInspectionScheduled",
  SCOUR_CRITICAL_BRIDGE_INDICATOR: "ScourCriticalBridgeIndicator",
  SCOUR_CRITICAL_BRIDGE_INDICATOR_SOURCE: "ScourCriticalBridgeIndicatorSource",
  ABUTMENT_LOCATION: "AbutmentLocation",
  WINGWALL_PRESENCE: "WingwallPresence",
  UPSTREAM_WINGWALL_CONDITION: "UpstreamWingwallCondition",
  CURRENT_COUNTERMEASURE_TYPE: "CurrentCountermeasureType",
  CURRENT_COUNTERMEASURE_LOCATION: "CurrentCountermeasureLocation",
  CURRENT_COUNTERMEASURE_CONDITION: "CurrentCountermeasureCondition",
  WORK_CANDIDATE: "WorkCandidate",
  OSA_FOUNDATION_TYPE: "OSAFoundationType",
  METHOD1_ELIGIBILITY: "Method1Eligibility",
  OVERTOPPING_RISK: "OvertoppingRisk",
  TRAFFIC_DELAY: "TrafficDelay",
  MINIMUM_UNDERCLEARANCE: "MinimumUnderclearance",
  NAVIGATION_CONTROL: "NavigationControl",
  NSTM_DETAIL_PRESENT: "NSTMDetailPresent",
  NSTM_PRESENT: "NSTMPresent",
  ON_UNDER: "OnUnder",
  POSTING_TYPE: "PostingType",
  POSTING_CONDITION: "PostingCondition",
  VEHICLE_POSTING_TYPE: "VehiclePostingType",
  ROUTE_DIRECTION: "RouteDirection",
  ROADWAY_FUNCTIONAL_CLASSIFICATION: "RoadwayFunctionalClassification",
  WORST_FLOOD_EVENT: "WorstFloodEvent",
  BRIDGE_CONDITION_RATING: "BridgeConditionRating",
  NATIONAL_HIGHWAY_SYSTEM: "NationalHighwaySystem",
  VERTICAL_CLEARANCE_SIGN: "VerticalClearanceSign",
  INSPECTION_COMMENT_TYPE: "InspectionCommentType",
  SUBSTRUCTURE_COMMENT_TYPE: "SubstructureCommentType",
  AGENCY_PRIORITY: "AgencyPriority",
  MAINTENANCE_ACTION: "MaintenanceAction",
  AGENCY_STATUS: "AgencyStatus",
  ACTION_SCOPE: "ActionScope",
  WORK_ASSIGNMENT: "WorkAssignment",
  FEATURE_TYPE: "FeatureType",
  RAILROAD_NAME: "RailroadName",
  SERVICE_STATUS: "ServiceStatus",
  SEISMIC_VULNERABILITY: "SeismicVulnerability",
  STREAM_CLASSIFICATION: "StreamClassification",
  WATERFLOW_DIRECTION: "WaterflowDirection",
  SEVERITY: "Severity",
  FATIGUE_DETAILS: "FatigueDetails",
  SPECIAL_CONDITIONS: "SpecialCondition",
  AASHTO_IMPACT_CODE: "AASHTOImpactCode",
  SCOUR_POA_REQUIRED: "ScourPOARequired",
  SNBI_INSPECTION_TYPE: "SNBIInspectionType",
  RBI_METHOD: "RBIMethod",
  INSPECTION_EQUIPMENT: "InspectionEquipment",
  BPR_STATUS: "BPRStatus",
  FEDERAL_AID_SYSTEM: "FederalAidSystem",
  BRIDGE_GROUP: "BridgeGroup",
  STATE_CODE: "StateCode",
  BORDER_BRIDGE_STATE: "BorderBridgeState",
  BOUNDARY: "Boundary",
  METROPOLITAN_PLANNING_ORG: "MetropolitanPlanningOrg",
  PARALLEL_STRUCTURE: "ParallelStructure",
  AGENCY_RESPONSIBLE: "AgencyResponsible",
  PORTION_RESPONSIBLE: "PortionResponsible",
  FEDERAL_FUNDING: "FederalFunding",
  DEICING_EQUIP: "DeicingEquip",
  ACM_STATUS: "ACMStatus",
  ACM_QUANTITY_CODE: "ACMQuantityCode",
  STRUCTURE_GROUP_TYPE: "StructureGroupType",
  STRUCTURE_GROUP_RELATIONSHIP: "StructureGroupRelationship",
  STRUCTURE_IDENTIFICATION_TOLL: "StructureIdentificationToll",
  EMER_EVAC_DESIGNATION: "EmerEvacDesignation",
  FED_TRIBAL_LAND_ACCESS: "FedTribalLandAccess",
  BORDER_BRIDGE_INSP_RESP: "BorderBridgeInspResp",
  ROADSIDE_HARDWARE: "RoadsideHardware",
  BRIDGE_MEDIAN_TYPE: "BridgeMedian",
  CURVED_BRIDGE: "CurvedBridge",
  SIDEHILL_BRIDGE: "SidehillBridge",
  COATING_EXTENT: "CoatingExtent",
  PRIMER_COATING_TYPE: "PrimerCoatingType",
  INTERMEDIATE_COATING_TYPE: "IntermediateCoatingType",
  FINISH_COATING_TYPE: "FinishCoatingType",
  COATING_COLOR: "CoatingColor",
  CLEANING_TYPE: "CleaningType",
  DESIGN_METHOD: "DesignMethod",
  BEAMS_DESIGNED_LLC: "BeamsDesignedLLC",
  DESIGN_EXCEPTION: "DesignException",
  MAIN_SPANSET_INDICATOR: "MainSpanSetIndicator",
  SPAN_CONFIG_DESIGNATION: "SpanConfigDesignation",
  SPAN_MATERIAL: "SpanMaterial",
  SPAN_CONTINUITY: "SpanContinuity",
  SPAN_TYPE: "SpanType",
  SPAN_PROTECTION_SYSTEM: "SpanProtectionSystem",
  DECK_INTERACTION: "DeckInteraction",
  DECK_MATERIAL_TYPE: "DeckMaterialType",
  WEARING_SURFACE: "WearingSurface",
  DECK_PROTECTIVE_SYSTEM: "DeckProtectiveSystem",
  DECK_REINFORCING_PROTECTIVE_SYSTEM: "DeckReinforcingProtectiveSystem",
  DECK_STAY_IN_PLACE_FORMS: "DeckStayInPlaceForms",
  FIELD_SPLICE_TYPE: "FieldSpliceType",
  STEEL_TYPE: "SteelType",
  STRAND_TYPE: "StrandType",
  PS_SPLICE_TYPE_DESIGN: "PSSpliceTypeDesign",
  PS_SPLICE_TYPE_FILLER: "PSSpliceTypeFiller",
  PS_SPLICE_TYPE_THROUGH: "PSSpliceTypeThrough",
  VOID_TYPE: "VoidType",
  STRAND_SIZE: "StrandSize",
  HAUNCH_TYPE: "HaunchType",
  SUBSTRUCTURE_CONFIG_DESIGNATION: "SubstructureConfigDesignation",
  SUBSTRUCTURE_MATERIAL: "SubstructureMaterial",
  SUBSTRUCTURE_TYPE: "SubstructureType",
  SUBSTRUCTURE_PROTECTIVE_SYSTEM: "SubstructureProtectiveSystem",
  SUBSTRUCTURE_FOUNDATION_TYPE: "SubstructureFoundationType",
  SUBSTRUCTURE_FOUNDATION_PROTECTIVE_SYSTEM:
    "SubstructureFoundationProtectiveSystem",
  SPECIAL_PIER_CAP_TYPE: "SpecialPierCapType",
  BUSINESS_PARTNER_ASSIGN_TYPE: "BPAssignType",
  COMPLEX_FEATURE: "ComplexFeaturePresent",
  FEATURE_LOCATION: "FeatureLocation",
  NATIONAL_HWY_FREIGHT_NETWORK: "NationalHwyFreightNetwork",
  STRAHNET_DESIGNATION: "StrahnetDesignation",
  ADMIN_JURISDICTION: "AdminJurisdiction",
  GOVERNMENT_CONTROL: "GovernmentControl",
  FEDERAL_AID: "FederalAid",
  URBAN_RURAL: "UrbanRural",
  HIGHWAY_INDICATOR: "HighwayIndicator",
  HIGHWAY_SYSTEM_TYPE: "HighwaySystemType",
  FEATURE_MEDIAN_TYPE: "FeatureMedianType",
  FEATURE_ROUTE_DIRECTION: "FeatureRouteDirection",
  ROUTE_TYPE: "RouteType",
  SERVICE_TYPE: "ServiceType",
  HIGHWAY_BASE_NETWORK: "HighwayBaseNetwork",
  RMS_ROADWAY_BPN: "RMSRoadwayBPN",
  HIGHWAY_NETWORK_STATE_CODE: "HighwayNetworkStateCode",
  INTERSTATE_NETWORK: "InterstateNetwork",
  ACCESS_TANDEM_TRAILER_TRUCK_NETWORK: "AccessTandemTrailerTruckNetwork",
  RMS_NHS: "RMSNHS",
  TANDEM_TRAILER_TRUCK_NETWORK: "TandemTrailerTruckNetwork",
  SUBSTRUCTURE_PROTECTION: "SubstructureProtection",
  RR_SERVICE_TYPE: "RRServiceType",
  UTILITY_TYPE: "UtilityType",
  SCOPE_OF_WORK: "ScopeOfWork",
  SCOPE_OF_REHAB: "ScopeOfRehab",
  SPN_STATUS: "SPNStatus",
  SPN_BRIDGE_FOUNDATION: "SPNBridgeFoundation",
  SPN_LINK_TYPE: "SPNLinkType",
  SPN_PREFIX: "SPNPrefix",
  SPN_SUFFIX: "SPNSuffix",
  PERMIT_TYPE: "PermitType",
  SIDEWALK_TYPE: "SidewalkType",
  HORIZONTAL_CURVE: "HorizontalCurve",
  VERTICAL_CURVE: "VerticalCurve",
  STRUCTURE_TYPE: "StructureType",
  PIER_PROTECTION: "PierProtection",
  PRESERVATION_AND_REHAB_PROP_WORK: "ProposedSubWork",
  PRESERVATION_AND_REHAB_PROP_DECK_SUPER_WORK: "ProposedDeckSuperWork",
  PRESERVATION_AND_REHAB_WORK_SCOPE: "WorkScope",
  PRESERVATION_AND_REHAB_WORK_STATUS: "WorkStatus",
  PRESERVATION_AND_REHAB_JOINT_AND_BEARING_WORK_SCOPE:
    "JointAndBearingWorkScope",
  LEGAL_LOAD_RATING_FACTOR_WEIGHT: "LegalLoadRatingFactorWeight",
  ROUTINE_PERMIT_LOADS: "RoutinePermitLoads",
};

export const REFERENCE_TABLES = [
  REFERENCE_TABLE.APPROACH_SPAN_MATERIAL,
  REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL,
  REFERENCE_TABLE.BB_MESSAGE_STATUS,
  REFERENCE_TABLE.BRIDGE_DECK_TYPE,
  REFERENCE_TABLE.BRIDGE_MEDIAN,
  REFERENCE_TABLE.BUS_PLAN_NETWORK,
  REFERENCE_TABLE.CONDITION_RATING,
  REFERENCE_TABLE.DECK_FORM_TYPE,
  REFERENCE_TABLE.DECK_MEMBRANE_TYPE,
  REFERENCE_TABLE.DECK_PROTECTION_TYPE,
  REFERENCE_TABLE.DECK_SURFACE_TYPE,
  REFERENCE_TABLE.DECK_STRUCTURE_TYPE,
  REFERENCE_TABLE.DRAWING_TYPE,
  REFERENCE_TABLE.ENVIRONMENT,
  REFERENCE_TABLE.EXTENDED_INSPECTION_INTERVAL_ELIGIBILITY,
  REFERENCE_TABLE.FRACTURE_CRITICAL_FATIGUE_STRESS_CATEGORY,
  REFERENCE_TABLE.FIELD_COND,
  REFERENCE_TABLE.FRACTURE_CRITICAL_MEMBER_TYPE,
  REFERENCE_TABLE.HISTORICAL_SIGNIFIGANCE,
  REFERENCE_TABLE.INSPECTION_STATUS,
  REFERENCE_TABLE.INSPECTION_TYPE,
  REFERENCE_TABLE.MAIN_SPAN_DESIGN,
  REFERENCE_TABLE.MAIN_SPAN_MATERIAL,
  REFERENCE_TABLE.MAINTENENCE_RESPONSIBILITY,
  REFERENCE_TABLE.MATERIAL_STORED_UNDER,
  REFERENCE_TABLE.MATERIAL_MAKEUP,
  REFERENCE_TABLE.MEMBER_TYPE,
  REFERENCE_TABLE.PAINT_CONDITION_RATING,
  REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT,
  REFERENCE_TABLE.PHYSICAL_MAKEUP,
  REFERENCE_TABLE.PLUG_WELD_PROBLEM_TYPE,
  REFERENCE_TABLE.POST_REASON,
  REFERENCE_TABLE.POSTING_STATUS,
  REFERENCE_TABLE.RELIEF_JOINT,
  REFERENCE_TABLE.REPORTING_GROUP,
  REFERENCE_TABLE.SIGN_TYPE,
  REFERENCE_TABLE.SIGN_CONDITION_RAITING,
  REFERENCE_TABLE.SERVICE_TYPE_ON,
  REFERENCE_TABLE.SPAN_INTERACTION,
  REFERENCE_TABLE.SPECIAL_RESTRICT_POST,
  REFERENCE_TABLE.STRUCTURAL_CONFIGURATION,
  REFERENCE_TABLE.STRUCTURAL_CONFIGURATION_OF_APPROACH_SPANS,
  REFERENCE_TABLE.STRUCTURE_FLARED,
  REFERENCE_TABLE.SUB_AGENCY,
  REFERENCE_TABLE.SERVICE_TYPE_UNDER,
  REFERENCE_TABLE.TEMPORARY_STRUCTURE,
  REFERENCE_TABLE.WEATHER_CONDITION,
  REFERENCE_TABLE.TEAM_LEADER,
  REFERENCE_TABLE.INSPECTION_PERFORMED_BY,
  REFERENCE_TABLE.INSPECTION_INTERVAL_CONCURRENCE,
  REFERENCE_TABLE.RAILING_RATING,
  REFERENCE_TABLE.TRANSITION_RATING,
  REFERENCE_TABLE.APPROACH_GUIDE_RAIL_RATING,
  REFERENCE_TABLE.APPROACH_RAIL_END_RATING,
  REFERENCE_TABLE.APPROACH_SPAN_DESIGN,
  REFERENCE_TABLE.OWNER,
  REFERENCE_TABLE.NBIS_BRIDGE_LENGTH,
  REFERENCE_TABLE.FC_GROUP_NUMBER,
  REFERENCE_TABLE.CRF_FATIGUE_SUSCEPTIBILITY,
  REFERENCE_TABLE.CRF_MATERIAL,
  REFERENCE_TABLE.CUMULATIVE_AVERAGE_DAILY_TRUCK_TRAFFIC,
  REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
  REFERENCE_TABLE.CONSULTANT_HIRED_BY,
  REFERENCE_TABLE.INACCESSIBLE_AREA,
  REFERENCE_TABLE.PIER_ABUTMENT_TYPE,
  REFERENCE_TABLE.PA_FOUNDATION_TYPE,
  REFERENCE_TABLE.STREAM_BED_MATERIAL,
  REFERENCE_TABLE.OBSERVED_SCOUR_RATING,
  REFERENCE_TABLE.SCOUR_CONDITION_RATING,
  REFERENCE_TABLE.SCOUR_VULNERABILITY,
  REFERENCE_TABLE.UNDERWATER_INSPECTION_TYPE,
  REFERENCE_TABLE.CHANGE_SINCE_LAST_INSP,
  REFERENCE_TABLE.SCOUR_HOLE,
  REFERENCE_TABLE.DEBRIS_POTENTIAL,
  REFERENCE_TABLE.SUBSTRUCTURE_SCOURABILITY,
  REFERENCE_TABLE.OPENING_ADEQUACY_CHANNEL,
  REFERENCE_TABLE.SEDIMENT_DEPOSITS,
  REFERENCE_TABLE.ALIGNMENT,
  REFERENCE_TABLE.VELOCITY_STREAM_SLOPE,
  REFERENCE_TABLE.JOINT_CONDITION_RATING,
  REFERENCE_TABLE.JOINT_TYPE,
  REFERENCE_TABLE.JOINT_MOVEMENT,
  REFERENCE_TABLE.JOINT_MANUFACTURER,
  REFERENCE_TABLE.JOINT_REPLACEMENT_REASON,
  REFERENCE_TABLE.BEARING_CONDITION_RATING,
  REFERENCE_TABLE.BEARING_TYPE,
  REFERENCE_TABLE.BEARING_SUBSTRUCTURE_UNIT,
  REFERENCE_TABLE.BEARING_MOVEMENT,
  REFERENCE_TABLE.BEARING_CORROSION,
  REFERENCE_TABLE.BEARING_ALLIGNMENT_ISSUES,
  REFERENCE_TABLE.BEARING_ANCHOR_BOLT,
  REFERENCE_TABLE.BEARING_LOSS_AREA,
  REFERENCE_TABLE.BEARING_REPLACEMENT_REASON,
  REFERENCE_TABLE.REVIEWER_ACTION,
  REFERENCE_TABLE.LOAD_TYPE,
  REFERENCE_TABLE.NBI_RATING,
  REFERENCE_TABLE.LOAD_RATING_METHOD,
  REFERENCE_TABLE.CONTROLLING_MEMBER_TYPE,
  REFERENCE_TABLE.CONTROLLING_MEMBER_FATIGUE_STRESS_CATEGORY,
  REFERENCE_TABLE.FATIGUE_LOAD_TYPE,
  REFERENCE_TABLE.GOVERNING_CRITERIA,
  REFERENCE_TABLE.DESIGN_LOAD,
  REFERENCE_TABLE.POSTING,
  REFERENCE_TABLE.POSTING_TYPE,
  REFERENCE_TABLE.POSTING_CONDITION,
  REFERENCE_TABLE.VEHICLE_POSTING_TYPE,
  REFERENCE_TABLE.YES_NO,
  REFERENCE_TABLE.TUNNEL_LOAD_RATING_METHOD,
  REFERENCE_TABLE.CULVERT_OPENING_TYPE,
  REFERENCE_TABLE.CULVERT_TIE_TYPE,
  REFERENCE_TABLE.CULVERT_FLOOR_TYPE,
  REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING,
  REFERENCE_TABLE.SIGN_ASSET_TAG,
  REFERENCE_TABLE.WALL_CONDITION_RATING,
  REFERENCE_TABLE.BACKFILL_MATERIAL,
  REFERENCE_TABLE.TUNNEL_SHAPE,
  REFERENCE_TABLE.PORTAL_SHAPE,
  REFERENCE_TABLE.GROUND_CONDITION,
  REFERENCE_TABLE.COMPLEX,
  REFERENCE_TABLE.SERVICE_IN_TUNNEL,
  REFERENCE_TABLE.DIRECTION_OF_TRAVEL,
  REFERENCE_TABLE.TOLL,
  REFERENCE_TABLE.TUNNEL_FUNCTIONAL_CLASSIFICATION,
  REFERENCE_TABLE.URBAN_CODE,
  REFERENCE_TABLE.IN_DEPTH,
  REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR,
  REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR_SOURCE,
  REFERENCE_TABLE.ABUTMENT_LOCATION,
  REFERENCE_TABLE.WINGWALL_PRESENCE,
  REFERENCE_TABLE.UPSTREAM_WINGWALL_CONDITION,
  REFERENCE_TABLE.CURRENT_COUNTERMEASURE_TYPE,
  REFERENCE_TABLE.CURRENT_COUNTERMEASURE_LOCATION,
  REFERENCE_TABLE.CURRENT_COUNTERMEASURE_CONDITION,
  REFERENCE_TABLE.WORK_CANDIDATE,
  REFERENCE_TABLE.OVERTOPPING_RISK,
  REFERENCE_TABLE.TRAFFIC_DELAY,
  REFERENCE_TABLE.METHOD1_ELIGIBILITY,
  REFERENCE_TABLE.MINIMUM_UNDERCLEARANCE,
  REFERENCE_TABLE.NAVIGATION_CONTROL,
  REFERENCE_TABLE.NSTM_DETAIL_PRESENT,
  REFERENCE_TABLE.NSTM_PRESENT,
  REFERENCE_TABLE.OSA_FOUNDATION_TYPE,
  REFERENCE_TABLE.ON_UNDER,
  REFERENCE_TABLE.ROUTE_DIRECTION,
  REFERENCE_TABLE.ROADWAY_FUNCTIONAL_CLASSIFICATION,
  REFERENCE_TABLE.WORST_FLOOD_EVENT,
  REFERENCE_TABLE.BRIDGE_CONDITION_RATING,
  REFERENCE_TABLE.NATIONAL_HIGHWAY_SYSTEM,
  REFERENCE_TABLE.VERTICAL_CLEARANCE_SIGN,
  REFERENCE_TABLE.INSPECTION_COMMENT_TYPE,
  REFERENCE_TABLE.SUBSTRUCTURE_COMMENT_TYPE,
  REFERENCE_TABLE.AGENCY_PRIORITY,
  REFERENCE_TABLE.MAINTENANCE_ACTION,
  REFERENCE_TABLE.AGENCY_STATUS,
  REFERENCE_TABLE.ACTION_SCOPE,
  REFERENCE_TABLE.WORK_ASSIGNMENT,
  REFERENCE_TABLE.FEATURE_TYPE,
  REFERENCE_TABLE.RAILROAD_NAME,
  REFERENCE_TABLE.SERVICE_STATUS,
  REFERENCE_TABLE.SEISMIC_VULNERABILITY,
  REFERENCE_TABLE.STREAM_CLASSIFICATION,
  REFERENCE_TABLE.WATERFLOW_DIRECTION,
  REFERENCE_TABLE.SEVERITY,
  REFERENCE_TABLE.FATIGUE_DETAILS,
  REFERENCE_TABLE.SPECIAL_CONDITIONS,
  REFERENCE_TABLE.AASHTO_IMPACT_CODE,
  REFERENCE_TABLE.SCOUR_POA_REQUIRED,
  REFERENCE_TABLE.SNBI_INSPECTION_TYPE,
  REFERENCE_TABLE.RBI_METHOD,
  REFERENCE_TABLE.INSPECTION_EQUIPMENT,
  REFERENCE_TABLE.BPR_STATUS,
  REFERENCE_TABLE.FEDERAL_AID_SYSTEM,
  REFERENCE_TABLE.BRIDGE_GROUP,
  REFERENCE_TABLE.STATE_CODE,
  REFERENCE_TABLE.BORDER_BRIDGE_STATE,
  REFERENCE_TABLE.BOUNDARY,
  REFERENCE_TABLE.METROPOLITAN_PLANNING_ORG,
  REFERENCE_TABLE.PARALLEL_STRUCTURE,
  REFERENCE_TABLE.AGENCY_RESPONSIBLE,
  REFERENCE_TABLE.PORTION_RESPONSIBLE,
  REFERENCE_TABLE.FEDERAL_FUNDING,
  REFERENCE_TABLE.DEICING_EQUIP,
  REFERENCE_TABLE.ACM_STATUS,
  REFERENCE_TABLE.ACM_QUANTITY_CODE,
  REFERENCE_TABLE.STRUCTURE_GROUP_TYPE,
  REFERENCE_TABLE.STRUCTURE_GROUP_RELATIONSHIP,
  REFERENCE_TABLE.STRUCTURE_IDENTIFICATION_TOLL,
  REFERENCE_TABLE.EMER_EVAC_DESIGNATION,
  REFERENCE_TABLE.FED_TRIBAL_LAND_ACCESS,
  REFERENCE_TABLE.BORDER_BRIDGE_INSP_RESP,
  REFERENCE_TABLE.ROADSIDE_HARDWARE,
  REFERENCE_TABLE.BRIDGE_MEDIAN_TYPE,
  REFERENCE_TABLE.CURVED_BRIDGE,
  REFERENCE_TABLE.SIDEHILL_BRIDGE,
  REFERENCE_TABLE.COATING_EXTENT,
  REFERENCE_TABLE.PRIMER_COATING_TYPE,
  REFERENCE_TABLE.INTERMEDIATE_COATING_TYPE,
  REFERENCE_TABLE.FINISH_COATING_TYPE,
  REFERENCE_TABLE.COATING_COLOR,
  REFERENCE_TABLE.CLEANING_TYPE,
  REFERENCE_TABLE.DESIGN_METHOD,
  REFERENCE_TABLE.BEAMS_DESIGNED_LLC,
  REFERENCE_TABLE.DESIGN_EXCEPTION,
  REFERENCE_TABLE.MOUNT_TYPE,
  REFERENCE_TABLE.MOUNT_BOLT_BASE,
  REFERENCE_TABLE.ROAD_SIDE,
  REFERENCE_TABLE.FOUND_MATERIAL_TYPE,
  REFERENCE_TABLE.MAIN_SPANSET_INDICATOR,
  REFERENCE_TABLE.SPAN_CONFIG_DESIGNATION,
  REFERENCE_TABLE.SPAN_MATERIAL,
  REFERENCE_TABLE.SPAN_CONTINUITY,
  REFERENCE_TABLE.SPAN_TYPE,
  REFERENCE_TABLE.SPAN_PROTECTION_SYSTEM,
  REFERENCE_TABLE.DECK_INTERACTION,
  REFERENCE_TABLE.DECK_MATERIAL_TYPE,
  REFERENCE_TABLE.WEARING_SURFACE,
  REFERENCE_TABLE.DECK_PROTECTIVE_SYSTEM,
  REFERENCE_TABLE.DECK_REINFORCING_PROTECTIVE_SYSTEM,
  REFERENCE_TABLE.DECK_STAY_IN_PLACE_FORMS,
  REFERENCE_TABLE.FIELD_SPLICE_TYPE,
  REFERENCE_TABLE.STEEL_TYPE,
  REFERENCE_TABLE.STRAND_TYPE,
  REFERENCE_TABLE.PS_SPLICE_TYPE_DESIGN,
  REFERENCE_TABLE.PS_SPLICE_TYPE_FILLER,
  REFERENCE_TABLE.PS_SPLICE_TYPE_THROUGH,
  REFERENCE_TABLE.VOID_TYPE,
  REFERENCE_TABLE.STRAND_SIZE,
  REFERENCE_TABLE.HAUNCH_TYPE,
  REFERENCE_TABLE.SUBSTRUCTURE_CONFIG_DESIGNATION,
  REFERENCE_TABLE.SUBSTRUCTURE_MATERIAL,
  REFERENCE_TABLE.SUBSTRUCTURE_TYPE,
  REFERENCE_TABLE.SUBSTRUCTURE_PROTECTIVE_SYSTEM,
  REFERENCE_TABLE.SUBSTRUCTURE_FOUNDATION_TYPE,
  REFERENCE_TABLE.SUBSTRUCTURE_FOUNDATION_PROTECTIVE_SYSTEM,
  REFERENCE_TABLE.SPECIAL_PIER_CAP_TYPE,
  REFERENCE_TABLE.BUSINESS_PARTNER_ASSIGN_TYPE,
  REFERENCE_TABLE.COMPLEX_FEATURE,
  REFERENCE_TABLE.SCOPE_OF_WORK,
  REFERENCE_TABLE.SCOPE_OF_REHAB,
  REFERENCE_TABLE.SPN_STATUS,
  REFERENCE_TABLE.SPN_BRIDGE_FOUNDATION,
  REFERENCE_TABLE.SPN_LINK_TYPE,
  REFERENCE_TABLE.SPN_PREFIX,
  REFERENCE_TABLE.SPN_SUFFIX,
  REFERENCE_TABLE.PERMIT_TYPE,
  REFERENCE_TABLE.SIDEWALK_TYPE,
  REFERENCE_TABLE.HORIZONTAL_CURVE,
  REFERENCE_TABLE.VERTICAL_CURVE,
  REFERENCE_TABLE.WALL_FOUNDATION_TYPE,
  REFERENCE_TABLE.WALL_USE,
  REFERENCE_TABLE.WALL_MOUNT_TYPE,
  REFERENCE_TABLE.WALL_POST_TYPE,
  REFERENCE_TABLE.REBAR_PROTECTION_TYPE,
  REFERENCE_TABLE.SUPPORT_DESCRIPTION,
  REFERENCE_TABLE.ARCHITECTURAL_FORMS,
  REFERENCE_TABLE.INSTALLED_ROADWAY_TYPE,
  REFERENCE_TABLE.UNDER_NAVIGABLE_WATERWAY,
  REFERENCE_TABLE.PROTECTION_FROM_NAVIGATION,
  REFERENCE_TABLE.LEGAL_LOAD_RATING_FACTOR_WEIGHT,
  REFERENCE_TABLE.ROUTINE_PERMIT_LOADS,
  REFERENCE_TABLE.STRAHNET_DESIGNATION,
  REFERENCE_TABLE.PRESERVATION_AND_REHAB_PROP_WORK,
  REFERENCE_TABLE.PRESERVATION_AND_REHAB_PROP_DECK_SUPER_WORK,
  REFERENCE_TABLE.PRESERVATION_AND_REHAB_WORK_SCOPE,
  REFERENCE_TABLE.PRESERVATION_AND_REHAB_WORK_STATUS,
  REFERENCE_TABLE.PRESERVATION_AND_REHAB_JOINT_AND_BEARING_WORK_SCOPE,
];

<template>
  <v-container fluid class="blankSpace" id="landingPage_container">
    <v-row>
      <v-col class="px-0 pt-0" cols="12">
        <div
          v-if="external"
          class="withBlueBackground px-2"
          id="landingPage_publicWelcomeMessage"
        >
          Welcome to the public facing landing page of the PennDOT Bridge
          Management System 3 (BMS3). Authorized users may access the login page
          to access the site to view and maintain inventory and inspection
          information for Pennsylvania bridges and other structures. BMS3 PWA
          supports decision-making to help maintain the long-term health of
          bridges and to formulate optimal programs for bridge maintenance and
          rehabilitation.
        </div>
        <div
          v-else
          class="withBlueBackground px-2"
          id="landingPage_welcomeMessage"
        >
          Welcome to the progressive web application of the PennDOT Bridge
          Management System 3 (BMS3). Authorized users may access this site to
          view and maintain inventory and inspection information for
          Pennsylvania bridges and other structures. BMS3 PWA supports
          decision-making to help maintain the long-term health of bridges and
          to formulate optimal programs for bridge maintenance and
          rehabilitation.
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!external && hasAccessToQuickSearchStructure">
      <v-col>
        <v-row justify="end" class="withRowBackground mb-2">
          <v-col class="px-0 alignCenter" cols="4">
            <h3
              class="align-center whiteFont"
              id="landingPage_structureQuickSearchHeader"
            >
              Structure Quick Search
            </h3>
          </v-col>
          <v-col class="pr-6 alignRight" cols="4">
            <v-btn
              class="align-self-center landingPageButton"
              id="btn_goToStructureSearch"
              variant="outlined"
              @click="goToStructureSearch()"
            >
              Structure Search
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="5"
            sm="5"
            md="4"
            lg="4"
            xl="3"
            class="d-flex pl-2 pb-0 pt-2"
          >
            <v-label class="pt-1 pr-2">BRKEY:</v-label>
            <v-text-field
              class="pr-2"
              id="input_searchBrkey"
              v-model="brKey"
              autofocus
              single-line
              v-maska="MASK.NUMBER"
              clearable
              @keyup.enter="enterKeyHandlerForBRKey"
            ></v-text-field>
            <v-btn
              aria-label="BRKEY Quick Search"
              id="btn_retrieveBrkey"
              variant="outlined"
              @click="readDataFromAPIBrkey()"
            >
              GO
            </v-btn>
          </v-col>
          <v-col
            cols="1"
            sm="1"
            md="3"
            lg="3"
            xl="5"
            class="d-flex pb-0 pt-2"
          />
          <v-col cols="5" md="4" lg="4" xl="3" class="d-flex pl-2 pb-0 pt-2">
            <v-label class="pt-1 pr-2">BMS ID:</v-label>
            <v-text-field
              class="pr-2"
              id="input_searchBmsId"
              v-model="bmsId"
              autofocus
              v-maska="MASK.NUMBER"
              single-line
              clearable
              @keyup.enter="enterKeyHandlerForBMSId"
            ></v-text-field>
            <v-btn
              aria-label="BMS ID Quick Search"
              id="btn_retrieveBmsId"
              variant="outlined"
              @click="readDataFromAPIBmsid()"
            >
              GO
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="!external && hasAccessToQuickSearchSPN">
      <v-col>
        <v-row justify="end" class="withRowBackground mb-2">
          <v-col class="px-0 alignCenter" cols="4">
            <h3
              class="align-center whiteFont"
              id="landingPage_spnQuickSearchHeader"
            >
              SPN Quick Search
            </h3>
          </v-col>
          <v-col class="pr-6 alignRight" cols="4">
            <v-btn
              class="align-self-center landingPageButton"
              id="btn_goToSPNSearch"
              variant="outlined"
              @click="goToSPNSearch()"
            >
              SPN Search
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col
            cols="11"
            sm="11"
            md="11"
            lg="6"
            xl="5"
            class="d-flex pl-2 pb-2 pt-2 mr-8 verticalAlignment-center"
          >
            <v-label class="pt-1 pr-2">S/L Number:</v-label>
            <v-row class="pa-0 ma-0">
              <v-col
                cols="3"
                sm="3"
                md="3"
                lg="3"
                xl="3"
                class="d-flex pa-0 ma-0"
              >
                <v-select
                  id="select_searchPrefix"
                  clearable
                  hide-details="auto"
                  :items="slPrefixes"
                  v-model="slNumberPrefix"
                  class="pr-2 d-inline"
                >
                </v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                class="d-flex pa-0 ma-0"
              >
                <v-text-field
                  class="pr-2 d-inline"
                  id="input_searchSlNumber"
                  hide-details="auto"
                  :maxlength="5"
                  v-model="slNumber"
                  v-maska="MASK.NUMBER"
                  single-line
                  clearable
                ></v-text-field>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                md="3"
                lg="3"
                xl="3"
                class="d-flex pa-0 ma-0"
              >
                <v-select
                  id="input_searchSuffix"
                  clearable
                  hide-details="auto"
                  :items="slSuffixes"
                  v-model="slNumberSuffix"
                  class="pr-2 d-inline"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-btn
              aria-label="S/L Number Quick Search"
              :disabled="!slNumberPrefix || !slNumber"
              @click="getSpnDetailBySlNumber()"
              variant="outlined"
            >
              GO
            </v-btn>
          </v-col>
          <v-col
            v-if="$vuetify?.display?.xlAndUp"
            lg="1"
            xl="1"
            class="d-flex pb-0 pt-2"
          />
          <v-col
            cols="11"
            sm="11"
            md="11"
            lg="5"
            xl="5"
            class="d-flex pl-2 pb-2 pt-2"
          >
            <v-label class="pt-1 pr-2">Structure Request ID:</v-label>
            <v-text-field
              class="pr-2"
              id="input_searchStructureRequestIdSearch"
              autofocus
              hide-details="auto"
              v-maska="MASK.NUMBER"
              single-line
              clearable
              v-model="structureRequestId"
            ></v-text-field>
            <v-btn
              aria-label="Structure Request ID Quick Search"
              :disabled="!structureRequestId"
              @click="getSpnDetailByStructureRequestID()"
              variant="outlined"
            >
              GO
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-0 pt-0" cols="12">
        <h3
          class="withBackground py-1 px-2"
          id="landingPage_penndotLinksHeader"
        >
          PennDOT Links
        </h3>
      </v-col>
      <v-col>
        <PenndotLinks id="externalPenndotLinks" :isExternal="external" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-0" cols="12">
        <h3
          class="withBackground py-1 px-2"
          id="landingPage_bulletinBoardHeader"
        >
          Bulletin Board
        </h3>
      </v-col>
      <v-col>
        <ExternalBulletinBoard id="externalBulletinBoard" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-0" cols="12">
        <a
          id="link_penndotPrivacyPolicy"
          class="ml-2"
          style="margin-right: auto; max-width: 60%"
          href="https://www.pa.gov/privacy-policy/"
          title="https://www.pa.gov/privacy-policy/"
        >
          <span style="font-size: small"> PENNDOT Privacy Policy </span>
        </a>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog v-model="errorPresent" :width="600">
    <v-card class="pa-3 error-dialog">
      <p class="h3 mb-6"><strong>&nbsp;</strong></p>
      <p v-if="structurePlanStore.getErrorMessage()" class="mb-2">
        {{ STRUCTURE_SEARCH_MESSAGES.SPN_NOT_FOUND }}
      </p>
      <p v-else-if="quickSearchStore.getErrorMessage()" class="mb-2">
        {{ STRUCTURE_SEARCH_MESSAGES.STRUCTURE_NOT_FOUND }}
      </p>

      <button
        @click="
          {
            structurePlanStore.setErrorMessage(false);
            quickSearchStore.setErrorMessage(false);
          }
        "
        class="d-flex align-end flex-column align-content-center close-button"
      >
        <v-icon size="x-large" icon="fas fa-xmark mx-2" />
        <small>CLOSE</small>
      </button>
    </v-card>
  </v-dialog>
</template>

<script>
import ExternalBulletinBoard from "@/components/shared/ExternalBulletinBoard.vue";
import { useQuickSearchStore } from "@/stores/quickSearch";
import router from "@/router";
import PenndotLinks from "@/components/shared/PenndotLinks.vue";
import { ref, nextTick, computed } from "vue";
import { MASK } from "@/constants/Maskas";
import { useUserStore } from "@/stores/userStore";
import { useStructurePlanStore } from "@/stores/structurePlan";
import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { STRUCTURE_SEARCH_MESSAGES } from "@/constants/StructureSearches";
import {
  STRUCTURE_IDENTIFICATION_PERMITTED_ROLES,
  STRUCTURE_PLAN_PERMITTED_ROLES,
  isUserGroupHaveAccess,
} from "@/rbac";

export default {
  name: "LandingPage",
  props: {
    external: { type: Boolean, default: false },
  },
  setup() {
    let brKey = ref(null);
    let bmsId = ref(null);
    let slNumberPrefix = ref(null);
    let slNumberSuffix = ref(null);
    let slNumber = ref(null);
    let structureRequestId = ref(null);
    const structurePlanStore = useStructurePlanStore();
    const quickSearchStore = useQuickSearchStore();
    const configStore = useConfigStore();
    const userStore = useUserStore();
    const userRoles = computed(() => userStore.getUserRoles());
    const slSuffixes = computed(() =>
      configStore.getReferenceValues(
        REFERENCE_TABLE.SPN_SUFFIX,
        null,
        null,
        true
      )
    );
    const slPrefixes = computed(() =>
      configStore.getReferenceValues(
        REFERENCE_TABLE.SPN_PREFIX,
        null,
        null,
        true
      )
    );

    const hasAccessToQuickSearchStructure = computed(() =>
      isUserGroupHaveAccess(
        userRoles.value,
        STRUCTURE_IDENTIFICATION_PERMITTED_ROLES.view
      )
    );

    const hasAccessToQuickSearchSPN = computed(() =>
      isUserGroupHaveAccess(
        userRoles.value,
        STRUCTURE_PLAN_PERMITTED_ROLES.view
      )
    );

    const readDataFromAPIBrkey = async () => {
      if (brKey.value) {
        quickSearchStore.setStructure({
          BRKEY: brKey.value,
          BMSID: "",
          REPORTGROUP: "",
        });
        await quickSearchStore.getStructureMetaDataByBRKEY();
        if (!quickSearchStore.getErrorMessage()) {
          router.push({
            name: "StructureIdentification",
          });
        }
      }
    };
    const readDataFromAPIBmsid = async () => {
      if (bmsId.value) {
        quickSearchStore.setStructure({
          BRKEY: "",
          BMSID: bmsId.value,
          REPORTGROUP: "",
        });
        await quickSearchStore.getStructureMetaDataByBMSID();
        if (!quickSearchStore.getErrorMessage()) {
          router.push({
            name: "StructureIdentification",
          });
        }
      }
    };
    const getSpnDetailBySlNumber = async () => {
      if (slNumberPrefix.value && slNumber.value) {
        const response = await structurePlanStore.getStructurePlanBySlNumber(
          slNumberPrefix.value,
          slNumber.value,
          slNumberSuffix.value,
          true
        );
        if (response) {
          structurePlanStore.setPassedSPN(response);
          router.push({ name: "StructurePlanDetails" });
        }
      }
    };
    const getSpnDetailByStructureRequestID = async () => {
      if (structureRequestId.value) {
        const response =
          await structurePlanStore.getSpnDetailByStructureRequestID(
            structureRequestId.value,
            true
          );
        if (response) {
          structurePlanStore.setPassedSPN(response);
          router.push({ name: "StructurePlanDetails" });
        }
      }
    };
    const goToStructureSearch = () => {
      router.push({
        name: "StructureSearch",
      });
    };

    const goToSPNSearch = () => {
      router.push({
        name: "StructurePlanSearch",
      });
    };
    const errorPresent = computed(
      () =>
        structurePlanStore.getErrorMessage() ||
        quickSearchStore.getErrorMessage()
    );

    const enterKeyHandlerForBRKey = () => {
      nextTick(() => document.getElementById("btn_retrieveBrkey").focus());
      document.getElementById("btn_retrieveBrkey").click();
    };

    const enterKeyHandlerForBMSId = () => {
      nextTick(() => document.getElementById("btn_retrieveBmsId").focus());
      document.getElementById("btn_retrieveBmsId").click();
    };
    return {
      structurePlanStore,
      quickSearchStore,
      STRUCTURE_SEARCH_MESSAGES,
      errorPresent,
      slSuffixes,
      slPrefixes,
      getSpnDetailByStructureRequestID,
      getSpnDetailBySlNumber,
      structureRequestId,
      slNumber,
      slNumberSuffix,
      slNumberPrefix,
      goToSPNSearch,
      hasAccessToQuickSearchSPN,
      hasAccessToQuickSearchStructure,
      enterKeyHandlerForBRKey,
      enterKeyHandlerForBMSId,
      goToStructureSearch,
      MASK,
      brKey,
      bmsId,
      readDataFromAPIBrkey,
      readDataFromAPIBmsid,
    };
  },
  components: { ExternalBulletinBoard, PenndotLinks },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;

.withBlueBackground {
  background: c.$p-pa-blue;
  color: c.$p-white;
}
.landingPageButton {
  background-color: c.$p-white;
  color: c.$p-black;

  button:focus {
    outline: c.$p-link-blue-focus solid 5px;
  }

  button:hover {
    background-color: c.$p-pa-blue;
    color: c.$p-white;
  }
}
.withRowBackground {
  background-color: c.$p-pa-light-blue;
}
.alignRight {
  text-align: right;
}
.whiteFont {
  color: c.$p-white;
}
</style>

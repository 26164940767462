import { defineStore } from "pinia";
import { ref } from "vue";
import { INSPECTION_LINKS_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import { clone } from "@/util/clone";
import { MODULE_TYPES } from "@/constants/InspectionConstants";
import { useInspectionStore } from "@/stores/inspection";
import { getIN15Value, getIN15ValueFromMaterials, areThereNonControllingIN15Changes } from "@/composables/keyFields.js";

export const useKeyfieldsStore = defineStore("keyfields", () => {
  let pageData = ref({
    currentInsp: {},
    previousInsp: {},
  });
  const inspectionStore = useInspectionStore();

  const loadDataObject = async (moduleType, brkey, inspectionId, structure) => {
    if (moduleType === MODULE_TYPES.INSPECTION) {
      //if moduleType is inspection, load data from pouchDB
      convertToInspModuleObject(structure);
    } else if (moduleType === MODULE_TYPES.MANAGEMENT && brkey && inspectionId) {
      //if moduleType is management, load data from service call
      await getInspLinkDataObject(brkey, inspectionId);
    }
  };

  const getKeyfieldsObject = () => {
    return clone(pageData.value);
  };

  const getInspLinkDataObject = async (brkey, inspectionId) => {
    //clear idb object
    pageData.value.inspection = {};
    let url = INSPECTION_LINKS_ENDPOINT.GET_KEYFIELDS.replace("{brkey}", brkey).replace("{inspectionId}", inspectionId);

    await axios
      .get(url)
      .then((resp) => {
        convertToInspLinkObject(resp.data.data);
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const convertToInspLinkObject = (response) => {
    pageData.value.currentInsp = {
      // The fields in the main table
      INSPDATE: response.inspectionDate,
      INSPTYPE: response.inspectionType,
      DKRATING: response.dkRating,
      SUPRATING: response.supRating,
      SUBRATING: response.subRating,
      CULVRATING: response.culvRating,
      RAILRATING: response.railRating,
      TRANSRATIN: response.transRating,
      BEARING_OVERALL_COND_RATE: response.bearingConditionRating,
      JOINTS_OVERALL_COND_RATE: response.jointsConditionRating,
      CHANRATING: response.chanRating,
      CHAN_PROT_RATING: response.chanProtRating,
      OVERALL_SCOUR_HOLES: response.scourHole,
      UNDERWATER_RATING: response.underwaterRating,
      NSTM_CONDITION: response.nstmCondition,
      SCOURCRIT: response.scourCritical,
      SCOUR_CRIT_CTGRY: response.scourCriticalCat,
      STREAM_BED_MATERIAL: getIN15ValueFromMaterials(response.streamBedMaterials),
      STREAM_BED_MATERIAL_NON_CONTROLLING_CHANGE: response.streamBedMaterialNonControllingChange,
      CONDITION: response.condition,
      // 7A06 "Performed" table
      NBINSPDONE: response.nbInspectionDone,
      FCINSPDONE: response.fcInspectionDone,
      UWINSPDONE: response.uwInspectionDone,
      OSINSPDONE: response.osInspectionDone,
      ELINSPDONE: response.elInspectionDone,
      // 7A07 "Required" table
      FCINSPREQ: response.fcInspectionReq,
      UWINSPREQ: response.uwInspectionReq,
      OSINSPREQ: response.osInspectionReq,
      ELINSPREQ: response.elInspectionReq,
      // 7A09 "Interval" table
      BRINSPFREQ: response.brInspectionFreq,
      FCINSPFREQ: response.fcInspectionFreq,
      UWINSPFREQ: response.uwInspectionFreq,
      OSINSPFREQ: response.osInspectionFreq,
      ELINSPFREQ: response.elInspectionFreq,
    };
    pageData.value.previousInsp = {
      INSPDATE: response.prevInspectionDate,
      INSPTYPE: response.prevInspectionType,
      DKRATING: response.prevDkRating,
      SUPRATING: response.prevSupRating,
      SUBRATING: response.prevSubRating,
      CULVRATING: response.prevCulvRating,
      RAILRATING: response.prevRailRating,
      TRANSRATIN: response.prevTransRating,
      BEARING_OVERALL_COND_RATE: response.prevBearingConditionRating,
      JOINTS_OVERALL_COND_RATE: response.prevJointsConditionRating,
      CHANRATING: response.prevChanRating,
      CHAN_PROT_RATING: response.prevChanProtRating,
      OVERALL_SCOUR_HOLES: response.prevScourHole,
      UNDERWATER_RATING: response.prevUnderwaterRating,
      NSTM_CONDITION: response.prevNstmCondition,
      SCOURCRIT: response.prevScourCritical,
      SCOUR_CRIT_CTGRY: response.prevScourCriticalCat,
      STREAM_BED_MATERIAL: getIN15ValueFromMaterials(response.prevStreamBedMaterials),
      CONDITION: response.prevCondition,
      // 7A06 "Performed" table
      NBINSPDONE: response.prevNbInspectionDone,
      FCINSPDONE: response.prevFcInspectionDone,
      UWINSPDONE: response.prevUwInspectionDone,
      OSINSPDONE: response.prevOsInspectionDone,
      ELINSPDONE: response.prevElInspectionDone,
      // 7A07 "Required" table
      FCINSPREQ: response.prevFcInspectionReq,
      UWINSPREQ: response.prevUwInspectionReq,
      OSINSPREQ: response.prevOsInspectionReq,
      ELINSPREQ: response.prevElInspectionReq,
      // 7A09 "Interval" table
      BRINSPFREQ: response.prevBrInspectionFreq,
      FCINSPFREQ: response.prevFcInspectionFreq,
      UWINSPFREQ: response.prevUwInspectionFreq,
      OSINSPFREQ: response.prevOsInspectionFreq,
      ELINSPFREQ: response.prevElInspectionFreq,
    };
  };

  const convertToInspModuleObject = (structure) => {
    const prevAcceptedInsp = structure.keyFields;
    pageData.value.currentInsp = {
      INSPDATE: structure.InspEvnt.INSPDATE,
      INSPTYPE: structure.InspEvnt.INSPTYPE,
      DKRATING: structure.InspEvnt.DKRATING,
      SUPRATING: structure.InspEvnt.SUPRATING,
      SUBRATING: structure.InspEvnt.SUBRATING,
      CULVRATING: structure.InspEvnt.CULVRATING,
      RAILRATING: structure.InspEvnt.RAILRATING,
      TRANSRATIN: structure.InspEvnt.TRANSRATIN,
      BEARING_OVERALL_COND_RATE: structure.T_Bearings?.[0]?.BEARING_OVERALL_COND_RATE,
      JOINTS_OVERALL_COND_RATE: structure.T_Joints?.[0]?.OVERALL_COND_RATING,
      CHANRATING: structure.InspEvnt.CHANRATING,
      CHAN_PROT_RATING: structure.UserInsp.CHAN_PROT_RATING,
      OVERALL_SCOUR_HOLES: inspectionStore.getOverallScourHoles(structure),
      UNDERWATER_RATING: structure.UserInsp.UNDERWATER_RATING,
      NSTM_CONDITION: structure.UserInsp.NSTM_CONDITION,
      SCOURCRIT: structure.InspEvnt.SCOURCRIT,
      SCOUR_CRIT_CTGRY: structure.InspEvnt.SCOUR_CRIT_CTGRY,
      STREAM_BED_MATERIAL: getIN15Value(structure.Structure_Unit, structure.T_Underwater_Insp),
      STREAM_BED_MATERIAL_NON_CONTROLLING_CHANGE: areThereNonControllingIN15Changes(
        structure.Structure_Unit,
        structure.T_Underwater_Insp,
        prevAcceptedInsp?.value?.streamBedMaterial
      ),
      CONDITION: structure.InspEvnt.CONDITION,
      // 7A06 "Performed" table
      NBINSPDONE: structure.InspEvnt.NBINSPDONE,
      FCINSPDONE: structure.InspEvnt.FCINSPDONE,
      UWINSPDONE: structure.InspEvnt.UWINSPDONE,
      OSINSPDONE: structure.InspEvnt.OSINSPDONE,
      ELINSPDONE: structure.InspEvnt.ELINSPDONE,
      // 7A07 "Required" table
      FCINSPREQ: structure.InspEvnt.FCINSPREQ,
      UWINSPREQ: structure.InspEvnt.UWINSPREQ,
      OSINSPREQ: structure.InspEvnt.OSINSPREQ,
      ELINSPREQ: structure.InspEvnt.ELINSPREQ,
      // 7A09 "Interval" table
      BRINSPFREQ: structure.InspEvnt.BRINSPFREQ,
      FCINSPFREQ: structure.InspEvnt.FCINSPFREQ,
      UWINSPFREQ: structure.InspEvnt.UWINSPFREQ,
      OSINSPFREQ: structure.InspEvnt.OSINSPFREQ,
      ELINSPFREQ: structure.InspEvnt.ELINSPFREQ,
    };
    pageData.value.previousInsp = {
      INSPDATE: prevAcceptedInsp?.inspectionDate,
      INSPTYPE: prevAcceptedInsp?.inspectionType,
      DKRATING: prevAcceptedInsp?.dkRating,
      SUPRATING: prevAcceptedInsp?.supRating,
      SUBRATING: prevAcceptedInsp?.subRating,
      CULVRATING: prevAcceptedInsp?.culvRating,
      RAILRATING: prevAcceptedInsp?.railRating,
      TRANSRATIN: prevAcceptedInsp?.transRating,
      BEARING_OVERALL_COND_RATE: prevAcceptedInsp?.bearingsOverallCondRating,
      JOINTS_OVERALL_COND_RATE: prevAcceptedInsp?.jointsOverallCondRating,
      CHANRATING: prevAcceptedInsp?.chanRating,
      CHAN_PROT_RATING: prevAcceptedInsp?.chanProtRating,
      OVERALL_SCOUR_HOLES: inspectionStore.getPrevOverallScourHoles(prevAcceptedInsp?.value),
      UNDERWATER_RATING: prevAcceptedInsp?.underwaterRating,
      NSTM_CONDITION: prevAcceptedInsp?.nstmCondition,
      SCOURCRIT: prevAcceptedInsp?.scourCritical,
      SCOUR_CRIT_CTGRY: prevAcceptedInsp?.scourCriticalCat,
      STREAM_BED_MATERIAL: getIN15ValueFromMaterials(prevAcceptedInsp?.streamBedMaterial),
      CONDITION: prevAcceptedInsp?.condition,
      // 7A06 "Performed" table
      NBINSPDONE: prevAcceptedInsp?.nbInspectionDone,
      FCINSPDONE: prevAcceptedInsp?.fcInspectionDone,
      UWINSPDONE: prevAcceptedInsp?.uwInspectionDone,
      OSINSPDONE: prevAcceptedInsp?.osInspectionDone,
      ELINSPDONE: prevAcceptedInsp?.elInspectionDone,
      // 7A07 "Required" table
      FCINSPREQ: prevAcceptedInsp?.fcInspectionReq,
      UWINSPREQ: prevAcceptedInsp?.uwInspectionReq,
      OSINSPREQ: prevAcceptedInsp?.osInspectionReq,
      ELINSPREQ: prevAcceptedInsp?.elInspectionReq,
      // 7A09 "Interval" table
      BRINSPFREQ: prevAcceptedInsp?.brInspectionFreq,
      FCINSPFREQ: prevAcceptedInsp?.fcInspectionFreq,
      UWINSPFREQ: prevAcceptedInsp?.uwInspectionFreq,
      OSINSPFREQ: prevAcceptedInsp?.osInspectionFreq,
      ELINSPFREQ: prevAcceptedInsp?.elInspectionFreq,
    };
  };
  return {
    loadDataObject,
    getKeyfieldsObject,
  };
});

<template>
  <ExpandCollapseToggle
    id="loadRatingsPanelToggle"
    @toggleAllPanels="toggleAllPanels"
    styleClass="mt-3"
    :panel="panel"
  />
  <div v-if="!isTunnel">
    <v-form ref="loadRatingsForm" v-model="valid">
      <v-row :class="$vuetify?.display?.mdAndDown ? 'mt-1' : 'mt-3'">
        <v-col xl="5" lg="12" md="12" sm="12" cols="12" class="d-sm-block d-md-flex py-0">
          <LabelCheckbox
            labelXl="5"
            labelLg="9"
            labelMd="9"
            labelSm="10"
            id="recommendedReview"
            class="noGridCheckbox handleFlex pr-2 pb-0"
            label="IR01a Load Rating Review Recommended"
            :isEditable="isEditable"
            noGrid
            v-model="loadRatingValue.RECOMMEND_RATING_REVIEW"
            @update:modelValue="checkChangeReviewerAction()"
          ></LabelCheckbox>
          <v-btn id="btn_considerations" variant="outlined" @click="showConsiderationsDialog = true"
            >Considerations</v-btn
          >
        </v-col>
        <v-col xl="4" lg="12" md="12" sm="12" cols="12" class="py-0">
          <LabelSelect
            class="reviewActionField"
            noGrid
            id="reviewerAction"
            label="IR01b Reviewer Action"
            :isEditable="isEditable && isManagement"
            :refTable="REFERENCE_TABLE.REVIEWER_ACTION"
            v-model="loadRatingValue.LR_REVIEW_ACTION"
          ></LabelSelect>
        </v-col>
        <v-col xl="3" lg="12" md="12" sm="12" class="py-0">
          <LabelCheckbox
            class="noGridCheckbox handleFlex pr-2 pb-0"
            noGrid
            id="postingReviewRecommended"
            label="VP11 Posting Review Recommended"
            v-model="loadRatingValue.POST_REVIEW_REC"
            @update:model-value="loadRatingsForm.validate()"
            :isEditable="isEditable"
          ></LabelCheckbox>
        </v-col>
      </v-row>
      <v-row v-if="isManagement">
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <ManagementCommentTypeTextField
            label="Posting Review Recommended Summary"
            id="field_Posting_Review_Recommended_Summary_Management"
            :inspectionCommentType="INSPECTION_COMMENTS.LOAD_RATING_POSTING_RECOMMENDED_SUMMARY"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="loadRatingValue.inspectionComments"
            :brkey="loadRatingValue.BRKEY"
            :inspectionId="loadRatingValue.INSPECTIONID"
            @updateComment="
              (comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.LOAD_RATING_POSTING_RECOMMENDED_SUMMARY)
            "
            :isRequired="loadRatingValue.POST_REVIEW_REC === '1'"
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <ManagementCommentTypeTextField
            label="Previous Posting Review Recommended Summary"
            id="field_Previous_Posting_Review_Recommended_Summary_Management"
            :inspectionCommentType="INSPECTION_COMMENTS.LOAD_RATING_POSTING_RECOMMENDED_SUMMARY"
            readonly
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="loadRatingValue.previousInspectionComments"
            :brkey="loadRatingValue.BRKEY"
            :inspectionId="loadRatingValue.INSPECTIONID"
            previousData
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <ManagementCommentTypeTextField
            label="Inspection Team Notes"
            id="field_Inspection_Team_Notes_Management"
            :inspectionCommentType="INSPECTION_COMMENTS.REVIEW_RECOMMENDED_DUE_TO_COMMENTS"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="loadRatingValue.inspectionComments"
            :brkey="loadRatingValue.BRKEY"
            :inspectionId="loadRatingValue.INSPECTIONID"
            secondCharacterCount
            @updateComment="
              (comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.REVIEW_RECOMMENDED_DUE_TO_COMMENTS)
            "
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <ManagementCommentTypeTextField
            label="Previous Inspection Team Notes"
            id="field_Previous_Inspection_Team_Notes_Management"
            :inspectionCommentType="INSPECTION_COMMENTS.REVIEW_RECOMMENDED_DUE_TO_COMMENTS"
            readonly
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="loadRatingValue.previousInspectionComments"
            :brkey="loadRatingValue.BRKEY"
            :inspectionId="loadRatingValue.INSPECTIONID"
            previousData
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <ManagementCommentTypeTextField
            label="Load Rating Engineer Notes"
            id="field_Load_Rating_Engineer_Notes_Management"
            :inspectionCommentType="INSPECTION_COMMENTS.LOAD_RATING_REVIEW"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="loadRatingValue.inspectionComments"
            :brkey="loadRatingValue.BRKEY"
            :inspectionId="loadRatingValue.INSPECTIONID"
            @updateComment="(comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.LOAD_RATING_REVIEW)"
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <ManagementCommentTypeTextField
            label="Previous Load Rating Engineer Notes"
            id="field_Previous_Load_Rating_Engineer_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.LOAD_RATING_REVIEW"
            readonly
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="loadRatingValue.previousInspectionComments"
            :brkey="loadRatingValue.BRKEY"
            :inspectionId="loadRatingValue.INSPECTIONID"
            previousData
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <CommentTypeTextField
            class="details-text"
            label="Posting Review Recommended Summary"
            id="field_Posting_Recommended_Summary_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.LOAD_RATING_POSTING_RECOMMENDED_SUMMARY"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :readonly="!isEditable"
            :isRequired="loadRatingValue.POST_REVIEW_REC === '1'"
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <CommentTypeTextField
            class="details-text"
            label="Previous Posting Review Recommended Summary"
            id="field_Previous_Posting_Recommended_Summary_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.LOAD_RATING_POSTING_RECOMMENDED_SUMMARY"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            readonly
            previousData
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <CommentTypeTextField
            class="details-text"
            label="Inspection Team Notes"
            id="field_Inspection_Team_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.REVIEW_RECOMMENDED_DUE_TO_COMMENTS"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            secondCharacterCount
            :isRequired="!isEditable"
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <CommentTypeTextField
            label="Previous Inspection Team Notes"
            id="field_Previous_Inspection_Team_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.REVIEW_RECOMMENDED_DUE_TO_COMMENTS"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            readonly
            previousData
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <CommentTypeTextField
            class="details-text"
            label="Load Rating Engineer Notes"
            id="field_Load_Rating_Engineer_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.LOAD_RATING_REVIEW"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :readonly="!isEditable"
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <CommentTypeTextField
            label="Previous Load Rating Engineer Notes"
            id="field_Previous_Load_Rating_Engineer_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.LOAD_RATING_REVIEW"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            readonly
            previousData
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="6" md="12" sm="12">
          <LabelSelect
            v-if="isManagement"
            noGrid
            id="calculationDates"
            label="IR03 Calculation Date"
            v-model="calculationDate"
            :options="calculationDates"
            :isEditable="isEditable && isManagement && assignRatingSetClicked"
            @update:modelValue="switchRatingSet()" />
          <LabelDatefield v-else noGrid label="IR03 Calculation Date" id="calculationDate" v-model="calculationDate"
        /></v-col>
        <v-col lg="6" md="12" sm="12"
          ><LabelDatefield
            noGrid
            label="IR02 Assigned Rating Approval Date"
            id="approvalDate"
            v-model="loadRatingValue.RATING_APPR_DATE"
        /></v-col>
      </v-row>
      <v-row>
        <v-col sm="9">
          <LabelText
            type="text"
            noGrid
            label="IR02a Assigned Rating Approval Engineer"
            id="approvalEngineer"
            counter="45"
            maxlength="45"
            v-model="loadRatingValue.APPROVER_NAME"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
  <v-row>
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel v-if="!isTunnel" class="mt-5" :value="0">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Load Ratings Details</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <v-row>
            <v-col class="px-0">
              <v-btn
                :disabled="!isEditable || addingOrEditing"
                id="btn_add_Posting"
                variant="outlined"
                class="mr-1"
                @click="addRating"
                v-if="isManagement"
                >Add New Rating Set</v-btn
              >
              <v-btn
                :disabled="!isEditable || addingOrEditing || assignRatingSetClicked"
                class="ml-1"
                id="btn_assign_Rating_Set"
                variant="outlined"
                @click="assignRatingSetClicked = true"
                v-if="isManagement"
                >Assign Rating Set</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12">
              <v-data-table
                v-model:expanded="expandedRows"
                :headers="isManagement ? detailsHeadersEditable : detailsHeaders"
                :items="loadRatingDetails"
                class="lessPaddingTableColumns"
                v-model:sort-by="sortBy"
                hide-default-footer
                must-sort
                show-expand
                return-object
              >
                <template v-slot:[`header.data-table-expand`]="{ column }">
                  {{ column.title }}
                </template>
                <template v-slot:[`header.LOAD_TYPE`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.NBI_RATING_IND`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.RATING_ANALYSIS_METHOD`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.IRLOAD`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.ORLOAD`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.SLC_RATING`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.IR_LOAD_FACTOR`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.OR_LOAD_FACTOR`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.SUPPORT_DATASET`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.action`]="{ column }" v-if="isManagement">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>

                <template v-slot:item="{ item }">
                  <tr :class="rowClassDetails(item)">
                    <td class="text-center">
                      <button v-if="!expandedRows.includes(item)" @click="toggleRowDetails(item)">
                        <v-icon :id="`icon_Chevron_Right_${item.UNIQUE_KEY}`" icon="fa-solid fa-chevron-right"></v-icon>
                      </button>

                      <button v-else-if="expandedRows.includes(item)" @click="toggleRowDetails(item)">
                        <v-icon :id="`icon_Chevron_Down_${item.UNIQUE_KEY}`" icon="fa-solid fa-chevron-down"></v-icon>
                      </button>
                    </td>
                    <td class="text-center">
                      {{
                        item?.LOAD_TYPE ? configStore.getReferenceValue(REFERENCE_TABLE.LOAD_TYPE, item?.LOAD_TYPE) : ""
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        item?.NBI_RATING_IND
                          ? configStore.getReferenceValue(REFERENCE_TABLE.NBI_RATING, item?.NBI_RATING_IND)
                          : ""
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        item?.RATING_ANALYSIS_METHOD
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.LOAD_RATING_METHOD,
                              item?.RATING_ANALYSIS_METHOD
                            )
                          : ""
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          item.IRLOAD,
                          ROUNDING_FACTOR.ROUND
                        )
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          item.ORLOAD,
                          ROUNDING_FACTOR.ROUND
                        )
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          item.SLC_RATING,
                          ROUNDING_FACTOR.ROUND,
                          null,
                          [METRIC_CONVERSION_TYPES.W1]
                        )
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        parseFloat(item.IR_LOAD_FACTOR)
                          ? parseFloat(item.IR_LOAD_FACTOR).toFixed(2)
                          : item.IR_LOAD_FACTOR
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        parseFloat(item.OR_LOAD_FACTOR)
                          ? parseFloat(item.OR_LOAD_FACTOR).toFixed(2)
                          : item.OR_LOAD_FACTOR
                      }}
                    </td>
                    <td class="text-center">
                      {{ item.SUPPORT_DATASET }}
                    </td>
                    <td class="alignCenter" v-if="isManagement">
                      <div style="white-space: nowrap" v-if="!item.readOnly && addingOrEditing && isEditable">
                        <IconTooltip
                          :id="`icon_add_${item.POSTING_KEY}`"
                          icon="fa-solid fa-check"
                          :tooltip="item?.isAdd ? 'Add' : 'Update'"
                          @click="saveRating(item)"
                        />
                        <IconTooltip
                          :id="`icon_close_${item.POSTING_KEY}`"
                          icon="fa-solid fa-xmark"
                          tooltip="Close"
                          @click="closeRating"
                        />
                      </div>
                      <div
                        style="white-space: nowrap"
                        v-else-if="isEditable && expandedRows?.length == 0 && !addingOrEditing"
                      >
                        <IconTooltip
                          :id="`icon_edit_${item.UNIQUE_KEY}`"
                          icon="fa-solid fa-pencil"
                          tooltip="Edit"
                          @click="editRating(item)"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-slot:expanded-row="{ item }">
                  <td :colspan="isManagement ? detailsHeadersEditable.length : detailsHeaders.length">
                    <v-form
                      ref="loadRatingsDetailsForm"
                      v-model="validLoadRatingsDetails"
                      class="mt-2"
                      validate-on="blur"
                    >
                      <v-row class="mt-1">
                        <v-col
                          xl="6"
                          lg="6"
                          md="12"
                          sm="12"
                          cols="12"
                          :class="$vuetify?.display?.mdAndDown ? '' : 'pb-0'"
                        >
                          <LabelDatefield
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            textXl="7"
                            textLg="6"
                            textMd="6"
                            textSm="6"
                            id="calcDate"
                            label="IR03 Calculation Date"
                            v-model="item.RATING_DATE"
                            @update:model-value="changesDetectedRating = true"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          xl="6"
                          lg="6"
                          md="12"
                          sm="12"
                          cols="12"
                          :class="$vuetify?.display?.mdAndDown ? '' : 'pt-0 pb-0'"
                        >
                          <LabelSelect
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            selectXl="7"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="loadType"
                            label="IR04 Load Type"
                            v-model="item.LOAD_TYPE"
                            :refTable="REFERENCE_TABLE.LOAD_TYPE"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            :isRequired="isEditable && isManagement && !item.readOnly"
                            @update:model-value="updateLegalLoadRatingFactor(item)"
                          />
                          <LabelSelect
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            selectXl="7"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="nbiRating"
                            label="IR05 NBI"
                            v-model="item.NBI_RATING_IND"
                            :refTable="REFERENCE_TABLE.NBI_RATING"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            :isRequired="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                          <LabelSelect
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            selectXl="7"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="ratingMethod"
                            label="IR06 Load Rating Method"
                            v-model="item.RATING_ANALYSIS_METHOD"
                            :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            :isRequired="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                          <LabelSelect
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            selectXl="7"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="memberType"
                            label="IR07 Ctrl Member Type"
                            v-model="item.CONTROL_MEM_TYPE"
                            :refTable="REFERENCE_TABLE.CONTROLLING_MEMBER_TYPE"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                          <LabelSelect
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            selectXl="7"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="stressCategoryType"
                            label="IR08 Fatigue Stress Category"
                            v-model="item.FATIG_STRESS_CAT"
                            :refTable="REFERENCE_TABLE.CONTROLLING_MEMBER_FATIGUE_STRESS_CATEGORY"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                          <LabelSelect
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            selectXl="7"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="fatigueLoadType"
                            label="IR09 Fatigue Load Type"
                            v-model="item.FATIG_LOAD_TYPE"
                            :refTable="REFERENCE_TABLE.FATIGUE_LOAD_TYPE"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                          <LabelText
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            id="manYr"
                            :counter="false"
                            :maxlength="4"
                            label="IR14 AASHTO Man Yr"
                            v-model="item.AASHTO_MANUAL_YEAR"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                          <LabelText
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            :counter="false"
                            :maxlength="4"
                            id="specYr"
                            label="IR15 AASHTO Spec Yr"
                            v-model="item.AASHTO_SPEC_YEAR"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                          <LabelText
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            textXl="7"
                            textLg="6"
                            textMd="6"
                            textSm="6"
                            type="text"
                            label="IR16 Engineer"
                            id="engineer"
                            v-model="item.ANALYSIS_ENGINEER"
                            counter="30"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                        </v-col>
                        <v-col
                          xl="6"
                          lg="6"
                          md="12"
                          sm="12"
                          cols="12"
                          :class="$vuetify?.display?.mdAndDown ? '' : 'pt-0'"
                        >
                          <div v-if="isInventoryAndOperatingRatingEditable(item)">
                            <LabelText
                              labelXl="5"
                              labelLg="6"
                              labelMd="6"
                              labelSm="6"
                              textXl="7"
                              textLg="6"
                              textMd="6"
                              textSm="6"
                              id="invRating"
                              label="IR10 Inventory Rating"
                              v-model="item.IRLOAD"
                              appendWith=" Tons"
                              :pairCode="PAIR_CODE.METRICTON_TO_TON"
                              :roundingFactor="ROUNDING_FACTOR.ROUND"
                              type="number"
                              :isEditable="isEditable && isManagement && !item.readOnly"
                              :isRequired="isEditable && isManagement && !item.readOnly"
                              @update:model-value="changesDetectedRating = true"
                            />
                            <LabelText
                              labelXl="5"
                              labelLg="6"
                              labelMd="6"
                              labelSm="6"
                              textXl="7"
                              textLg="6"
                              textMd="6"
                              textSm="6"
                              id="invRating"
                              label="IR11 Operating Rating"
                              v-model="item.ORLOAD"
                              appendWith=" Tons"
                              :pairCode="PAIR_CODE.METRICTON_TO_TON"
                              :roundingFactor="ROUNDING_FACTOR.ROUND"
                              type="number"
                              :isEditable="isEditable && isManagement && !item.readOnly"
                              :isRequired="isEditable && isManagement && !item.readOnly"
                              @update:model-value="changesDetectedRating = true"
                            />
                          </div>
                          <div v-else>
                            <LabelText
                              labelXl="5"
                              labelLg="6"
                              labelMd="6"
                              labelSm="6"
                              textXl="7"
                              textLg="6"
                              textMd="6"
                              textSm="6"
                              id="invRating"
                              label="IR10 Inventory Rating"
                              :value="getInventoryRating(item)"
                              appendWith=" Tons"
                              :pairCode="PAIR_CODE.METRICTON_TO_TON"
                              :roundingFactor="ROUNDING_FACTOR.ROUND"
                              type="number"
                            />

                            <LabelText
                              labelXl="5"
                              labelLg="6"
                              labelMd="6"
                              labelSm="6"
                              textXl="7"
                              textLg="6"
                              textMd="6"
                              textSm="6"
                              id="invRating"
                              label="IR11 Operating Rating"
                              :value="getOperatorRating(item)"
                              appendWith=" Tons"
                              :pairCode="PAIR_CODE.METRICTON_TO_TON"
                              :roundingFactor="ROUNDING_FACTOR.ROUND"
                              type="number"
                            />
                          </div>
                          <LabelText
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            textXl="7"
                            textLg="6"
                            textMd="6"
                            textSm="6"
                            id="slcRating"
                            label="IR11a SLC Rating"
                            v-model="item.SLC_RATING"
                            appendWith=" Tons"
                            :pairCode="PAIR_CODE.METRICTON_TO_TON"
                            :roundingFactor="ROUNDING_FACTOR.ROUND"
                            type="number"
                            :conversionTypes="[METRIC_CONVERSION_TYPES.W1]"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />

                          <LabelText
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            textXl="7"
                            textLg="6"
                            textMd="6"
                            textSm="6"
                            id="irRatingFactor"
                            label="IR20 IR Rating Factor"
                            v-model="item.IR_LOAD_FACTOR"
                            decimalPlaces="2"
                            type="number"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />

                          <LabelText
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            textXl="7"
                            textLg="6"
                            textMd="6"
                            textSm="6"
                            id="orRatingFactor"
                            label="IR21 OR Rating Factor"
                            v-model="item.OR_LOAD_FACTOR"
                            decimalPlaces="2"
                            type="number"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                          <LabelSelect
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            selectXl="7"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="critInventory"
                            label="IR12 Govern Crit Inventory"
                            v-model="item.INV_RATING_STRESS_METHOD"
                            :refTable="REFERENCE_TABLE.GOVERNING_CRITERIA"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />

                          <LabelSelect
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            selectXl="7"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="critOperating"
                            label="IR13 Govern Crit Operating"
                            :refTable="REFERENCE_TABLE.GOVERNING_CRITERIA"
                            v-model="item.OPR_RATING_STRESS_METHOD"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          ></LabelSelect>

                          <LabelText
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            textXl="7"
                            textLg="6"
                            textMd="6"
                            textSm="6"
                            id="stressRange"
                            label="IR18 Stress Range"
                            :pairCode="PAIR_CODE.MPA_TO_KSI"
                            :roundingFactor="ROUNDING_FACTOR.TENTH"
                            decimalPlaces="1"
                            v-model="item.STRESS_RANGE"
                            appendWith=" ksi"
                            type="number"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                          <LabelText
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            textXl="7"
                            textLg="6"
                            textMd="6"
                            textSm="6"
                            id="legalLoadRatingFactor"
                            label="IR22 Legal Load Rating Factor"
                            :roundingFactor="ROUNDING_FACTOR.HUNDRETH"
                            decimalPlaces="2"
                            v-model="item.LEGAL_LOAD_RF"
                            type="number"
                          />
                          <LabelSelect
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            selectXl="7"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="vehiclePostingType"
                            label="B.EP.03 Vehicle Posting Type"
                            :refTable="REFERENCE_TABLE.VEHICLE_POSTING_TYPE"
                            v-model="item.VEH_POST_TYPE"
                          ></LabelSelect>
                          <LabelText
                            labelXl="5"
                            labelLg="6"
                            labelMd="6"
                            labelSm="6"
                            textXl="7"
                            textLg="6"
                            textMd="6"
                            textSm="6"
                            id="vehiclePostingValue"
                            label="B.EP.04 Vehicle Posting Value"
                            appendWith=" Tons"
                            :pairCode="PAIR_CODE.METRICTON_TO_TON"
                            :roundingFactor="ROUNDING_FACTOR.ROUND"
                            type="number"
                            v-model="item.VEH_POST_WEIGHT"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="12" class="pb-0">
                          <LabelText
                            style="padding-left: 4px !important; padding-bottom: 0px !important"
                            labelXl="12"
                            labelLg="12"
                            labelMd="12"
                            labelSm="12"
                            textXl="12"
                            textLg="12"
                            textMd="12"
                            textSm="12"
                            type="textarea"
                            label="IR17 Rating Dataset"
                            id="ratingDataset"
                            v-model="item.SUPPORT_DATASET"
                            counter="100"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="12" class="pt-0 pb-0">
                          <LabelText
                            style="
                              padding-left: 4px !important;
                              padding-top: 0px !important;
                              padding-bottom: 4px !important;
                            "
                            labelXl="12"
                            labelLg="12"
                            labelMd="12"
                            labelSm="12"
                            textXl="12"
                            textLg="12"
                            textMd="12"
                            textSm="12"
                            type="textarea"
                            label="IR19 Notes"
                            id="notes"
                            v-model="item.NOTES"
                            :counter="notesMaxLength"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedRating = true"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </td> </template
                ><template v-slot:bottom> </template></v-data-table></v-col
          ></v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel v-if="!isTunnel" :value="1"
        ><v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />
          <h3>NBI & Alternate Ratings</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-label class="bold pt-2">NBI:</v-label>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6">
              <LabelSelect
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="6"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                id="designLoad"
                label="B.LR.01 Design Load"
                :isEditable="isEditable && isManagement"
                :refTable="REFERENCE_TABLE.DESIGN_LOAD"
                v-model="loadRatingValue.DESIGNLOAD"
              ></LabelSelect>
            </v-col>
            <v-col cols="12" lg="6" xl="6">
              <LabelDatefield
                class="pt-1"
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                label="4B02/B.LR.03/X Rating Date"
                id="ratingDate"
                v-model="loadRatingValue.nbiAndAlternateRating.inspectionDate"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6">
              <LabelText
                v-if="
                  isRF(
                    loadRatingValue.nbiAndAlternateRating.nbi1RatingAnalysisMethod,
                    loadRatingValue.nbiAndAlternateRating.nbi1LoadType,
                    true
                  )
                "
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="inventoryRating"
                label="4B07/B.LR.05 Inventory Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.nbi1IRLoadFactor"
                decimalPlaces="2"
                type="number"
              />
              <LabelText
                v-else
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="inventoryRating"
                label="4B07/B.LR.05 Inventory Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.nbi1IRLoad"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6">
              <LabelText
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="inventoryRating"
                label="4B04/B.LR.04 Operating Rating Method"
                v-model="loadRatingValue.nbiAndAlternateRating.nbi1OperatingRatingMethod"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="
                  isRF(
                    loadRatingValue.nbiAndAlternateRating.nbi1RatingAnalysisMethod,
                    loadRatingValue.nbiAndAlternateRating.nbi1LoadType,
                    true
                  )
                "
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="operatingRating"
                label="4B05/B.LR.06 Operating Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.nbi1ORLoadFactor"
                decimalPlaces="2"
                type="number"
              />
              <LabelText
                v-else
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="operatingRating"
                label="4B05/B.LR.06 Operating Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.nbi1ORLoad"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="6"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="B.LR.02 Design Method"
                id="designMethod"
                :refTable="REFERENCE_TABLE.DESIGN_METHOD"
                v-model="loadRatingValue.DESIGN_METHOD"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6">
              <LabelText
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="controllingLegalLoadRatingFactor"
                label="B.LR.07 Controlling Legal Load Rating Factor"
                :roundingFactor="ROUNDING_FACTOR.HUNDRETH"
                decimalPlaces="2"
                v-model="loadRatingValue.nbiAndAlternateRating.controllingLegalLoadRatingFactor"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="6"
                labelLg="7"
                labelMd="7"
                labelSm="7"
                label="B.LR.08 Routine Permit Loads"
                id="routinePermitLoads"
                selectXl="6"
                selectLg="5"
                selectMd="5"
                selectSm="5"
                :refTable="REFERENCE_TABLE.ROUTINE_PERMIT_LOADS"
                v-model="loadRatingValue.ROUTINE_PERMIT_LOADS"
                :isEditable="isEditable && isManagement"
              />
            </v-col>
          </v-row>
          <v-label class="bold pt-2">Alternate:</v-label>
          <v-row class="pl-6 pt-1">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="
                  isRF(
                    loadRatingValue.nbiAndAlternateRating.h20RatingAnalysisMethod,
                    loadRatingValue.nbiAndAlternateRating.h20LoadType,
                    true
                  )
                "
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="inventoryRating"
                label="4B11 H20 Inventory Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.h20IRLoadFactor"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="inventoryRating"
                label="4B11 H20 Inventory Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.h20IRLoad"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="
                  isRF(
                    loadRatingValue.nbiAndAlternateRating.h20RatingAnalysisMethod,
                    loadRatingValue.nbiAndAlternateRating.h20LoadType,
                    true
                  )
                "
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="operatingRating"
                label="4B09 H20 Operating Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.h20ORLoadFactor"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="operatingRating"
                label="4B09 H20 Operating Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.h20ORLoad"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="6"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="4B08 H20 Operating Type"
                id="operatingType"
                :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                v-model="loadRatingValue.nbiAndAlternateRating.h20RatingAnalysisMethod"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="
                  isRF(
                    loadRatingValue.nbiAndAlternateRating.ml80RatingAnalysisMethod,
                    loadRatingValue.nbiAndAlternateRating.ml80LoadType,
                    true
                  )
                "
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="ml80InventoryRating"
                label="4B12 ML80 Inventory Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.ml80IRLoadFactor"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="ml80InventoryRating"
                label="4B12 ML80 Inventory Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.ml80IRLoad"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="
                  isRF(
                    loadRatingValue.nbiAndAlternateRating.ml801RatingAnalysisMethod,
                    loadRatingValue.nbiAndAlternateRating.ml80LoadType,
                    true
                  )
                "
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="ml80OperatingRating"
                label="4B12a ML80 Operating Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.ml80ORLoadFactor"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="ml80OperatingRating"
                label="4B12a ML80 Operating Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.ml80ORLoad"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="6"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="4B12c ML80 Operating Type"
                id="ml80OperatingType"
                :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                v-model="loadRatingValue.nbiAndAlternateRating.ml80RatingAnalysisMethod"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="
                  isRF(
                    loadRatingValue.nbiAndAlternateRating.tk527RatingAnalysisMethod,
                    loadRatingValue.nbiAndAlternateRating.ml80LoadType,
                    true
                  )
                "
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="tk527InventoryRating"
                label="4B13 TK527 Inventory Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.tk527IRLoadFactor"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="tk527InventoryRating"
                label="4B13 TK527 Inventory Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.tk527IRLoad"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="
                  isRF(
                    loadRatingValue.nbiAndAlternateRating.tk527RatingAnalysisMethod,
                    loadRatingValue.nbiAndAlternateRating.tk527LoadType,
                    true
                  )
                "
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="tk527OperatingRating"
                label="4B13a TK527 Operating Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.tk527ORLoadFactor"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="tk527OperatingRating"
                label="4B13a TK527 Operating Rating"
                v-model="loadRatingValue.nbiAndAlternateRating.tk527ORLoad"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="6"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="4B13c TK527 Operating Type"
                id="tk527OperatingType"
                :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                v-model="loadRatingValue.nbiAndAlternateRating.tk527RatingAnalysisMethod"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
        :class="$vuetify?.display?.mdAndDown ? 'mt-1' : 'mt-3'"
        v-if="isTunnel && loadRatingValue.T_Tunnel_Load_Rating"
        :value="2"
      >
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(2)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(2)" icon="fa-solid fa-minus" />
          <h3>Load Ratings Details</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <v-col
              ><LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="L.1 Rating Method"
                id="lRatingMethod"
                :refTable="REFERENCE_TABLE.TUNNEL_LOAD_RATING_METHOD"
                v-model="loadRatingValue.T_Tunnel_Load_Rating.RATING_METHOD"
              />
            </v-col>
            <v-col
              ><LabelText
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                textXl="6"
                textLg="4"
                textMd="4"
                textSm="4"
                label="L.7 Posting Load - Type 3"
                id="type3PostLoad"
                v-model="loadRatingValue.T_Tunnel_Load_Rating.TYPE3_POST_LOAD"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><LabelText
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                label="L.2 Inventory Rating Factor"
                id="invLRFactor"
                v-model="loadRatingValue.T_Tunnel_Load_Rating.INV_LR_FACTOR"
              />
            </v-col>
            <v-col
              ><LabelText
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                textXl="6"
                textLg="4"
                textMd="4"
                textSm="4"
                label="L.8 Posting Load - Type 3S2"
                id="type33s2PostLoad"
                v-model="loadRatingValue.T_Tunnel_Load_Rating.TYPE3S2_POST_LOAD"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><LabelText
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                label="L.3 Operating Rating Factor"
                id="oplrFactor"
                v-model="loadRatingValue.T_Tunnel_Load_Rating.OP_LR_FACTOR"
              />
            </v-col>
            <v-col
              ><LabelText
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                textXl="6"
                textLg="4"
                textMd="4"
                textSm="4"
                label="L.9 Posting Load - Type 3-3"
                id="type33PostLoad"
                v-model="loadRatingValue.T_Tunnel_Load_Rating.TYPE3_3_POST_LOAD"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="L.4 Load Posting Status"
                id="postStatus"
                :refTable="REFERENCE_TABLE.POSTING_STATUS"
                :value="postingHistory[postingHistory.length - 1]?.POST_STATUS"
              />
            </v-col>
            <v-col
              ><LabelSelect
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="6"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="L.10 Height Restriction"
                :isEditable="isEditable && isManagement"
                id="heightRestriction"
                :refTable="REFERENCE_TABLE.YES_NO"
                v-model="loadRatingValue.T_Tunnel_Load_Rating.HEIGHT_RESTR"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><LabelText
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                label="L.5 Posting Load - Gross"
                id="loadGross"
                v-model="loadRatingValue.T_Tunnel_Load_Rating.GROSS_POST_LOAD"
              />
            </v-col>
            <v-col
              ><LabelSelect
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="6"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="L.11 Hazardous Material Restriction"
                :isEditable="isEditable && isManagement"
                id="heightRestriction"
                :refTable="REFERENCE_TABLE.YES_NO"
                v-model="loadRatingValue.T_Tunnel_Load_Rating.HAZMAT_RESTR"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><LabelText
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                label="L.6 Posting Load - Axle"
                id="loadAxle"
                v-model="loadRatingValue.T_Tunnel_Load_Rating.AXLE_POST_LOAD"
              />
            </v-col>
            <v-col
              ><LabelSelect
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="6"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="L.12 Other Restrictions"
                id="otherRestriction"
                :isEditable="isEditable && isManagement"
                :refTable="REFERENCE_TABLE.YES_NO"
                v-model="loadRatingValue.T_Tunnel_Load_Rating.OTHER_RESTR"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel v-if="!isTunnel" :value="3">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(3)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(3)" icon="fa-solid fa-minus" />
          <h3>Posting History</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <v-row>
            <v-col class="px-0">
              <v-btn
                :disabled="!isEditable || addingOrEditing"
                id="btn_add_Posting"
                variant="outlined"
                @click="addPosting"
                v-if="isManagement"
                >Add Posting</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12">
              <v-data-table
                v-model:expanded="expandedRowsPosting"
                :headers="isManagement ? headersPostingEditable : headersPosting"
                :items="postingHistory"
                class="lessPaddingTableColumns"
                v-model:sort-by="sortByPosting"
                hide-default-footer
                must-sort
                show-expand
                return-object
              >
                <template v-slot:[`header.data-table-expand`]="{ column }">
                  {{ column.title }}
                </template>
                <template v-slot:[`header.POST_STATUS_DATE`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>
                <template v-slot:[`header.POST_STATUS`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>
                <template v-slot:[`header.SPEC_RESTRICT_POST`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>
                <template v-slot:[`header.POST_LIMIT_WEIGHT`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>
                <template v-slot:[`header.POST_LIMIT_COMB`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>
                <template v-slot:[`header.POST_REASON`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>
                <template v-slot:[`header.action`]="{ column }" v-if="isManagement">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>

                <template v-slot:item="{ item }">
                  <tr :class="rowClassPosting(item)">
                    <td class="text-center">
                      <button v-if="!expandedRowsPosting.includes(item)" @click="toggleRowPosting(item)">
                        <v-icon
                          :id="`icon_Chevron_Right_${item.POST_STATUS_DATE}`"
                          icon="fa-solid fa-chevron-right"
                        ></v-icon>
                      </button>

                      <button v-else-if="expandedRowsPosting.includes(item)" @click="toggleRowPosting(item)">
                        <v-icon
                          :id="`icon_Chevron_Down_${item.POST_STATUS_DATE}`"
                          icon="fa-solid fa-chevron-down"
                        ></v-icon>
                      </button>
                    </td>
                    <td class="text-center">
                      {{ getFormattedDateStringNoTime(item?.POST_STATUS_DATE) }}
                    </td>
                    <td class="text-center">
                      {{
                        item?.POST_STATUS
                          ? configStore.getReferenceValue(REFERENCE_TABLE.POSTING_STATUS, item?.POST_STATUS)
                          : ""
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        item?.SPEC_RESTRICT_POST
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SPECIAL_RESTRICT_POST,
                              item?.SPEC_RESTRICT_POST
                            )
                          : ""
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          item.POST_LIMIT_WEIGHT,
                          ROUNDING_FACTOR.ROUND
                        )
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          item.POST_LIMIT_COMB,
                          ROUNDING_FACTOR.ROUND
                        )
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        item?.POST_REASON
                          ? configStore.getReferenceValue(REFERENCE_TABLE.POST_REASON, item?.POST_REASON)
                          : ""
                      }}
                    </td>
                    <td class="alignCenter" v-if="isManagement">
                      <div style="white-space: nowrap" v-if="!item.readOnly && addingOrEditing && isEditable">
                        <IconTooltip
                          :id="`icon_add_${item.POSTING_KEY}`"
                          icon="fa-solid fa-check"
                          :tooltip="item?.isAdd ? 'Add' : 'Update'"
                          @click="savePosting(item)"
                        />
                        <IconTooltip
                          :id="`icon_close_${item.POSTING_KEY}`"
                          icon="fa-solid fa-xmark"
                          tooltip="Close"
                          @click="closePosting"
                        />
                      </div>
                      <div
                        style="white-space: nowrap"
                        v-else-if="isEditable && expandedRowsPosting?.length == 0 && !addingOrEditing"
                      >
                        <IconTooltip
                          :id="`icon_edit_${item.UNIQUE_KEY}`"
                          icon="fa-solid fa-pencil"
                          tooltip="Edit"
                          @click="editPosting(item)"
                        />
                      </div>
                    </td>
                  </tr>
                </template>

                <template v-slot:expanded-row="{ item }">
                  <td :colspan="isManagement ? headersPostingEditable.length : headersPosting.length">
                    <v-form ref="postingHistoryForm" v-model="validPostingHistory" class="mt-2" validate-on="blur">
                      <v-row>
                        <v-col
                          xl="6"
                          lg="6"
                          md="12"
                          sm="12"
                          cols="12"
                          :class="$vuetify?.display?.mdAndDown ? 'px-1' : 'py-0'"
                        >
                          <LabelDatefield
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            textXl="6"
                            textLg="5"
                            textMd="5"
                            textSm="5"
                            id="txt_Status_Date"
                            label="VP01 Status Date"
                            v-model="item.POST_STATUS_DATE"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            :isRequired="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedPosting = true"
                          >
                          </LabelDatefield>

                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Posting_Status"
                            label="VP02 Posting Status"
                            :refTable="REFERENCE_TABLE.POSTING_STATUS"
                            v-model="item.POST_STATUS"
                          ></LabelSelect>
                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Posting_Type"
                            label="VP02a Posting Type"
                            :refTable="REFERENCE_TABLE.POSTING_TYPE"
                            v-model="item.SNBI_POST_STATUS"
                            @update:modelValue="updatePostingStatus(item)"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            :isRequired="isEditable && isManagement && !item.readOnly"
                          ></LabelSelect>
                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Posting_Condition"
                            label="VP02b Posting Condition"
                            :refTable="REFERENCE_TABLE.POSTING_CONDITION"
                            v-model="item.SNBI_POST_CONDITION"
                            @update:modelValue="updatePostingStatus(item)"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            :isRequired="isEditable && isManagement && !item.readOnly"
                          ></LabelSelect>
                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Special_Restrictive_Posting"
                            label="VP03 Special Restrictive Posting"
                            :refTable="REFERENCE_TABLE.SPECIAL_RESTRICT_POST"
                            v-model="item.SPEC_RESTRICT_POST"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedPosting = true"
                          ></LabelSelect>
                          <LabelText
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            textXl="6"
                            textLg="5"
                            textMd="5"
                            textSm="5"
                            id="txt_Posted Weight Limit"
                            label="VP04 Posted Weight Limit"
                            v-model="item.POST_LIMIT_WEIGHT"
                            appendWith=" Tons"
                            :pairCode="PAIR_CODE.METRICTON_TO_TON"
                            :roundingFactor="ROUNDING_FACTOR.ROUND"
                            type="number"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedPosting = true"
                            :isRequired="postingFieldRequired(item) && isEditable && isManagement && !item.readOnly"
                          />

                          <LabelText
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            textXl="6"
                            textLg="5"
                            textMd="5"
                            textSm="5"
                            id="txt_Posted_Limit_Combination"
                            label="VP05 Posted Limit Combination"
                            v-model="item.POST_LIMIT_COMB"
                            appendWith=" Tons"
                            :pairCode="PAIR_CODE.METRICTON_TO_TON"
                            :roundingFactor="ROUNDING_FACTOR.ROUND"
                            type="number"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedPosting = true"
                            :isRequired="postingFieldRequired(item) && isEditable && isManagement && !item.readOnly"
                          />
                        </v-col>

                        <v-col
                          xl="6"
                          lg="6"
                          md="12"
                          sm="12"
                          cols="12"
                          :class="$vuetify?.display?.mdAndDown ? 'px-1' : 'py-0'"
                        >
                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Posting_Reason"
                            label="VP06 Posting Reason"
                            :refTable="REFERENCE_TABLE.POST_REASON"
                            v-model="item.POST_REASON"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedPosting = true"
                            :isRequired="
                              (item.SNBI_POST_STATUS == 'C' && isEditable && isManagement && !item.readOnly) ||
                              (postingFieldRequired(item) && isEditable && isManagement && !item.readOnly)
                            "
                          ></LabelSelect>

                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Field_Conditions"
                            label="VP07 Field Conditions"
                            :refTable="REFERENCE_TABLE.FIELD_COND"
                            v-model="item.FIELD_COND"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedPosting = true"
                          ></LabelSelect>

                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Special_Conditions"
                            label="VP08 Special Conditions"
                            :refTable="REFERENCE_TABLE.SPECIAL_CONDITIONS"
                            v-model="item.SPEC_COND"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedPosting = true"
                          ></LabelSelect>

                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_AASHTO_Impact_Code"
                            label="VP09 AASHTO Impact Code"
                            :refTable="REFERENCE_TABLE.AASHTO_IMPACT_CODE"
                            v-model="item.IMPACT"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedPosting = true"
                          ></LabelSelect>
                          <LabelCheckbox
                            v-if="isManagement"
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            class="labelCheckbox"
                            id="checkbox_ Permanently_Closed_Structure"
                            label="VP10 Permanently Closed Structure"
                            v-model="item.PERMANENTLY_CLOSED_STRUC"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedPosting = true"
                          >
                          </LabelCheckbox>
                          <LabelCheckbox
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            class="labelCheckbox"
                            id="checkbox_Active"
                            label="VP12 Active"
                            v-model="item.ACTIVE"
                            :isEditable="isEditable && isManagement && !item.readOnly"
                            @update:model-value="changesDetectedPosting = true"
                          >
                          </LabelCheckbox>
                        </v-col>
                      </v-row>
                    </v-form>
                  </td>
                </template>

                <template v-slot:bottom> </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>

  <v-dialog v-model="showConsiderationsDialog" scrollable persistent class="dialogForm">
    <v-card>
      <v-card-title> Considerations: </v-card-title>
      <v-divider />
      <v-card-text>
        <ul class="pl-3">
          <li v-for="(item, id) in CONSIDERATIONS_POINTS" :key="id">
            {{ item }}
          </li>
        </ul>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          variant="outlined"
          class="mx-5"
          @click="
            {
              showConsiderationsDialog = false;
            }
          "
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
</template>

<script>
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { clone } from "@/util/clone";
import { useConfigStore } from "@/stores/config";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import LabelDatefield from "@/components/common/LabelDatefield.vue";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { convertToInt } from "@/composables/util";
import useStructureType from "@/composables/structureType";
import {
  MODULE_TYPES,
  CONSIDERATIONS_POINTS,
  METRIC_CONVERSION_TYPES,
  INSPECTION_MESSAGES,
} from "@/constants/InspectionConstants";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import useDateField from "@/composables/dateField.js";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";
import { useLoadRatingStore } from "@/components/inspection/store/loadRating";
import { useQuickSearchStore } from "@/stores/quickSearch";
import { LOAD_RATING_AND_POSTING_DATA, POSTING, RATING_LOAD } from "@/constants/LoadRatingAndPosting.js";
import ManagementCommentTypeTextField from "@/components/common/ManagementCommentTypeTextField.vue";
import { useSnackbarStore } from "@/stores/snackbar";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { BUTTONS, COMMON_MESSAGE_CONSTANTS } from "@/constants/CommonWebConstants";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";
import { LOGGER } from "@/util/logger";
import IconTooltip from "@/components/common/IconTooltip.vue";
import { LOAD_RATING_AND_POSTING_PERMISSIONS, ACCESS_LEVELS } from "@/rbac";
import { useUserPermissionStore } from "@/stores/userPermission";
import { useInspectionLinksStore } from "@/stores/inspectionLinks";
import { useUserStore } from "@/stores/userStore";

export default {
  name: "InspectionLoadRatings",
  props: {
    moduleType: { default: MODULE_TYPES.INSPECTION },
  },
  setup(props) {
    const quickSearchStore = useQuickSearchStore();
    const structureHeader = computed(() => quickSearchStore.getStructure());
    const { getStructureType } = useStructureType();
    let loadRatingValue = ref(clone(LOAD_RATING_AND_POSTING_DATA));
    const isTunnel = computed(() => {
      return getStructureType(loadRatingValue.value.SERVTYPON) == "T";
    });
    const allPanels = [0, 1, 2, 3];
    const tunnelPanels = [2];
    let panel = ref(isTunnel.value ? tunnelPanels : allPanels);
    const inspectionStore = useInspectionStore();
    const loadRatingStore = useLoadRatingStore();
    const inspectionLinksStore = useInspectionLinksStore();
    const userStore = useUserStore();
    const snackbarStore = useSnackbarStore();

    const structure = computed(() => inspectionStore.selectedInspection);
    const userPermissionStore = useUserPermissionStore();
    let postingHistoryForm = ref();
    let validPostingHistory = ref(true);
    let validLoadRatingsDetails = ref(true);
    let loadRatingsDetailsForm = ref();
    let changesDetectedPosting = ref(false);
    let changesDetectedRating = ref(false);
    let snackBarId = ref("");
    let assignRatingSetClicked = ref(false);
    let calculationDates = ref([]);
    const closeConfirm = ref(null);

    const hasAccessToEdit = computed(() =>
      userPermissionStore.checkUserPermission(
        LOAD_RATING_AND_POSTING_PERMISSIONS[ACCESS_LEVELS.EDIT],
        userPermissionStore.getUserPermissionByBrkeyFromCache(loadRatingValue.value.BRKEY)
      )
    );

    let addingOrEditing = computed({
      get() {
        return (
          inspectionLinksStore.isAddingOrEditingLoadRatingDetails ||
          inspectionLinksStore.isAddingOrEditingLoadRatingPostingDetails
        );
      },
    });

    let addingOrEditingLoadRatingDetails = computed({
      get() {
        return inspectionLinksStore.isAddingOrEditingLoadRatingDetails;
      },
      set(value) {
        inspectionLinksStore.isAddingOrEditingLoadRatingDetails = value;
      },
    });

    let addingOrEditingLoadRatingPostingDetails = computed({
      get() {
        return inspectionLinksStore.isAddingOrEditingLoadRatingPostingDetails;
      },
      set(value) {
        inspectionLinksStore.isAddingOrEditingLoadRatingPostingDetails = value;
      },
    });

    const isManagement = computed(() => props.moduleType == MODULE_TYPES.MANAGEMENT);

    let validationSnackbar = computed({
      get() {
        return inspectionLinksStore.showValidationSnackbar;
      },
      set(value) {
        inspectionLinksStore.showValidationSnackbar = value;
      },
    });

    let showConsiderationsDialog = ref(false);
    let loadRatingsForm = ref();

    let valid = ref(true);

    let resetDirty = ref(false);

    const validate = async () => {
      validationSnackbar.value = false;
      await loadRatingsForm.value?.validate();
      if (!valid.value) {
        validationSnackbar.value = true;
      }
      return valid.value;
    };

    onMounted(async () => {
      await readData();
      if (!isTunnel.value) {
        getLoadRatingDetails();
      }
      getPostingHistoryDetails();
      await loadRatingsForm.value?.validate();
    });

    const reloadValuesFromStore = async () => {
      resetDirty.value = true;
      loadRatingValue.value = loadRatingStore.getLoadRatingObject();
      if (isManagement.value) {
        loadCalculationDates();
      }
      resetDirty.value = true;
      if (!isTunnel.value) {
        getLoadRatingDetails();
      }
      getPostingHistoryDetails();
      await loadRatingsForm.value?.validate();
    };

    const readData = async () => {
      loadRatingValue.value = clone(LOAD_RATING_AND_POSTING_DATA);
      resetDirty.value = true;
      if (isManagement.value && structureHeader.value?.BRKEY) {
        await loadRatingStore.loadLoadRatingObject(
          props.moduleType,
          structureHeader.value?.BRKEY,
          structureHeader.value?.INSPECTIONID
        );
        loadRatingValue.value = loadRatingStore.getLoadRatingObject();
        loadCalculationDates();
      } else if (props.moduleType == MODULE_TYPES.INSPECTION) {
        await loadRatingStore.loadLoadRatingObject(props.moduleType, null, null, structure.value);
        loadRatingValue.value = loadRatingStore.getLoadRatingObject();
      }
      resetDirty.value = true;
      if (!isTunnel.value) {
        getLoadRatingDetails();
      } else if (!loadRatingValue.value.T_Tunnel_Load_Rating) {
        loadRatingValue.value.T_Tunnel_Load_Rating = LOAD_RATING_AND_POSTING_DATA.T_Tunnel_Load_Rating;
        resetDirty.value = true;
      }
      getPostingHistoryDetails();
      await loadRatingsForm.value?.validate();
    };
    const { getFormattedDateStringNoTime } = useDateField();
    let sortByPosting = ref([{ key: "POST_STATUS_DATE", order: "desc" }]);
    let previousSortByPosting = ref([{ key: "POST_STATUS_DATE", order: "desc" }]);
    const headersPostingEditable = ref([
      {
        key: "data-table-expand",
        width: "3%",
      },
      {
        line1: "VP01",
        line2: "Status",
        line3: "Date",
        key: "POST_STATUS_DATE",
        sortable: true,
      },
      {
        line1: "VP02",
        line2: "Posting",
        line3: "Status",
        key: "POST_STATUS",
        sortable: true,
      },
      {
        line1: "VP03",
        line2: "Special Restrictive",
        line3: "Posting",
        key: "SPEC_RESTRICT_POST",
        sortable: true,
      },
      {
        line1: "VP04",
        line2: "Posted Weight",
        line3: "Limit (Tons)",
        key: "POST_LIMIT_WEIGHT",
        sortable: false,
      },
      {
        line1: "VP05",
        line2: "Posted Limit",
        line3: "Combination (Tons)",
        key: "POST_LIMIT_COMB",
        sortable: false,
      },
      {
        line1: "VP06",
        line2: "Posting",
        line3: "Reason",
        key: "POST_REASON",
        sortable: true,
      },
      {
        line1: null,
        line2: "Action",
        line3: null,
        key: "action",
        sortable: false,
        width: "5%",
      },
    ]);
    const headersPosting = ref([
      {
        key: "data-table-expand",
        width: "3%",
      },
      {
        line1: "VP01",
        line2: "Status",
        line3: "Date",
        key: "POST_STATUS_DATE",
        sortable: true,
      },
      {
        line1: "VP02",
        line2: "Posting",
        line3: "Status",
        key: "POST_STATUS",
        sortable: true,
      },
      {
        line1: "VP03",
        line2: "Special Restrictive",
        line3: "Posting",
        key: "SPEC_RESTRICT_POST",
        sortable: true,
      },
      {
        line1: "VP04",
        line2: "Posted Weight",
        line3: "Limit (Tons)",
        key: "POST_LIMIT_WEIGHT",
        sortable: false,
      },
      {
        line1: "VP05",
        line2: "Posted Limit",
        line3: "Combination (Tons)",
        key: "POST_LIMIT_COMB",
        sortable: false,
      },
      {
        line1: "VP06",
        line2: "Posting",
        line3: "Reason",
        key: "POST_REASON",
        sortable: true,
      },
    ]);

    let postingHistory = ref([]);

    const getPostingHistoryDetails = () => {
      postingHistory.value = [];
      postingHistory.value = clone(loadRatingValue.value.T_Posting);
      if (!postingHistory.value?.[0]) {
        //remove null values from array(if any)
        postingHistory.value = postingHistory.value?.filter((a) => !!a);
      }
      //set readOnly
      postingHistory.value?.forEach((element) => {
        element.readOnly = true;
      });
    };

    let expandedRowsPosting = ref([]);

    const rowClassPosting = (data) => {
      return expandedRowsPosting.value?.find((a) => a.POSTING_KEY === data.POSTING_KEY) ? "rowSelect" : "rowUnselect";
    };

    const toggleRowPosting = (detail) => {
      if (expandedRowsPosting.value.includes(detail)) {
        const index = expandedRowsPosting.value.findIndex((d) => d.POSTING_KEY == detail.POSTING_KEY);
        expandedRowsPosting.value.splice(index, 1);
      } else {
        expandedRowsPosting.value.push(detail);
      }
    };

    const isEditable = computed(() => {
      if (isManagement.value) {
        return !quickSearchStore.getErrorMessage() && loadRatingValue.value?.BRKEY && hasAccessToEdit.value;
      } else {
        return inspectionStore.isEditable;
      }
    });

    const inspCommentMaxLength = computed(() => {
      return configStore.getEnvConfigValue(ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH);
    });

    const notesMaxLength = computed(() => {
      return configStore.getEnvConfigValue(ENV_CONFIG_PROPERTY.NOTES_MAX_LENGTH);
    });

    let expandedRows = ref([]);
    const configStore = useConfigStore();

    let sortBy = ref([{ key: "LOAD_TYPE", order: "asc" }]);
    let previousSortBy = ref([{ key: "LOAD_TYPE", order: "asc" }]);
    const detailsHeadersEditable = ref([
      {
        key: "data-table-expand",
        width: "3%",
      },
      {
        line1: "IR04",
        line2: "Load",
        line3: "Type",
        key: "LOAD_TYPE",
        sortable: true,
      },
      {
        line1: "IR05",
        line2: "NBI",
        line3: "",
        key: "NBI_RATING_IND",
        sortable: true,
      },
      {
        line1: "IR06",
        line2: "Load Rating",
        line3: "Method",
        key: "RATING_ANALYSIS_METHOD",
        sortable: true,
      },
      {
        line1: "IR10",
        line2: "IR",
        line3: "Tons",
        key: "IRLOAD",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR11",
        line2: "OR",
        line3: "Tons",
        key: "ORLOAD",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR11a",
        line2: "SLC",
        line3: "Tons",
        key: "SLC_RATING",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR20",
        line2: "IR",
        line3: "RF",
        key: "IR_LOAD_FACTOR",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR21",
        line2: "OR",
        line3: "RF",
        key: "OR_LOAD_FACTOR",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR17",
        line2: "Rating",
        line3: "Dataset",
        key: "SUPPORT_DATASET",
        width: "12%",
        sortable: true,
      },
      {
        line1: null,
        line2: "Action",
        line3: null,
        key: "action",
        sortable: false,
      },
    ]);
    const detailsHeaders = ref([
      {
        key: "data-table-expand",
        width: "3%",
      },
      {
        line1: "IR04",
        line2: "Load",
        line3: "Type",
        key: "LOAD_TYPE",
        sortable: true,
      },
      {
        line1: "IR05",
        line2: "NBI",
        line3: "",
        key: "NBI_RATING_IND",
        sortable: true,
      },
      {
        line1: "IR06",
        line2: "Load Rating",
        line3: "Method",
        key: "RATING_ANALYSIS_METHOD",
        sortable: true,
      },
      {
        line1: "IR10",
        line2: "IR",
        line3: "Tons",
        key: "IRLOAD",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR11",
        line2: "OR",
        line3: "Tons",
        key: "ORLOAD",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR11a",
        line2: "SLC",
        line3: "Tons",
        key: "SLC_RATING",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR20",
        line2: "IR",
        line3: "RF",
        key: "IR_LOAD_FACTOR",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR21",
        line2: "OR",
        line3: "RF",
        key: "OR_LOAD_FACTOR",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR17",
        line2: "Rating",
        line3: "Dataset",
        key: "SUPPORT_DATASET",
        width: "12%",
        sortable: true,
      },
    ]);

    const convertToPostingObject = (details) => {
      let postingObject = clone(POSTING);
      postingObject.BRKEY = details.BRKEY;
      postingObject.POSTING_KEY = details.POSTING_KEY;
      postingObject.ACTIVE = details.ACTIVE;
      postingObject.FIELD_COND = details.FIELD_COND;
      postingObject.IMPACT = details.IMPACT;
      postingObject.PERMANENTLY_CLOSED_STRUC = details.PERMANENTLY_CLOSED_STRUC;
      postingObject.POST_LIMIT_COMB = details.POST_LIMIT_COMB;
      postingObject.POST_LIMIT_WEIGHT = details.POST_LIMIT_WEIGHT;
      postingObject.POST_REASON = details.POST_REASON;
      postingObject.POST_STATUS = details.POST_STATUS;
      postingObject.POST_STATUS_DATE = details.POST_STATUS_DATE;
      postingObject.SNBI_POST_CONDITION = details.SNBI_POST_CONDITION;
      postingObject.SNBI_POST_STATUS = details.SNBI_POST_STATUS;
      postingObject.SPEC_COND = details.SPEC_COND;
      postingObject.SPEC_RESTRICT_POST = details.SPEC_RESTRICT_POST;
      postingObject.MODTIME = details.MODTIME;
      postingObject.USERKEY = details.USERKEY;
      postingObject.VEH_POST_TYPE = details.VEH_POST_TYPE;

      return postingObject;
    };

    const savePosting = (detail) => {
      validationSnackbar.value = false;
      postingHistoryForm.value
        .validate()
        .then(() => {
          if (validPostingHistory.value) {
            if (!loadRatingValue.value?.T_Posting?.[0]) {
              //remove null values from array(if any)
              loadRatingValue.value.T_Posting = loadRatingValue.value.T_Posting?.filter((a) => !!a);
            }

            changesDetectedPosting.value = false;
            if (detail.isAdd) {
              loadRatingValue.value.T_Posting.push(convertToPostingObject(detail));
            } else {
              //update existing detail
              const updateIndex = loadRatingValue.value.T_Posting.findIndex(
                (a) => a.POSTING_KEY === detail.POSTING_KEY
              );
              loadRatingValue.value.T_Posting.splice(updateIndex, 1, convertToPostingObject(detail));
            }

            //if all required fields entered - close the expanded row
            expandedRowsPosting.value = [];
            addingOrEditingLoadRatingPostingDetails.value = false;
            snackbarStore.removeMessage(snackBarId.value);
            getPostingHistoryDetails();
            sortByPosting.value = previousSortByPosting.value?.map((x) => x);
          } else {
            validationSnackbar.value = true;
          }
        })
        .catch((err) => {
          LOGGER.logException(err);
          changesDetectedPosting.value = false;
        });
    };

    const editPosting = (posting) => {
      previousSortByPosting.value = sortByPosting.value.map((x) => x);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.POSTINGS_DATATABLE,
      });
      posting.readOnly = false;
      addingOrEditingLoadRatingPostingDetails.value = true;
      expandedRowsPosting.value.push(posting);
    };

    let getNewPostingKey = () => {
      if (postingHistory.value.length > 0) {
        return Math.max.apply(
          null,
          postingHistory.value.map(function (T_Posting) {
            return T_Posting.POSTING_KEY + 1;
          })
        );
      } else {
        return 1;
      }
    };

    const addPosting = () => {
      previousSortByPosting.value = sortByPosting.value.map((x) => x);
      let newPosting = clone(POSTING);
      newPosting.BRKEY = loadRatingValue.value.BRKEY;
      newPosting.POSTING_KEY = getNewPostingKey();
      newPosting.isAdd = true;
      postingHistory.value.unshift(newPosting);
      expandedRowsPosting.value.push(newPosting);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.POSTINGS_DATATABLE,
      });
      addingOrEditingLoadRatingPostingDetails.value = true;
      nextTick(() => postingHistoryForm.value?.validate());
    };

    const closePosting = () => {
      if (isEditable.value && changesDetectedPosting.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE, BUTTONS.YES)
          .then((response) => {
            if (!response) {
              changesDetectedPosting.value = false;
              getPostingHistoryDetails();
              expandedRowsPosting.value = [];
              addingOrEditingLoadRatingPostingDetails.value = false;
              validationSnackbar.value = false;
              snackbarStore.removeMessage(snackBarId.value);
              snackBarId.value = snackbarStore.showMessage({
                displayText: COMMON_MESSAGE_CONSTANTS.CHANGES_DISCARDED,
                timeout: 10000,
                messageType: SNACKBAR_MESSAGE_TYPES.SUCCESS,
              });
              sortBy.value = previousSortByPosting.value?.map((x) => x);
            }
          });
      } else {
        getPostingHistoryDetails();
        expandedRowsPosting.value = [];
        addingOrEditingLoadRatingPostingDetails.value = false;
        validationSnackbar.value = false;
        snackbarStore.removeMessage(snackBarId.value);
        sortByPosting.value = previousSortByPosting.value?.map((x) => x);
      }
    };

    const convertToRatingLoadObject = (details) => {
      let ratingLoadObject = clone(RATING_LOAD);
      ratingLoadObject.BRKEY = details.BRKEY;
      ratingLoadObject.RATING_DATE = details.RATING_DATE;
      ratingLoadObject.RATING_KEY = details.RATING_KEY;
      ratingLoadObject.AASHTO_MANUAL_YEAR = details.AASHTO_MANUAL_YEAR;
      ratingLoadObject.AASHTO_SPEC_YEAR = details.AASHTO_SPEC_YEAR;
      ratingLoadObject.ANALYSIS_ENGINEER = details.ANALYSIS_ENGINEER;
      ratingLoadObject.CONTROL_MEM_TYPE = details.CONTROL_MEM_TYPE;
      ratingLoadObject.CREATEDATETIME = details.CREATEDATETIME;
      ratingLoadObject.CREATEUSERKEY = details.CREATEUSERKEY;
      ratingLoadObject.FATIG_LOAD_TYPE = details.FATIG_LOAD_TYPE;
      ratingLoadObject.FATIG_STRESS_CAT = details.FATIG_STRESS_CAT;
      ratingLoadObject.INV_RATING_STRESS_METHOD = details.INV_RATING_STRESS_METHOD;
      ratingLoadObject.IRLOAD = details.IRLOAD;
      ratingLoadObject.IR_LOAD_FACTOR = details.IR_LOAD_FACTOR;
      ratingLoadObject.LEGAL_LOAD_RF = details.LEGAL_LOAD_RF;
      ratingLoadObject.LOAD_TYPE = details.LOAD_TYPE;
      ratingLoadObject.NBI_RATING_IND = details.NBI_RATING_IND;
      ratingLoadObject.NOTES = details.NOTES;
      ratingLoadObject.OPR_RATING_STRESS_METHOD = details.OPR_RATING_STRESS_METHOD;
      ratingLoadObject.ORLOAD = details.ORLOAD;
      ratingLoadObject.OR_LOAD_FACTOR = details.OR_LOAD_FACTOR;
      ratingLoadObject.RATING_ANALYSIS_METHOD = details.RATING_ANALYSIS_METHOD;
      ratingLoadObject.SLC_RATING = details.SLC_RATING;
      ratingLoadObject.STRESS_RANGE = details.STRESS_RANGE;
      ratingLoadObject.SUPPORT_DATASETdetails = details.SUPPORT_DATASET;
      ratingLoadObject.MODTIME = details.MODTIME;
      ratingLoadObject.USERKEY = details.USERKEY;
      ratingLoadObject.VEH_POST_WEIGHT = details.VEH_POST_WEIGHT;

      return ratingLoadObject;
    };

    const saveRating = (detail) => {
      validationSnackbar.value = false;
      loadRatingsDetailsForm.value
        .validate()
        .then(() => {
          if (validLoadRatingsDetails.value) {
            if (!loadRatingValue.value?.T_Rating_Load?.[0]) {
              //remove null values from array(if any)
              loadRatingValue.value.T_Rating_Load = loadRatingValue.value.T_Rating_Load?.filter((a) => !!a);
            }
            if (!isInventoryAndOperatingRatingEditable(detail)) {
              detail.IRLOAD = getInventoryRating(detail);
              detail.ORLOAD = getOperatorRating(detail);
            }
            updateNBI(detail);
            changesDetectedRating.value = false;
            if (detail.isAdd) {
              loadRatingValue.value.T_Rating_Load.push(convertToRatingLoadObject(detail));
            } else {
              //update existing detail
              const updateIndex = loadRatingValue.value.T_Rating_Load.findIndex(
                (a) => a.RATING_KEY === detail.RATING_KEY
              );
              loadRatingValue.value.T_Rating_Load.splice(updateIndex, 1, convertToRatingLoadObject(detail));
            }

            //if all required fields entered - close the expanded row
            expandedRows.value = [];
            addingOrEditingLoadRatingDetails.value = false;
            snackbarStore.removeMessage(snackBarId.value);
            getLoadRatingDetails();
            sortBy.value = previousSortBy.value?.map((x) => x);
          } else {
            validationSnackbar.value = true;
          }
        })
        .catch((err) => {
          LOGGER.logException(err);
          changesDetectedRating.value = false;
        });
    };

    const editRating = (rating) => {
      previousSortBy.value = sortBy.value.map((x) => x);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.RATINGS_DATATABLE,
      });
      rating.readOnly = false;
      addingOrEditingLoadRatingDetails.value = true;
      expandedRows.value.push(rating);
    };

    let getNewRatingKey = () => {
      if (loadRatingDetails.value.length > 0) {
        return Math.max.apply(
          null,
          loadRatingDetails.value.map(function (T_Rating_Load) {
            return T_Rating_Load.RATING_KEY;
          })
        );
      } else {
        return 1;
      }
    };

    const addRating = () => {
      previousSortBy.value = sortBy.value.map((x) => x);
      let newRating = clone(RATING_LOAD);
      newRating.BRKEY = loadRatingValue.value.BRKEY;
      newRating.RATING_KEY = getNewRatingKey();
      newRating.isAdd = true;
      newRating.readOnly = false;
      loadRatingDetails.value?.unshift(newRating);
      expandedRows.value.push(newRating);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.RATINGS_DATATABLE,
      });
      addingOrEditingLoadRatingDetails.value = true;
      nextTick(() => loadRatingsDetailsForm.value?.validate());
    };

    const closeRating = () => {
      if (isEditable.value && changesDetectedRating.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE, BUTTONS.YES)
          .then((response) => {
            if (!response) {
              changesDetectedRating.value = false;
              getLoadRatingDetails();
              expandedRows.value = [];
              addingOrEditingLoadRatingDetails.value = false;
              validationSnackbar.value = false;
              snackbarStore.removeMessage(snackBarId.value);
              snackBarId.value = snackbarStore.showMessage({
                displayText: COMMON_MESSAGE_CONSTANTS.CHANGES_DISCARDED,
                timeout: 10000,
                messageType: SNACKBAR_MESSAGE_TYPES.SUCCESS,
              });
              sortBy.value = previousSortBy.value?.map((x) => x);
            }
          });
      } else {
        getLoadRatingDetails();
        expandedRows.value = [];
        addingOrEditingLoadRatingDetails.value = false;
        validationSnackbar.value = false;
        snackbarStore.removeMessage(snackBarId.value);
        sortBy.value = previousSortBy.value?.map((x) => x);
      }
    };

    const switchRatingSet = async () => {
      const ratingLoads = await loadRatingStore.getAssignedRatingSet(
        loadRatingValue.value?.BRKEY,
        loadRatingValue.value?.RATING_DATE
      );
      let cleanedRatingLoads = [];
      ratingLoads?.forEach((ratingLoad) => {
        cleanedRatingLoads.push({
          BRKEY: ratingLoad.brkey,
          RATING_DATE: ratingLoad.ratingDate,
          RATING_KEY: ratingLoad.ratingKey,
          AASHTO_MANUAL_YEAR: ratingLoad.aashtoManualYear,
          AASHTO_SPEC_YEAR: ratingLoad.aashtoSpecYear,
          ANALYSIS_ENGINEER: ratingLoad.analysisEngineer,
          CONTROL_MEM_TYPE: ratingLoad.controlMemType,
          CREATEDATETIME: ratingLoad.createdDateTime,
          CREATEUSERKEY: ratingLoad.createdUserId,
          FATIG_LOAD_TYPE: ratingLoad.fatigLoadType,
          FATIG_STRESS_CAT: ratingLoad.fatigStressCat,
          INV_RATING_STRESS_METHOD: ratingLoad.invRatingStressMethod,
          IRLOAD: ratingLoad.irLoad,
          IR_LOAD_FACTOR: ratingLoad.irLoadFactor,
          LEGAL_LOAD_RF: ratingLoad.legalLoadRF,
          LOAD_TYPE: ratingLoad.loadType,
          NBI_RATING_IND: ratingLoad.nbiRatingInd,
          NOTES: ratingLoad.notes,
          OPR_RATING_STRESS_METHOD: ratingLoad.oprRatingStressMethod,
          ORLOAD: ratingLoad.orLoad,
          OR_LOAD_FACTOR: ratingLoad.orLoadFactor,
          RATING_ANALYSIS_METHOD: ratingLoad.ratingAnalysisMethod,
          SLC_RATING: ratingLoad.slcRating,
          STRESS_RANGE: ratingLoad.stressRange,
          SUPPORT_DATASET: ratingLoad.supportDataset,
          MODTIME: ratingLoad.updateDateTime,
          USERKEY: ratingLoad.updateUserId,
          VEH_POST_WEIGHT: ratingLoad.vehPostWeight,
          VEH_POST_TYPE: ratingLoad.vehPostType,
        });
      });
      loadRatingValue.value.T_Rating_Load = cleanedRatingLoads;
      loadRatingValue.value.RATING_APPR_DATE = new Date().toISOString();
      loadRatingValue.value.APPROVER_NAME =
        userStore.loggedInUser.given_name + " " + userStore.loggedInUser.family_name;
      getLoadRatingDetails();
      loadCalculationDates();
    };

    const loadCalculationDates = () => {
      calculationDates.value = [];
      loadRatingValue.value?.calculationDates?.forEach((date) => {
        if (getFormattedDateStringNoTime(date) == calculationDate.value) {
          calculationDates.value.push({
            title: getFormattedDateStringNoTime(date) + "*",
            value: getFormattedDateStringNoTime(date),
          });
        } else {
          calculationDates.value.push({
            title: getFormattedDateStringNoTime(date),
            value: date,
          });
        }
      });
    };

    let loadRatingDetails = ref([]);

    const sortDetails = (arr) => {
      return arr?.sort(function (a, b) {
        return (
          convertToInt(a.LOAD_TYPE) - convertToInt(b.LOAD_TYPE) ||
          convertToInt(a.NBI_RATING_IND) - convertToInt(b.NBI_RATING_IND)
        );
      });
    };

    const getLoadRatingDetails = () => {
      loadRatingDetails.value = [];

      loadRatingDetails.value = clone(loadRatingValue.value.T_Rating_Load);
      if (!loadRatingDetails.value?.[0]) {
        //remove null values from array(if any)
        loadRatingDetails.value = loadRatingDetails.value?.filter((a) => !!a);
      }
      //set unique key for toggle expansion
      loadRatingDetails.value?.forEach((element) => {
        element.UNIQUE_KEY = element.LOAD_TYPE + "-" + element.NBI_RATING_IND;
        element.readOnly = true;
      });
      loadRatingDetails.value = sortDetails(loadRatingDetails.value);
    };

    const rowClassDetails = (data) => {
      return expandedRows.value?.find((a) => a.UNIQUE_KEY === data.UNIQUE_KEY) ? "rowSelect" : "rowUnselect";
    };

    const nbiRating1 = computed(() => {
      return loadRatingValue.value.T_Rating_Load?.find((a) => a.NBI_RATING_IND === "1") || {};
    });

    let calculationDate = computed({
      get() {
        return isManagement.value
          ? getFormattedDateStringNoTime(loadRatingValue.value.RATING_DATE)
          : loadRatingValue.value.RATING_DATE;
      },
      set(value) {
        loadRatingValue.value.RATING_DATE = value;
      },
    });

    const getInventoryRating = (item) => {
      let returnValue = null;
      switch (item.LOAD_TYPE) {
        case "1":
          returnValue = item.IR_LOAD_FACTOR * 20;
          break;
        case "2":
          returnValue = item.IR_LOAD_FACTOR * 36;
          break;
        case "8":
          returnValue = item.IR_LOAD_FACTOR * 36.64;
          break;
        case "0":
          returnValue = item.IR_LOAD_FACTOR * 40;
          break;
      }
      return Math.round(returnValue);
    };

    const getOperatorRating = (item) => {
      let returnValue = null;
      switch (item.LOAD_TYPE) {
        case "1":
          returnValue = item.OR_LOAD_FACTOR * 20;
          break;
        case "2":
          returnValue = item.OR_LOAD_FACTOR * 36;
          break;
        case "8":
          returnValue = item.OR_LOAD_FACTOR * 36.64;
          break;
        case "0":
          returnValue = item.OR_LOAD_FACTOR * 40;
          break;
      }
      return Math.round(returnValue);
    };

    const isInventoryAndOperatingRatingEditable = (item) => {
      return !["8", "D", "E", "F", "6"].includes(item.RATING_ANALYSIS_METHOD) || item.LOAD_TYPE != "D";
    };

    const updateNBI = (item) => {
      if (item.NBI_RATING_IND == "1") {
        loadRatingDetails.value.forEach((ratingLoad) => {
          if (ratingLoad.NBI_RATING_IND == "1" && ratingLoad.RATING_KEY != item.RATING_KEY) {
            ratingLoad.NBI_RATING_IND = "0";
          }
        });
      }
    };

    const postingFieldRequired = (item) => {
      switch (item.SNBI_POST_STATUS) {
        case "N":
          return false;
        case "C":
          return false;
        case "X":
          return false;
        case "P":
        case "S":
        case "T":
          switch (item.SNBI_POST_CONDITION) {
            case "A":
            case "D":
              return true;
            case "M":
            case "P":
              return true;
            case "O":
              return false;
            case "R":
              return true;
          }
          break;
      }
    };

    const updatePostingStatus = (item) => {
      changesDetectedPosting.value = true;
      switch (item.SNBI_POST_STATUS) {
        case "N":
          item.POST_STATUS = "G";
          item.SNBI_POST_CONDITION = "";
          break;
        case "C":
          item.POST_STATUS = "C(K)";
          item.SNBI_POST_CONDITION = "";
          break;
        case "X":
          item.POST_STATUS = "X";
          item.SNBI_POST_CONDITION = "";
          break;
        case "P":
          switch (item.SNBI_POST_CONDITION) {
            case "A":
            case "D":
              item.POST_STATUS = "B";
              break;
            case "M":
            case "P":
              item.POST_STATUS = "P";
              break;
            case "O":
              item.POST_STATUS = "A";
              break;
            case "R":
              item.POST_STATUS = "R";
              break;
          }
          break;
        case "S":
          switch (item.SNBI_POST_CONDITION) {
            case "A":
            case "D":
              item.POST_STATUS = "B";
              break;
            case "M":
            case "P":
              item.POST_STATUS = "P";
              break;
            case "O":
              item.POST_STATUS = "D";
              break;
            case "R":
              item.POST_STATUS = "R";
              break;
          }
          break;
        case "T":
          switch (item.SNBI_POST_CONDITION) {
            case "A":
            case "D":
              item.POST_STATUS = "B";
              break;
            case "M":
            case "P":
              item.POST_STATUS = "P";
              break;
            case "O":
              item.POST_STATUS = "E";
              break;
            case "R":
              item.POST_STATUS = "R";
              break;
          }
          break;
      }
    };

    const updateLegalLoadRatingFactor = (item) => {
      changesDetectedRating.value = true;
      switch (item.LOAD_TYPE) {
        case "1":
        case "A":
          item.LEGAL_LOAD_RF = parseInt(
            configStore.getReferenceValue(REFERENCE_TABLE.LEGAL_LOAD_RATING_FACTOR_WEIGHT, "H20")?.split("-")[1]
          );
          break;
        case "2":
        case "B":
          item.LEGAL_LOAD_RF = parseInt(
            configStore.getReferenceValue(REFERENCE_TABLE.LEGAL_LOAD_RATING_FACTOR_WEIGHT, "HS20")?.split("-")[1]
          );

          break;
        case "8":
        case "C":
          item.LEGAL_LOAD_RF = parseInt(
            configStore.getReferenceValue(REFERENCE_TABLE.LEGAL_LOAD_RATING_FACTOR_WEIGHT, "ML80")?.split("-")[1]
          );
          break;
        case "0":
          item.LEGAL_LOAD_RF = parseInt(
            configStore.getReferenceValue(REFERENCE_TABLE.LEGAL_LOAD_RATING_FACTOR_WEIGHT, "TK527")?.split("-")[1]
          );
          break;
      }
      if (["0", "1", "2", "8", "A", "B", "C"].includes(item.LOAD_TYPE)) {
        item.VEH_POST_TYPE = "G";
      }
      updateVehiclePostingValue(item);
    };

    const updateVehiclePostingValue = (item) => {
      const postedPosting = postingHistory.value.find((posting) => posting.POST_STATUS === "P");
      if (postedPosting) {
        switch (item.LOAD_TYPE) {
          case "0":
          case "1":
          case "8":
          case "A":
          case "C":
            item.VEH_POST_WEIGHT = postedPosting.POST_LIMIT_WEIGHT;
            break;
          case "2":
          case "B":
            item.VEH_POST_WEIGHT = postedPosting.POST_LIMIT_COMB;
            break;
        }
      }
    };

    const controllingLegalLoadRatingFactor = computed(() => {
      if (!isTunnel.value) {
        return getLowestLegalLoadRatingFactor();
      } else {
        return null;
      }
    });

    const getLowestLegalLoadRatingFactor = () => {
      if (loadRatingValue.value.T_Rating_Load?.length > 0) {
        return Math.max.apply(
          null,
          loadRatingValue.value.T_Rating_Load.map(function (T_Rating_Load) {
            return T_Rating_Load.LEGAL_LOAD_RF;
          })
        );
      } else {
        return null;
      }
    };

    const checkChangeReviewerAction = () => {
      if (loadRatingValue.value.RECOMMEND_RATING_REVIEW == "1") {
        loadRatingValue.value.LR_REVIEW_ACTION = "0";
      }
      if (loadRatingValue.value.RECOMMEND_RATING_REVIEW == "0") {
        loadRatingValue.value.LR_REVIEW_ACTION = "1";
      }
    };

    const isRF = (ratingAnalysisMethod, loadType, isNBI) => {
      if (
        ratingAnalysisMethod == "8" ||
        ratingAnalysisMethod == "D" ||
        ratingAnalysisMethod == "E" ||
        ratingAnalysisMethod == "F"
      ) {
        return true;
      } else if (isNBI && ratingAnalysisMethod == "6" && loadType == "D") {
        return true;
      } else {
        return false;
      }
    };

    const toggleRowDetails = (detail) => {
      if (expandedRows.value.includes(detail)) {
        const index = expandedRows.value.findIndex((d) => d.UNIQUE_KEY == detail.UNIQUE_KEY);
        expandedRows.value.splice(index, 1);
      } else {
        expandedRows.value.push(detail);
      }
    };
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = isTunnel.value ? tunnelPanels : allPanels;
      } else {
        panel.value = [];
      }
    };

    const saveChanges = async () => {
      const isSuccess = await loadRatingStore.saveLoadRating(props.moduleType, loadRatingValue.value, structure.value);
      if (isSuccess) {
        inspectionLinksStore.isDirty = false;
        await reloadValuesFromStore(); // Using this flag to not set the dirty flag in watch
        assignRatingSetClicked.value = false;
      }
      return isSuccess;
    };

    const cancelChanges = async () => {
      await reloadValuesFromStore();
      assignRatingSetClicked.value = false;
      inspectionLinksStore.isDirty = false;
    };

    const setUpdatedComment = (comment, commentType) => {
      const foundComment = loadRatingValue.value.inspectionComments.find((c) => {
        return c.commentType === commentType;
      });
      if (foundComment) {
        foundComment.notes = comment.notes;
      } else {
        loadRatingValue.value.inspectionComments.push(comment);
      }
    };

    watch(
      () => [loadRatingValue.value, structure.value.T_Insp_Comment],
      (_, oldValue) => {
        // This watch will NOT be triggered during initial onMounted()
        if (JSON.stringify(oldValue) != "{}") {
          if (isManagement.value) {
            // for inspection management module
            if (resetDirty.value) {
              inspectionLinksStore.isDirty = false;
              resetDirty.value = false;
            } else {
              inspectionLinksStore.isDirty = true;
            }
          } else if (props.moduleType === MODULE_TYPES.INSPECTION) {
            // for inspection  module
            if (resetDirty.value) {
              inspectionStore.setDirtyFlag(false);
              resetDirty.value = false;
            } else {
              inspectionStore.setDirtyFlag(true);
            }
          }
        }
      },
      { deep: true }
    );

    watch(
      () => [props],
      () => {
        readData();
      },
      { deep: true }
    );

    return {
      addingOrEditingLoadRatingPostingDetails,
      addingOrEditingLoadRatingDetails,
      calculationDate,
      controllingLegalLoadRatingFactor,
      postingFieldRequired,
      updatePostingStatus,
      getOperatorRating,
      getInventoryRating,
      isInventoryAndOperatingRatingEditable,
      calculationDates,
      assignRatingSetClicked,
      addingOrEditing,
      headersPostingEditable,
      detailsHeadersEditable,
      isManagement,
      updateLegalLoadRatingFactor,
      saveRating,
      savePosting,
      closeConfirm,
      BUTTONS,
      validPostingHistory,
      validLoadRatingsDetails,
      loadRatingsDetailsForm,
      closePosting,
      closeRating,
      editRating,
      editPosting,
      postingHistoryForm,
      switchRatingSet,
      addRating,
      addPosting,
      readData,
      setUpdatedComment,
      MODULE_TYPES,
      cancelChanges,
      saveChanges,
      isRF,
      checkChangeReviewerAction,
      detailsHeaders,
      sortBy,
      toggleRowPosting,
      rowClassPosting,
      expandedRowsPosting,
      panel,
      REFERENCE_TABLE,
      isEditable,
      loadRatingValue,
      INSPECTION_COMMENTS,
      loadRatingDetails,
      expandedRows,
      configStore,
      inspCommentMaxLength,
      rowClassDetails,
      PAIR_CODE,
      ROUNDING_FACTOR,
      nbiRating1,
      getStructureType,
      isTunnel,
      notesMaxLength,
      showConsiderationsDialog,
      CONSIDERATIONS_POINTS,
      loadRatingsForm,
      validate,
      valid,
      headersPosting,
      postingHistory,
      toggleRowDetails,
      getFormattedDateStringNoTime,
      sortByPosting,
      METRIC_CONVERSION_TYPES,
      toggleAllPanels,
    };
  },
  components: {
    IconTooltip,
    ConfirmDialog,
    ManagementCommentTypeTextField,
    DataTableHeader,
    LabelSelect,
    LabelText,
    LabelCheckbox,
    LabelDatefield,
    CommentTypeTextField,
    ExpandCollapseToggle,
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/general" as g;
@use "@/styles/colors" as c;
.handleFlex {
  flex: none !important;
}

.details-text :deep(.v-input__details) {
  display: flex !important;
  justify-content: space-between !important;
}

.details-text :deep(.v-messages) {
  display: none !important;
}

@media #{g.$p-breakPointLgAndUp} {
  .reviewActionField {
    padding-top: 5px !important;
  }
}
.alignCenter {
  text-align: center;
}
.alignEnd {
  text-align: end;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}
</style>

export const DECK_MAX_VALUE = {
  WEARING_SURFACE_THICKNESS: 99,
};

export const STRUCTURE_TYPE = {
  TUNNEL: "T",
  BRIDGE: "B",
};

export const FUNCTIONAL_SYSTEM = {
  MAT_KEY: "S",
  STR_UNIT_TYPE: "Z",
};

export const MAINTENANCE_CANDIDATE_TYPE = {
  PROPOSED: "proposed",
  COMPLETED: "completed",
};

export const INSPECTION_DELETE_CONFIRM_MESSAGES = {
  EDOCS_DRAWING_DELETE_CONFIRM_MESSAGE:
    "Are you sure you want to delete this drawing?",
};

export const INSPECTION_MESSAGES = {
  WORK_PERFORMED_ERROR:
    "VB03 is populated from B.W.03.  At least one VB03 check box is required to save the record.",
  SYSTEM_DELETE_ERROR:
    "The System Level Element cannot be deleted as Functional element(s) are present.",
  SYSTEM_ELEMENT_REQUIRED:
    "System Level Element is required before adding a Functional Element. Please add",
  CANCEL_CHANGE_MESSAGE:
    "Are you sure you want to cancel the changes on the screen?",
  DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE:
    "Warning!!! Unsaved changes exist on the datatable. Do you want to update and save before navigating away?",
  DETECT_CHANGE_CONFIRMATION_MESSAGE:
    "Warning!!! Unsaved changes exist on the page. Do you want to save before navigating away?",
  POPUP_DETECT_CHANGE_CONFIRMATION_MESSAGE:
    "Warning!!! Unsaved changes exist on the popup. Do you want to save before navigating away?",
  NO_SIGN_TYPES_AVAILABLE_TO_ADD: "No Sign Types are available to Add",
  SIGNS_DATATABLE:
    "Signs datatable is being added/edited, please add/update/close the table before continuing.",
  JOINTS_DATATABLE:
    "Joints datatable is being added/edited, please add/update/close the table before continuing.",
  BEARINGS_DATATABLE:
    "Bearings datatable is being added/edited, please add/update/close the table before continuing.",
  NSTM_FATIGUE_DATATABLE:
    "NSTM/Fatigue datatable is being added/edited, please add/update/close the table before continuing.",
  CURRENT_SCOUR_MEASURES_AND_COUNTERMEASURES_DATATABLE:
    "Current Scour Measures and Countermeasures datatable is being added/edited, please add/update/close the table before continuing.",
  POTENTIAL_COUNTERMEASURES_DATATABLE:
    "Potential Countermeasures datatable is being added/edited, please add/update/close the table before continuing.",
  SUBUNITS_WITHIN_500YR_FLOOD_PLAIN_DATATABLE:
    "Subunits within 500yr Flood Plain datatable is being added/edited, please add/update/close the table before continuing.",
  FEATURE_INTERSECTION_DETAILS_DATATABLE:
    "Feature Intersection Details datatable is being added/edited, please add/update/close the table before continuing.",
  SUBSTRUCTURE_INSPECTION_NOTES_SAVE_MESSAGE:
    "Substructure Notes datatable is being added/edited, please add/update/close the table before continuing.",
  INSPECTION_NOTES_SAVE_MESSAGE:
    "Inspection Notes datatable is being added/edited, please add/update/close the table before continuing.",
  MAINTENANCE_COMPLETED_SAVE_MESSAGE:
    "Completed Candidates table is being Edited, please Update/Close the table in order to continue.",
  MAINTENANCE_PROPOSED_SAVE_MESSAGE:
    "Proposed Candidates table is being Added/Edited, please Update/Close the table in order to continue.",
  NO_FATIGUE_LOCATIONS_AVAILABLE_TO_ADD: "No members are available to add",
  VALIDATION_FAILED_MESSAGE:
    "Field value entries exist outside of the acceptable range, or not all required fields are filled in, please fix the field values or complete the required fields and Save again.",
  OFFLINE_PASSWORD_WARNING_MESSAGE:
    "Please be aware that clearing cache within the browser will delete all downloaded structures from your BMS3 worklist and will result in lost progress on any unsubmitted inspections.",
  SNBI_EVENTS_DATATABLE:
    "Inspection types datatable is being added/edited, please add/update/close the table before continuing.",
  DRAWINGS_DATATABLE:
    "Drawings datatable is being added/edited, please add/update/close the table before continuing.",
  POSTINGS_DATATABLE:
    "Posting datatable is being added/edited, please add/update/close the table before continuing.",
  RATINGS_DATATABLE:
    "Rating Set datatable is being added/edited, please add/update/close the table before continuing.",
};

export const CONSIDERATIONS_POINTS = [
  "Has the dead load increased(e.g., additional wearing surface has been placed)?",
  "Has there been new or additional section loss to the main load carrying members in critical areas?",
  "Has the existing capacity of the bridge been increased (e.g., bridge has been rehabilitated)?",
  "Has there been a change in the Live Load configuration (e.g., barriers have been installed to restrict vehicles from certain areas)?",
  "Has the superstructure configuration changed (e.g., the bridge has been widened)?",
  "Has the substructure or superstructure deteriorated to a condition that may limit or further limit the load carrying capacity of the bridge (e.g., a substructure unit exhibits advanced section loss)?",
  "Has the condition code or distress level changed on a bridge load rated with the engineering judgement load rating method?",
  "Has the deck deteriorated to a condition that may limit or further limit the load carrying capacity of the bridge?",
  "Has there been a change in the condition code of the superstructure or substructure, which would  result in the need to apply an SLC factor?  Apply 0.8 factor when Super/Sub = 4 (ADTT >= 500) or <= 3, 0.9 factor when Super/Sub = 4 (ADTT < 500).",
  "If the controlling super/culvert condition rating is = 5 for 15 years or more, is the current load rating >= 15 years old?",
  "If the controlling super/culvert condition rating is <= 4, is the current load rating >= 10 years old?",
];

export const METRIC_CONVERSION_TYPES = {
  L1: "L1",
  L2: "L2",
  L3: "L3",
  L4: "L4",
  L5: "L5",
  W1: "W1",
};

export const SAFETY_FEATURE_TYPES = {
  BRIDGE_RAILING: "1",
  TRANSITION: "2",
  GUIDERAIL: "3",
  RAIL_END: "4",
};

export const MODULE_TYPES = {
  INSPECTION: "inspection",
  MANAGEMENT: "management",
};

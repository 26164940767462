<template>
  <v-row>
    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
      <v-table class="hide_scroll compactTable padding-xs">
        <thead>
          <tr>
            <th id="th_fieldComparison" aria-label="blankTableHeader" style="width: 55%" class="align-center" />
            <th id="th_current" class="align-center">Current</th>
            <th id="th_previous" class="align-center">Previous</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="field in fieldsToCompare" :key="field.rowName">
            <td
              class="boldFont"
              :class="{
                difference: field.differing || field.nonControllingChange,
              }"
            >
              {{ field.rowName }}: {{ field.differing ? "*" : "" }}
            </td>
            <td
              class="align-center"
              :class="{
                difference: field.differing || field.nonControllingChange,
              }"
            >
              <span :class="{ boldFont: field.nonControllingChange }"
                >{{ field.currentCol }}{{ field.nonControllingChange ? " *" : "" }}</span
              >
            </td>
            <td
              class="align-center"
              :class="{
                difference: field.differing || field.nonControllingChange,
              }"
            >
              {{ field.previousCol }}
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-col>
    <v-col xl="8" lg="8" md="8" sm="12" xs="12">
      <v-row>
        <v-col align="center" xl="1" lg="2" md="2" sm="2" xs="2">
          <span>&nbsp;</span>
          <v-table class="noBorders hide_scroll compactTable pt-2 padding-xs">
            <thead>
              <tr>
                <th id="th_empty" aria-label="blankTableHeader">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">NBI</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">NSTM</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">UW</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">OS</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">Element</td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col xl="3" lg="3" md="3" sm="3" xs="3" align="center" v-if="props.showPerformedData">
          <v-label class="bold">7A06 Performed</v-label>
          <v-table class="hide_scroll compactTable padding-xs">
            <thead>
              <tr>
                <th id="th_current" class="align-center">Current</th>
                <th id="th_previous" class="align-center">Previous</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="tableRowHeight align-center"
                v-for="performedInsp in performedInspToCompare"
                :key="performedInsp.rowName"
              >
                <td :class="{ difference: performedInsp.differing }">
                  <v-checkbox
                    class="d-flex justify-center"
                    id="{{ performedInsp.currentId }}"
                    v-model="performedInsp.currentModel"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td :class="{ difference: performedInsp.differing }">
                  <v-checkbox
                    class="d-flex justify-center"
                    id="{{ performedInsp.previousId }}"
                    :model-value="performedInsp.previousModel"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col xl="3" lg="3" md="3" sm="3" xs="3" align="center">
          <v-label class="bold">7A07 Required</v-label>
          <v-table class="hide_scroll compactTable padding-xs">
            <thead>
              <tr>
                <th id="th_current" class="align-center">Current</th>
                <th id="th_previous" class="align-center">Previous</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tableRowHeight align-center">
                <td>N/A</td>
                <td>N/A</td>
              </tr>
              <tr
                class="tableRowHeight align-center"
                v-for="requiredInsp in requiredInspToCompare"
                :key="requiredInsp.rowName"
              >
                <td :class="{ difference: requiredInsp.differing }">
                  <v-checkbox
                    class="d-flex justify-center"
                    id="{{ requiredInsp.currentId }}"
                    v-model="requiredInsp.currentModel"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td :class="{ difference: requiredInsp.differing }">
                  <v-checkbox
                    class="d-flex justify-center"
                    id="{{ requiredInsp.previousId }}"
                    :model-value="requiredInsp.previousModel"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col xl="3" lg="3" md="3" sm="3" xs="3" align="center">
          <v-label class="bold">7A09 Interval</v-label>
          <v-table class="hide_scroll compactTable padding-xs">
            <thead>
              <tr>
                <th id="th_intervalCurrent" class="align-center">Current</th>
                <th id="th_intervalPrevious" class="align-center">Previous</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="interval in intervalsToCompare" :key="interval.rowName" class="tableRowHeight align-center">
                <td :class="{ difference: interval.differing }">
                  {{ interval.currentInterval }}
                </td>
                <td :class="{ difference: interval.differing }">
                  {{ interval.previousInterval }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script setup>
import useDateField from "@/composables/dateField.js";
import { useInspectionStore } from "@/stores/inspection";
import { computed, ref, onMounted, watch, defineExpose } from "vue";
import { MODULE_TYPES } from "@/constants/InspectionConstants";
import { useQuickSearchStore } from "@/stores/quickSearch";
import { storeToRefs } from "pinia";
import { useKeyfieldsStore } from "@/components/inspection/store/keyfields";

const props = defineProps({
  showPerformedData: { default: false, type: Boolean },
  moduleType: { default: MODULE_TYPES.INSPECTION },
});

const { getFormattedDateStringNoTime } = useDateField();
const inspectionStore = useInspectionStore();
const overallScourHoles = computed({
  get() {
    return keyFieldsValue?.value?.currentInsp?.OVERALL_SCOUR_HOLES;
  },
});
const prevOverallScourHoles = computed({
  get() {
    return keyFieldsValue?.value?.previousInsp?.OVERALL_SCOUR_HOLES;
  },
});
const quickSearchStore = useQuickSearchStore();
const structureHeader = computed(() => quickSearchStore.getStructure());
let keyFieldsValue = ref({});
const { hasQuickSearchSucceeded } = storeToRefs(quickSearchStore);
const keyfieldsStore = useKeyfieldsStore();

onMounted(async () => {
  await readData();
  refreshKeyFieldsArrays();
});

const readData = async () => {
  keyFieldsValue.value = {};
  if (props.moduleType == MODULE_TYPES.MANAGEMENT && structureHeader.value?.BRKEY) {
    if (!hasQuickSearchSucceeded.value) {
      return;
    }
    await keyfieldsStore.loadDataObject(
      props.moduleType,
      structureHeader.value.BRKEY,
      structureHeader.value.INSPECTIONID
    );
    keyFieldsValue.value = keyfieldsStore.getKeyfieldsObject();
  } else if (props.moduleType == MODULE_TYPES.INSPECTION) {
    const structure = computed(() => inspectionStore.selectedInspection);
    await keyfieldsStore.loadDataObject(props.moduleType, null, null, structure.value);
    keyFieldsValue.value = keyfieldsStore.getKeyfieldsObject();
  }
};

let fieldsToCompare = ref({});
const setFieldsToCompare = () => {
  fieldsToCompare.value = [];
  fieldsToCompare.value.push({
    rowName: "7A01 Inspection Date",
    currentCol: getFormattedDateStringNoTime(keyFieldsValue?.value?.currentInsp?.INSPDATE),
    previousCol: getFormattedDateStringNoTime(keyFieldsValue?.value?.previousInsp?.INSPDATE),
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "7A03 Inspection Type",
    currentCol: keyFieldsValue?.value?.currentInsp?.INSPTYPE || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.INSPTYPE || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "1A01 Deck",
    currentCol: keyFieldsValue?.value?.currentInsp?.DKRATING || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.DKRATING || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "1A04 Superstructure",
    currentCol: keyFieldsValue?.value?.currentInsp?.SUPRATING || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.SUPRATING || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "1A02 Substructure",
    currentCol: keyFieldsValue?.value?.currentInsp?.SUBRATING || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.SUBRATING || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "1A03 Culvert",
    currentCol: keyFieldsValue?.value?.currentInsp?.CULVRATING || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.CULVRATING || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "IA02/B.C.05 Railings",
    currentCol: keyFieldsValue?.value?.currentInsp?.RAILRATING || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.RAILRATING || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "IA02/B.C.06 Transition",
    currentCol: keyFieldsValue?.value?.currentInsp?.TRANSRATIN || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.TRANSRATIN || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "IB01 Bearings",
    currentCol: keyFieldsValue?.value?.currentInsp?.BEARING_OVERALL_COND_RATE || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.BEARING_OVERALL_COND_RATE || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "IJ01 Joints",
    currentCol: keyFieldsValue?.value?.currentInsp?.JOINTS_OVERALL_COND_RATE || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.JOINTS_OVERALL_COND_RATE || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "1A05 Channel",
    currentCol: keyFieldsValue?.value?.currentInsp?.CHANRATING || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.CHANRATING || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "1A05b Channel Protection",
    currentCol: keyFieldsValue?.value?.currentInsp?.CHAN_PROT_RATING || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.CHAN_PROT_RATING || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "1A13 Scour",
    currentCol: overallScourHoles.value && overallScourHoles.value != "" ? overallScourHoles.value : "--",
    previousCol: prevOverallScourHoles.value && prevOverallScourHoles.value != "" ? prevOverallScourHoles.value : "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "1A14 Underwater",
    currentCol: keyFieldsValue?.value?.currentInsp?.UNDERWATER_RATING || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.UNDERWATER_RATING || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "1A15 NSTM",
    currentCol: keyFieldsValue?.value?.currentInsp?.NSTM_CONDITION || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.NSTM_CONDITION || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "4A08 SCBI",
    currentCol: keyFieldsValue?.value?.currentInsp?.SCOURCRIT || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.SCOURCRIT || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "4A08b SCBI Category",
    currentCol: keyFieldsValue?.value?.currentInsp?.SCOUR_CRIT_CTGRY || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.SCOUR_CRIT_CTGRY || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
  fieldsToCompare.value.push({
    rowName: "IN15 Streambed Material",
    currentCol: keyFieldsValue?.value?.currentInsp?.STREAM_BED_MATERIAL || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.STREAM_BED_MATERIAL || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
    nonControllingChange: keyFieldsValue?.value?.currentInsp?.STREAM_BED_MATERIAL_NON_CONTROLLING_CHANGE,
  });
  fieldsToCompare.value.push({
    rowName: "4A14 Bridge Condition",
    currentCol: keyFieldsValue?.value?.currentInsp?.CONDITION || "--",
    previousCol: keyFieldsValue?.value?.previousInsp?.CONDITION || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  });
};

let performedInspToCompare = ref({});
const setPerformedInspToCompare = () => {
  performedInspToCompare.value = [];
  performedInspToCompare.value.push({
    currentId: "checkbox_nbi_Current",
    currentModel: keyFieldsValue?.value?.currentInsp?.NBINSPDONE,
    previousId: "checkbox_nbi_Previous",
    previousModel: keyFieldsValue?.value?.previousInsp?.NBINSPDONE,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  });
  performedInspToCompare.value.push({
    currentId: "checkbox_nstm_Current",
    currentModel: keyFieldsValue?.value?.currentInsp?.FCINSPDONE,
    previousId: "checkbox_nstm_Previous",
    previousModel: keyFieldsValue?.value?.previousInsp?.FCINSPDONE,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  });
  performedInspToCompare.value.push({
    currentId: "checkbox_uw_Current",
    currentModel: keyFieldsValue?.value?.currentInsp?.UWINSPDONE,
    previousId: "checkbox_uw_Previous",
    previousModel: keyFieldsValue?.value?.previousInsp?.UWINSPDONE,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  });
  performedInspToCompare.value.push({
    currentId: "checkbox_os_Current",
    currentModel: keyFieldsValue?.value?.currentInsp?.OSINSPDONE,
    previousId: "checkbox_os_Previous",
    previousModel: keyFieldsValue?.value?.previousInsp?.OSINSPDONE,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  });
  performedInspToCompare.value.push({
    currentId: "checkbox_element_Current",
    currentModel: keyFieldsValue?.value?.currentInsp?.ELINSPDONE,
    previousId: "checkbox_element_Previous",
    previousModel: keyFieldsValue?.value?.previousInsp?.ELINSPDONE,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  });
};

let requiredInspToCompare = ref({});
const setRequiredInspToCompare = () => {
  requiredInspToCompare.value = [];
  //NBI doesn't get an entry, since it's always required
  //Start with NSTM
  requiredInspToCompare.value.push({
    currentId: "checkbox_nstm_requiredCurrent",
    currentModel: keyFieldsValue?.value?.currentInsp?.FCINSPREQ,
    previousId: "checkbox_nstm_requiredPrevious",
    previousModel: keyFieldsValue?.value?.previousInsp?.FCINSPREQ,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  });
  requiredInspToCompare.value.push({
    currentId: "checkbox_uw_requiredCurrent",
    currentModel: keyFieldsValue?.value?.currentInsp?.UWINSPREQ,
    previousId: "checkbox_uw_requiredPrevious",
    previousModel: keyFieldsValue?.value?.previousInsp?.UWINSPREQ,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  });
  requiredInspToCompare.value.push({
    currentId: "checkbox_os_requiredCurrent",
    currentModel: keyFieldsValue?.value?.currentInsp?.OSINSPREQ,
    previousId: "checkbox_os_requiredPrevious",
    previousModel: keyFieldsValue?.value?.previousInsp?.OSINSPREQ,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  });
  requiredInspToCompare.value.push({
    currentId: "checkbox_element_requiredCurrent",
    currentModel: keyFieldsValue?.value?.currentInsp?.ELINSPREQ,
    previousId: "checkbox_element_requiredPrevious",
    previousModel: keyFieldsValue?.value?.previousInsp?.ELINSPREQ,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  });
};

let intervalsToCompare = ref({});
const setIntervalsToCompare = () => {
  intervalsToCompare.value = [];
  intervalsToCompare.value.push({
    currentInterval: keyFieldsValue?.value?.currentInsp?.BRINSPFREQ
      ? keyFieldsValue?.value?.currentInsp?.BRINSPFREQ + " mos"
      : "0 mos",
    previousInterval: keyFieldsValue?.value?.previousInsp?.BRINSPFREQ
      ? keyFieldsValue?.value?.previousInsp?.BRINSPFREQ + " mos"
      : "--",
    get differing() {
      return this.currentInterval == "0 mos" && this.previousInterval == "--"
        ? false
        : this.currentInterval != this.previousInterval;
    },
  });
  intervalsToCompare.value.push({
    currentInterval: keyFieldsValue?.value?.currentInsp?.FCINSPFREQ
      ? keyFieldsValue?.value?.currentInsp?.FCINSPFREQ + " mos"
      : "0 mos",
    previousInterval: keyFieldsValue?.value?.previousInsp?.FCINSPFREQ
      ? keyFieldsValue?.value?.previousInsp?.FCINSPFREQ + " mos"
      : "--",
    get differing() {
      return this.currentInterval == "0 mos" && this.previousInterval == "--"
        ? false
        : this.currentInterval != this.previousInterval;
    },
  });
  intervalsToCompare.value.push({
    currentInterval: keyFieldsValue?.value?.currentInsp?.UWINSPFREQ
      ? keyFieldsValue?.value?.currentInsp?.UWINSPFREQ + " mos"
      : "0 mos",
    previousInterval: keyFieldsValue?.value?.previousInsp?.UWINSPFREQ
      ? keyFieldsValue?.value?.previousInsp?.UWINSPFREQ + " mos"
      : "--",
    get differing() {
      return this.currentInterval == "0 mos" && this.previousInterval == "--"
        ? false
        : this.currentInterval != this.previousInterval;
    },
  });
  intervalsToCompare.value.push({
    currentInterval: keyFieldsValue?.value?.currentInsp?.OSINSPFREQ
      ? keyFieldsValue?.value?.currentInsp?.OSINSPFREQ + " mos"
      : "0 mos",
    previousInterval: keyFieldsValue?.value?.previousInsp?.OSINSPFREQ
      ? keyFieldsValue?.value?.previousInsp?.OSINSPFREQ + " mos"
      : "--",
    get differing() {
      return this.currentInterval == "0 mos" && this.previousInterval == "--"
        ? false
        : this.currentInterval != this.previousInterval;
    },
  });
  intervalsToCompare.value.push({
    currentInterval: keyFieldsValue?.value?.currentInsp?.ELINSPFREQ
      ? keyFieldsValue?.value?.currentInsp?.ELINSPFREQ + " mos"
      : "0 mos",
    previousInterval: keyFieldsValue?.value?.previousInsp?.ELINSPFREQ
      ? keyFieldsValue?.value?.previousInsp?.ELINSPFREQ + " mos"
      : "--",
    get differing() {
      return this.currentInterval == "0 mos" && this.previousInterval == "--"
        ? false
        : this.currentInterval != this.previousInterval;
    },
  });
};

const refreshKeyFieldsArrays = () => {
  setFieldsToCompare();
  setPerformedInspToCompare();
  setRequiredInspToCompare();
  setIntervalsToCompare();
};

watch(
  () => [props],
  () => {
    readData();
  },
  { deep: true }
);

watch(
  () => [keyFieldsValue],
  () => {
    refreshKeyFieldsArrays();
  },
  { deep: true }
);

defineExpose({
  readData,
});
</script>
<style scoped lang="scss">
@use "@/styles/general" as g;
@use "@/styles/colors" as c;

.boldFont {
  font-weight: bold !important;
}

.tableRowHeight {
  height: 40px;
}

.rightAlign {
  text-align: right;
  font-weight: bold !important;
}

.difference {
  background-color: c.$e-yellow;
}
</style>

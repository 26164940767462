<template>
  <v-row>
    <ExpandCollapseToggle id="ratingsPanelToggle" @toggleAllPanels="toggleAllPanels" styleClass="mt-3" :panel="panel" />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel style="margin-top: 20px" value="inspectionStatus">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="panel.includes('inspectionStatus')" icon="fa-solid fa-minus" />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>Inspection Status</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <LabelSelect
            :labelXl="2"
            :labelLg="3"
            :labelmd="4"
            :labelsm="5"
            :selectXl="3"
            :selectLg="4"
            :selectMd="5"
            :selectSm="6"
            id="inspectionStatus"
            label="1A09 Inspection Status"
            :refTable="REFERENCE_TABLE.INSPECTION_STATUS"
            :isEditable="isEditable"
            isRequired
            v-model="ratingsValue.INSPSTAT"
          ></LabelSelect>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="keyFields">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="panel.includes('keyFields')" icon="fa-solid fa-minus" />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>Key Field Comparison since Last Accepted Inspection</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <FieldComparison ref="childFieldComparison" :moduleType="fieldComparisonModuleMode"> </FieldComparison>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="conditionRatings">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="panel.includes('conditionRatings')" icon="fa-solid fa-minus" />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>Condition Ratings</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form v-model="validConditionRatings" ref="conditionRatingsForm">
            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.DK_DURATION, ratingsValue.DK_DURATION_CHANGES)"
                  @update:modelValue="
                    {
                      deckConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="deck"
                  label="1A01 Deck"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.DKRATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.SUB_DURATION, ratingsValue.SUB_DURATION_CHANGES)"
                  @update:modelValue="
                    {
                      substructureConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="substructure"
                  label="1A02 Substructure"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.SUBRATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.CULV_DURATION, ratingsValue.CULV_DURATION_CHANGES)"
                  @update:modelValue="
                    {
                      culvertConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="culvert"
                  label="1A03 Culvert"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.CULVRATING"
                ></LabelSelect>
              </v-col>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.SUP_DURATION, ratingsValue.SUP_DURATION_CHANGES)"
                  @update:modelValue="
                    {
                      superstructureConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="superStructure"
                  label="1A04 Superstructure"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.SUPRATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.CHANRATE_DURATION, ratingsValue.CHANRATE_DURATION_CHANGES)"
                  @update:modelValue="
                    {
                      channelConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="channel"
                  label="1A05 Channel"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.CHANRATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="channelProt"
                  label="1A05b Channel Protection"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.CHAN_PROT_RATING"
                ></LabelSelect>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="scourCond"
                  label="1A13/B.C.11 Scour"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.SCOUR_COND_RATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="waterway"
                  label="1A14/B.C.15 Waterway"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.UNDERWATER_RATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="nstmInspectionCondition"
                  label="1A15 NSTM Inspection Condition"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.NSTM_INSPECTION_CONDITION"
                  @update:modelValue="inspectionStore.setNstmInspectionConditionChangedByUser(true)"
                ></LabelSelect>
              </v-col>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="bearingRating"
                  label="IB01 Bearings"
                  :refTable="REFERENCE_TABLE.JOINT_CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.OVERALL_BEARINGS_RATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="jointRating"
                  label="IJ01 Joints"
                  :refTable="REFERENCE_TABLE.JOINT_CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.OVERALL_JOINTS_RATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="lowestConditionRating"
                  label="1A16 Lowest Condition Rating"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  v-model="ratingsValue.LOWEST_CONDITION_RATING"
                ></LabelSelect>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <LabelText
                  labelXl="12"
                  labelLg="12"
                  labelMd="12"
                  labelSm="12"
                  textXl="12"
                  textLg="12"
                  textMd="12"
                  textSm="12"
                  id="text_Inspection_Notes"
                  label="2A02 Inspection Notes"
                  :isEditable="isEditable"
                  v-model="ratingsValue.INSPECTION_NOTES"
                  :maxlength="notesMaxLength"
                  type="textarea"
                  counter
                  persistentCounter
                  class="pb-0"
                >
                </LabelText>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="appraisalRatings">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="panel.includes('appraisalRatings')" icon="fa-solid fa-minus" />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>Appraisal Ratings</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form v-model="validAppraisalRatings" ref="appraisalRatingsForm">
            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.RAILRATING_DURATION, ratingsValue.RAILRATING_DURATION_CHANGES)"
                  @update:modelValue="
                    {
                      approachRailingUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="railing"
                  label="IA02/B.C.05 Railing"
                  :refTable="REFERENCE_TABLE.RAILING_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.RAILRATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.TRANSRATIN_DURATION, ratingsValue.TRANSRATIN_DURATION_CHANGES)"
                  @update:modelValue="
                    {
                      approachTransitionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="transition"
                  label="IA02/B.C.06 Transition"
                  :refTable="REFERENCE_TABLE.TRANSITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.TRANSRATIN"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="pierProt"
                  label="4A07 Pier Protection"
                  :refTable="REFERENCE_TABLE.PIER_PROTECTION"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.PIER_PROTECTION"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="scourVulnerability"
                  label="IU29/B.AP.03 Scour Vulnerability"
                  :refTable="REFERENCE_TABLE.SCOUR_VULNERABILITY"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.SCOUR_VULNERABILITY"
                ></LabelSelect>
                <LabelCheckbox
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  id="newCoating"
                  class="labelCheckbox"
                  label="6B35 New Protective Coating?"
                  :showColon="false"
                  editIndent
                  :isEditable="isEditable"
                  v-model="ratingsValue.NEW_PAINT"
                >
                </LabelCheckbox>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.PAINT_DURATION, ratingsValue.PAINT_DURATION_CHANGES)"
                  @update:modelValue="
                    {
                      protectiveCoatingUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="protectiveCoatings"
                  label="6B36 Protective Coating"
                  :refTable="REFERENCE_TABLE.PAINT_CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.PAINT_COND_RATE"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.PAINT_EXT_DURATION, ratingsValue.PAINT_EXT_DURATION_CHANGES)"
                  @update:modelValue="
                    {
                      protectiveCoatingExtentUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="protectiveCoatingExtents"
                  label="6B37 Protective Coating (Extent)"
                  :refTable="REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.PAINT_EXTENT_RATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="select_Seismic_Vulnerability"
                  :isEditable="isEditable"
                  isRequired
                  label="4A26 Seismic Vulnerability"
                  :refTable="REFERENCE_TABLE.SEISMIC_VULNERABILITY"
                  v-model="seismicVulnerability"
                  @update:modelValue="inspectionStore.setSeismicVulnerabilityChangedByUser(true)"
                ></LabelSelect>
              </v-col>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.APPRALIGN_DURATION, ratingsValue.APPRALIGN_DURATION_CHANGES)"
                  @update:modelValue="
                    {
                      approachAlignmentUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="approachAlignments"
                  label="4A02 Approach Alignment"
                  :refTable="REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.APPRALIGN"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.APPR_SLAB_DURATION, ratingsValue.APPR_SLAB_DURATION_CHANGES)"
                  @update:modelValue="
                    {
                      approachSlabUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="approachSlabs"
                  label="6B38 Approach Slab"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.APPR_SLAB_COND_RATE"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(ratingsValue.APPR_ROADWAY_DURATION, ratingsValue.APPR_ROADWAY_DURATION_CHANGES)
                  "
                  @update:modelValue="
                    {
                      approachRoadwayUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="approachRoadways"
                  label="6B39 Approach Roadway"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.APPR_ROAD_COND_RATE"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(ratingsValue.DECK_WS_SURF_DURATION, ratingsValue.DECK_WS_SURF_DURATION_CHANGES)
                  "
                  @update:modelValue="
                    {
                      deckWearingSurfaceUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="deckWearingSurfaces"
                  label="6B40 Deck Wearing Surface"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="ratingsValue.DECK_WS_COND_RATE"
                ></LabelSelect>
                <LabelSelect
                  :class="isEditable ? 'py-1' : ''"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="scbi"
                  :isEditable="false"
                  label="4A08 SCBI"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.SCOUTCRIT_DURATION, ratingsValue.SCOUTCRIT_DURATION_CHANGES)"
                  :refTable="REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR"
                  v-model="ratingsValue.SCOURCRIT"
                ></LabelSelect>
                <LabelText
                  :class="isEditable ? 'py-1' : ''"
                  :labelXl="5"
                  :labelLg="5"
                  :labelMd="6"
                  :labelSm="6"
                  :textXl="6"
                  :textLg="5"
                  :textMd="4"
                  :textSm="3"
                  id="scbiCat"
                  :isEditable="false"
                  label="4A08b SCBI Category"
                  v-model="ratingsValue.SCOUR_CRIT_CTGRY"
                ></LabelText>
                <LabelSelect
                  :class="isEditable ? 'py-1' : ''"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="bridgeCondition"
                  :isEditable="false"
                  label="4A14 Bridge Condition"
                  showDurationIcon
                  :tooltip="getHistDuration(ratingsValue.BRGCOND_DURATION)"
                  :refTable="REFERENCE_TABLE.BRIDGE_CONDITION_RATING"
                  v-model="ratingsValue.BRIDGE_CONDITION_RATING"
                ></LabelSelect>
                <LabelSelect
                  :class="isEditable ? 'py-1' : ''"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="deficiencyScore"
                  :isEditable="false"
                  label="6B46 Deficiency Score"
                  v-model="ratingsValue.MAINT_DEF_RATE"
                ></LabelSelect>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { getHistDuration } from "@/composables/util";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import LabelText from "@/components/common/LabelText.vue";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import FieldComparison from "@/components/shared/FieldComparison.vue";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";
import { MODULE_TYPES } from "@/constants/InspectionConstants";
import { useRatingsStore } from "@/components/inspection/store/ratings";
import { useQuickSearchStore } from "@/stores/quickSearch";
import { storeToRefs } from "pinia";
import { useUserPermissionStore } from "@/stores/userPermission";
import { INSPECTION_LINKS_RATINGS_PERMISSIONS } from "@/rbac";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";
import { useInspectionLinksStore } from "@/stores/inspectionLinks";

export default {
  name: "InspectionRatings",
  props: {
    moduleType: { default: MODULE_TYPES.INSPECTION },
  },
  setup(props) {
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();
    const ratingsStore = useRatingsStore();
    const quickSearchStore = useQuickSearchStore();
    const structureHeader = computed(() => quickSearchStore.getStructure());
    const { hasQuickSearchSucceeded } = storeToRefs(quickSearchStore);
    const userPermissionStore = useUserPermissionStore();
    const inspectionLinksStore = useInspectionLinksStore();
    const childFieldComparison = ref(null);
    let ratingsValue = ref({});

    let resetDirty = ref(false);
    const isEditable = computed(() => {
      if (props.moduleType == MODULE_TYPES.MANAGEMENT) {
        return !quickSearchStore.getErrorMessage() && ratingsValue?.value?.BRKEY && hasAccessToEdit.value;
      } else {
        return inspectionStore.isEditable;
      }
    });

    const fieldComparisonModuleMode = computed(() => {
      return props.moduleType;
    });

    const hasAccessToEdit = computed(() =>
      userPermissionStore.checkUserPermission(
        INSPECTION_LINKS_RATINGS_PERMISSIONS[ACCESS_LEVELS.EDIT],
        userPermissionStore.getUserPermissionByBrkeyFromCache(ratingsValue.value.BRKEY)
      )
    );

    let structure = computed(() => inspectionStore.selectedInspection);
    const allPanels = ["inspectionStatus", "keyFields", "conditionRatings", "appraisalRatings"];
    const panel = ref(allPanels);
    let validConditionRatings = ref(true);
    let validAppraisalRatings = ref(true);
    let valid = ref(true);
    let conditionRatingsForm = ref(null);
    let appraisalRatingsForm = ref(null);

    const readData = async () => {
      ratingsValue.value = {};
      if (props.moduleType == MODULE_TYPES.MANAGEMENT && structureHeader.value?.BRKEY) {
        if (!hasQuickSearchSucceeded.value) {
          resetDirty.value = true;
          return;
        }
        await ratingsStore.loadDataObject(
          props.moduleType,
          structureHeader.value.BRKEY,
          structureHeader.value.INSPECTIONID
        );
        ratingsValue.value = ratingsStore.getRatingsObject();
        await childFieldComparison?.value?.readData();
        inspectionLinksStore.isDirty = false;
      } else if (props.moduleType == MODULE_TYPES.INSPECTION) {
        const structure = computed(() => inspectionStore.selectedInspection);
        await ratingsStore.loadDataObject(props.moduleType, null, null, structure.value);
        ratingsValue.value = ratingsStore.getRatingsObject();
        await childFieldComparison?.value?.readData();
        inspectionStore.setDirtyFlag(false);
      }
    };

    async function validate() {
      await conditionRatingsForm.value.validate();
      await appraisalRatingsForm.value.validate();
      if (validConditionRatings.value && validAppraisalRatings.value) {
        valid.value = true;
      } else {
        valid.value = false;
      }
    }

    if (!isEditable.value) {
      panel.value = allPanels;
    }
    onMounted(async () => {
      await validate();
      await readData();
      inspectionLinksStore.isDirty = false;
      inspectionStore.setDirtyFlag(false);
    });
    const conditionRatings = computed(() => configStore.getReferenceValues(REFERENCE_TABLE.CONDITION_RATING));
    const railingRatings = computed(() => configStore.getReferenceValues(REFERENCE_TABLE.RAILING_RATING));
    const transitionRatings = computed(() => configStore.getReferenceValues(REFERENCE_TABLE.TRANSITION_RATING));
    const approachGuideRailRatings = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.APPROACH_GUIDE_RAIL_RATING)
    );
    const approachRailEndRatings = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.APPROACH_RAIL_END_RATING)
    );
    const protectiveCoatings = computed(() => configStore.getReferenceValues(REFERENCE_TABLE.PAINT_CONDITION_RATING));
    const protectiveCoatingExtents = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT)
    );
    const approachAlignments = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL)
    );

    function getNSTMInspectionCondition() {
      if (ratingsValue.value.MAIN_FC_GROUP_NUM < 5 || ratingsValue.value.APPR_FC_GROUP_NUM < 5) {
        return ratingsValue.value.SUPRATING;
      } else {
        return "N";
      }
    }

    function setLowestConditionRating() {
      if (parseInt(ratingsValue.value.LOWEST_CONDITION) >= 7) {
        ratingsValue.value.CONDITION = "G";
      } else if (ratingsValue.value.LOWEST_CONDITION == "5" || ratingsValue.value.LOWEST_CONDITION == "6") {
        ratingsValue.value.CONDITION = "F";
      } else if (parseInt(ratingsValue.value.LOWEST_CONDITION) <= 4) {
        ratingsValue.value.CONDITION = "P";
      } else if (ratingsValue.value.LOWEST_CONDITION == "N") {
        ratingsValue.value.CONDITION = "N";
      }
    }

    const lowestConditionRating = computed({
      get() {
        return ratingsValue.value.LOWEST_CONDITION;
      },
    });

    const bridgeCondition = computed({
      get() {
        setLowestConditionRating();
        return ratingsValue.value.CONDITION;
      },
    });

    const nstmInspectionConditionChangedByUser = computed(
      () => inspectionStore.getNstmInspectionConditionChangedByUser
    );

    const nstmInspectionCondition = computed({
      get() {
        if (!ratingsValue.value.NSTM_CONDITION && !nstmInspectionConditionChangedByUser.value) {
          return getNSTMInspectionCondition();
        } else {
          return ratingsValue.value.NSTM_CONDITION;
        }
      },
      set(inspectionConditionParam) {
        ratingsValue.value.NSTM_CONDITION = inspectionConditionParam;
      },
    });
    let seismicVulnerabilityChangedByUser = ref(false);

    const seismicVulnerability = computed({
      get() {
        if (!ratingsValue.value.SEISMIC_VULN && !seismicVulnerabilityChangedByUser.value) {
          return "0";
        } else {
          return ratingsValue.value.SEISMIC_VULN;
        }
      },
      set(seismicVulnerabilityParam) {
        ratingsValue.value.SEISMIC_VULN = seismicVulnerabilityParam;
      },
    });

    const saveConditionFields = async () => {
      ratingsValue.value.NSTM_CONDITION = nstmInspectionCondition.value;
    };

    const resetChangedByUser = async () => {
      inspectionStore.setNstmInspectionConditionChangedByUser(false);
      inspectionStore.setSeismicVulnerabilityChangedByUser(false);
    };

    const notesMaxLength = computed(() => {
      return configStore.getEnvConfigValue(ENV_CONFIG_PROPERTY.STRUCTURE_NOTES_MAX_LENGTH);
    });

    let deckConditionUpdated = ref(false);
    let superstructureConditionUpdated = ref(false);
    let substructureConditionUpdated = ref(false);
    let channelConditionUpdated = ref(false);
    let culvertConditionUpdated = ref(false);
    let waterwayConditionUpdated = ref(false);
    let conditionUpdated = ref(false);

    const updateConditionRatings = () => {
      if (!ratingsValue.value.T_Insp_Condition_Hist) {
        ratingsValue.value.T_Insp_Condition_Hist = {};
      }
      if (deckConditionUpdated.value) {
        ratingsValue.value.DK_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.DK_DURATION_CHANGES
        );
        ratingsValue.value.DK_DURATION = inspectionStore.setDuration(
          ratingsValue.value.DK_DURATION,
          ratingsValue.value.DK_DURATION_CHANGES
        );
      }
      if (superstructureConditionUpdated.value) {
        ratingsValue.value.SUP_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.SUP_DURATION_CHANGES
        );
        ratingsValue.value.SUP_DURATION = inspectionStore.setDuration(
          ratingsValue.value.SUP_DURATION,
          ratingsValue.value.SUP_DURATION_CHANGES
        );
      }
      if (substructureConditionUpdated.value) {
        ratingsValue.value.SUB_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.SUB_DURATION_CHANGES
        );
        ratingsValue.value.SUB_DURATION = inspectionStore.setDuration(
          ratingsValue.value.SUB_DURATION,
          ratingsValue.value.SUB_DURATION_CHANGES
        );
      }
      if (channelConditionUpdated.value) {
        ratingsValue.value.CHANRATE_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.CHANRATE_DURATION_CHANGES
        );
        ratingsValue.value.CHANRATE_DURATION = inspectionStore.setDuration(
          ratingsValue.value.CHANRATE_DURATION,
          ratingsValue.value.CHANRATE_DURATION_CHANGES
        );
      }
      if (culvertConditionUpdated.value) {
        ratingsValue.value.CULV_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.CULV_DURATION_CHANGES
        );
        ratingsValue.value.CULV_DURATION = inspectionStore.setDuration(
          ratingsValue.value.CULV_DURATION,
          ratingsValue.value.CULV_DURATION_CHANGES
        );
      }
      if (waterwayConditionUpdated.value) {
        ratingsValue.value.WADEQ_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.WADEQ_DURATION_CHANGES
        );
        ratingsValue.value.WADEQ_DURATION = inspectionStore.setDuration(
          ratingsValue.value.WADEQ_DURATION,
          ratingsValue.value.WADEQ_DURATION_CHANGES
        );
      }
      deckConditionUpdated.value = false;
      superstructureConditionUpdated.value = false;
      substructureConditionUpdated.value = false;
      channelConditionUpdated.value = false;
      culvertConditionUpdated.value = false;
      waterwayConditionUpdated.value = false;
    };

    let approachAlignmentUpdated = ref(false);
    let approachRoadwayUpdated = ref(false);
    let approachSlabUpdated = ref(false);
    let approachRailingUpdated = ref(false);
    let approachTransitionUpdated = ref(false);
    let approachGuideRailUpdated = ref(false);
    let approachRailEndUpdated = ref(false);

    let deckGeometryUpdated = ref(false);

    let underclearanceUpdated = ref(false);

    let protectiveCoatingUpdated = ref(false);

    let deckWearingSurfaceUpdated = ref(false);

    let protectiveCoatingExtentUpdated = ref(false);

    const updateAppraisalRatings = () => {
      if (!ratingsValue.value.T_Insp_Condition_Hist) {
        ratingsValue.value.T_Insp_Condition_Hist = {};
      }
      if (approachAlignmentUpdated.value) {
        ratingsValue.value.APPRALIGN_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.APPRALIGN_DURATION_CHANGES
        );
        ratingsValue.value.APPRALIGN_DURATION = inspectionStore.setDuration(
          ratingsValue.value.APPRALIGN_DURATION,
          ratingsValue.value.APPRALIGN_DURATION_CHANGES
        );
      }
      if (approachRoadwayUpdated.value) {
        ratingsValue.value.APPR_ROADWAY_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.APPR_ROADWAY_DURATION_CHANGES
        );
        ratingsValue.value.APPR_ROADWAY_DURATION = inspectionStore.setDuration(
          ratingsValue.value.APPR_ROADWAY_DURATION,
          ratingsValue.value.APPR_ROADWAY_DURATION_CHANGES
        );
      }
      if (approachSlabUpdated.value) {
        ratingsValue.value.APPR_SLAB_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.APPR_SLAB_DURATION_CHANGES
        );
        ratingsValue.value.APPR_SLAB_DURATION = inspectionStore.setDuration(
          ratingsValue.value.APPR_SLAB_DURATION,
          ratingsValue.value.APPR_SLAB_DURATION_CHANGES
        );
      }
      if (approachRailingUpdated.value) {
        ratingsValue.value.RAILRATING_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.RAILRATING_DURATION_CHANGES
        );
        ratingsValue.value.RAILRATING_DURATION = inspectionStore.setDuration(
          ratingsValue.value.RAILRATING_DURATION,
          ratingsValue.value.RAILRATING_DURATION_CHANGES
        );
      }
      if (approachTransitionUpdated.value) {
        ratingsValue.value.TRANSRATIN_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.TRANSRATIN_DURATION_CHANGES
        );
        ratingsValue.value.TRANSRATIN_DURATION = inspectionStore.setDuration(
          ratingsValue.value.TRANSRATIN_DURATION,
          ratingsValue.value.TRANSRATIN_DURATION_CHANGES
        );
      }
      if (approachGuideRailUpdated.value) {
        ratingsValue.value.ARAILRATIN_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.ARAILRATIN_DURATION_CHANGES
        );
        ratingsValue.value.ARAILRATIN_DURATION = inspectionStore.setDuration(
          ratingsValue.value.ARAILRATIN_DURATION,
          ratingsValue.value.ARAILRATIN_DURATION_CHANGES
        );
      }
      if (approachRailEndUpdated.value) {
        ratingsValue.value.AEBDRATING_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.AEBDRATING_DURATION_CHANGES
        );
        ratingsValue.value.AEBDRATING_DURATION = inspectionStore.setDuration(
          ratingsValue.value.AEBDRATING_DURATION,
          ratingsValue.value.AEBDRATING_DURATION_CHANGES
        );
      }

      if (deckGeometryUpdated.value) {
        ratingsValue.value.DECKGEOM_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.DECKGEOM_DURATION_CHANGES
        );
        ratingsValue.value.DECKGEOM_DURATION = inspectionStore.setDuration(
          ratingsValue.value.DECKGEOM_DURATION,
          ratingsValue.value.DECKGEOM_DURATION_CHANGES
        );
      }

      if (underclearanceUpdated.value) {
        ratingsValue.value.UNDERCLR_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.UNDERCLR_DURATION_CHANGES
        );
        ratingsValue.value.UNDERCLR_DURATION = inspectionStore.setDuration(
          ratingsValue.value.UNDERCLR_DURATION,
          ratingsValue.value.UNDERCLR_DURATION_CHANGES
        );
      }

      if (protectiveCoatingUpdated.value) {
        ratingsValue.value.PANIT_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.PANIT_DURATION_CHANGES
        );
        ratingsValue.value.PANIT_DURATION = inspectionStore.setDuration(
          ratingsValue.value.PANIT_DURATION,
          ratingsValue.value.PANIT_DURATION_CHANGES
        );
      }

      if (deckWearingSurfaceUpdated.value) {
        ratingsValue.value.DECK_WS_SURF_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.DECK_WS_SURF_DURATION_CHANGES
        );
        ratingsValue.value.DECK_WS_SURF_DURATION = inspectionStore.setDuration(
          ratingsValue.value.DECK_WS_SURF_DURATION,
          ratingsValue.value.DECK_WS_SURF_DURATION_CHANGES
        );
      }

      if (protectiveCoatingExtentUpdated.value) {
        ratingsValue.value.PAINT_EXT_DURATION_CHANGES = inspectionStore.setDurationChanges(
          ratingsValue.value.PAINT_EXT_DURATION_CHANGES
        );
        ratingsValue.value.PAINT_EXT_DURATION = inspectionStore.setDuration(
          ratingsValue.value.PAINT_EXT_DURATION,
          ratingsValue.value.PAINT_EXT_DURATION_CHANGES
        );
      }

      deckGeometryUpdated.value = false;

      underclearanceUpdated.value = false;

      protectiveCoatingUpdated.value = false;

      deckWearingSurfaceUpdated.value = false;

      protectiveCoatingExtentUpdated.value = false;

      approachRailEndUpdated.value = false;
      approachGuideRailUpdated.value = false;
      approachTransitionUpdated.value = false;
      approachAlignmentUpdated.value = false;
      approachRailingUpdated.value = false;
      approachRoadwayUpdated.value = false;
      approachSlabUpdated.value = false;
    };
    const updateDuration = () => {
      updateConditionRatings();
      updateAppraisalRatings();
      conditionUpdated.value = false;
    };
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    const saveChanges = async () => {
      const structure = inspectionStore.selectedInspection;
      const isSuccess = await ratingsStore.saveData(props.moduleType, ratingsValue.value, structure);
      if (isSuccess) {
        if (props.moduleType == MODULE_TYPES.INSPECTION) {
          const structure = computed(() => inspectionStore.selectedInspection);
          await ratingsStore.loadDataObject(props.moduleType, null, null, structure.value);
        }
        await childFieldComparison?.value?.readData();
        ratingsValue.value = ratingsStore.getRatingsObject();
        // Using this flag to not set the dirty flag in watch
        resetDirty.value = true;
        inspectionLinksStore.isDirty = false;
      }
      return isSuccess;
    };

    const cancelChanges = async () => {
      //only called from Inspection Links
      ratingsValue.value = ratingsStore.getRatingsObject();
      // Using this flag to not set the dirty flag in watch
      resetDirty.value = true;
      inspectionLinksStore.isDirty = false;
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [ratingsValue],
      () => {
        // This watch will NOT be triggered during initial onMounted()
        if (props.moduleType === MODULE_TYPES.MANAGEMENT) {
          // for management module
          if (resetDirty.value) {
            inspectionLinksStore.isDirty = false;
            resetDirty.value = false;
          } else {
            inspectionLinksStore.isDirty = true;
          }
        } else if (props.moduleType === MODULE_TYPES.INSPECTION) {
          // for inspection  module
          if (resetDirty.value) {
            inspectionStore.setDirtyFlag(false);
            resetDirty.value = false;
          } else {
            inspectionStore.setDirtyFlag(true);
          }
        }
      },
      { deep: true }
    );

    watch(
      () => [props],
      () => {
        readData();
      },
      { deep: true }
    );

    return {
      inspectionStore,
      underclearanceUpdated,
      protectiveCoatingExtentUpdated,
      deckWearingSurfaceUpdated,
      protectiveCoatingUpdated,
      deckGeometryUpdated,
      approachSlabUpdated,
      approachRailEndUpdated,
      approachGuideRailUpdated,
      approachTransitionUpdated,
      approachRailingUpdated,
      approachRoadwayUpdated,
      updateDuration,
      approachAlignmentUpdated,
      updateConditionRatings,
      waterwayConditionUpdated,
      culvertConditionUpdated,
      channelConditionUpdated,
      substructureConditionUpdated,
      superstructureConditionUpdated,
      deckConditionUpdated,
      conditionUpdated,
      resetChangedByUser,
      nstmInspectionConditionChangedByUser,
      seismicVulnerabilityChangedByUser,
      bridgeCondition,
      seismicVulnerability,
      saveConditionFields,
      nstmInspectionCondition,
      lowestConditionRating,
      structure,
      REFERENCE_TABLE,
      conditionRatings,
      railingRatings,
      transitionRatings,
      approachGuideRailRatings,
      approachRailEndRatings,
      protectiveCoatings,
      protectiveCoatingExtents,
      approachAlignments,
      getHistDuration,
      panel,
      isEditable,
      notesMaxLength,
      conditionRatingsForm,
      appraisalRatingsForm,
      validate,
      valid,
      validConditionRatings,
      validAppraisalRatings,
      toggleAllPanels,
      MODULE_TYPES,
      ratingsValue,
      readData,
      saveChanges,
      cancelChanges,
      fieldComparisonModuleMode,
      childFieldComparison,
    };
  },
  components: {
    LabelSelect,
    LabelCheckbox,
    LabelText,
    FieldComparison,
    ExpandCollapseToggle,
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

h3 {
  order: 1;
  color: c.$p-white !important;
}
</style>

import { defineStore } from "pinia";
import { ref } from "vue";
import { INVENTORY_ENDPOINT, REPORTS_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import { trimValues } from "@/composables/util";
import { downloadItem } from "@/util/downloadFile";

export const useInventoryStore = defineStore("inventory", () => {
  let isDirty = ref(false);
  let pageReference = ref(null);
  let isSearching = ref(false);
  let isSaving = ref(false);
  let isPrinting = ref(false);
  let structureIdentification = ref({});
  let isAddingOrEditingStructureIdentification = ref(false);
  let isAddingOrEditingStructureComposition = ref(false);
  let isAddingOrEditingFeatures = ref(false);
  let isAddingOrEditingInspectionPlanning = ref(false);
  let isEditingSignAndLight = ref(false);
  let isAddingOrEditingLoadRatingAndPosting = ref(false);
  let isAddingOrEditingPreservationRehab = ref(false);
  let isAddingOrEditingPreservationRehabBearing = ref(false);
  let isAddingOrEditingPreservationRehabContractor = ref(false);
  let isAddingOrEditingPreservationRehabJoint = ref(false);
  //For pages with one expansion panel
  let isAddingOrEditing = ref(false);

  let showValidationSnackbar = ref(false);
  let structureComposition = ref({
    bridgeGeometryDTO: {},
    legacyDataDTO: {},
    designInformationDTO: {},
    paintDTOs: [],
    structureSpanDTOs: [],
    structureSpanSetDTOs: [],
    structureSubstructureUnitDTOs: [],
    structureSubstructureUnitSetDTOs: [],
  });
  let inventoryFeatures = ref({});
  let inspectionPlanning = ref({});
  let preservationRehab = ref({});

  const getIsDirty = () => {
    return isDirty.value;
  };
  const getPageReference = () => {
    return pageReference.value;
  };
  const getIsSearching = () => {
    return isSearching.value;
  };
  const getIsSaving = () => {
    return isSaving.value;
  };
  const getIsAddingPreservationRehabProjectRecordSubTable = () => {
    return (
      isAddingOrEditingPreservationRehabBearing.value ||
      isAddingOrEditingPreservationRehabContractor.value ||
      isAddingOrEditingPreservationRehabJoint.value
    );
  };
  const setIsDirty = (value) => {
    isDirty.value = value;
  };
  const setPageReference = (value) => {
    pageReference.value = value;
  };
  const setIsSearching = (value) => {
    isSearching.value = value;
  };
  const setIsSaving = (value) => {
    isSaving.value = value;
  };
  const resetIsAddingPreservationRehabProjectRecordSubTable = () => {
    isAddingOrEditingPreservationRehabBearing.value = false;
    isAddingOrEditingPreservationRehabContractor.value = false;
    isAddingOrEditingPreservationRehabJoint.value = false;
  };

  const getRiskScoreByBrKey = async (brKey) => {
    let url = INVENTORY_ENDPOINT.GET_RISK_SCORE_BY_BRKEY.replace("{brkey}", brKey);
    isSearching.value = true;
    return await axios
      .get(url)
      .then((response) => {
        isSearching.value = false;
        return response.data.data;
      })
      .catch((e) => {
        isSearching.value = false;
        LOGGER.logException(e);
      });
  };

  const updateRiskAssessmentComment = async (thinRiskScore) => {
    const url = INVENTORY_ENDPOINT.UPDATE_RISK_ASSESSMENT_COMMENT;
    let isSuccess = true;
    await axios
      .put(url, thinRiskScore)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Update,
          LOGGER.EventCat.RequestResponse,
          "Update RiskAssessmentComment",
          response
        );
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const loadInspectionPlanningByBrKey = async (brKey) => {
    let isSuccess = true;
    let url = INVENTORY_ENDPOINT.GET_INSPECTION_PLANNING_BY_BRKEY.replace("{brkey}", brKey);
    isSearching.value = true;
    await axios
      .get(url)
      .then((response) => {
        isSearching.value = false;
        inspectionPlanning.value = response.data.data;
      })
      .catch((e) => {
        isSearching.value = false;
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const updateInspectionPlanning = async (inspectionPlanningData) => {
    const url = INVENTORY_ENDPOINT.UPDATE_INSPECTION_PLANNING;
    let isSuccess = true;
    await axios
      .put(url, inspectionPlanningData)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Update,
          LOGGER.EventCat.RequestResponse,
          "Update InspectionPlanning",
          response
        );
        inspectionPlanning.value = response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const loadStructureIdentificationByBrKey = async (brKey) => {
    let url = INVENTORY_ENDPOINT.GET_STRUCTURE_IDENTIFICATION_BY_BRKEY.replace("{brkey}", brKey);
    isSearching.value = true;
    return await axios
      .get(url)
      .then((response) => {
        isSearching.value = false;
        structureIdentification.value = trimValues(response.data.data);
      })
      .catch((e) => {
        structureIdentification.value = {};
        isSearching.value = false;
        LOGGER.logException(e);
      });
  };

  const getThinStructureIdentification = async (brkey) => {
    let url = INVENTORY_ENDPOINT.GET_THIN_STRUCTURE_IDENTIFICATION_BY_BRKEY.replace("{brkey}", brkey);
    isSearching.value = true;
    return await axios
      .get(url)
      .then((response) => {
        isSearching.value = false;
        return response.data.data;
      })
      .catch((e) => {
        isSearching.value = false;
        LOGGER.logException(e);
      });
  };

  const updateStructureIdentification = async (identificationData) => {
    let isSuccess = true;
    const url = INVENTORY_ENDPOINT.UPDATE_STRUCTURE_IDENTIFICATION;
    await axios
      .put(url, identificationData)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Update,
          LOGGER.EventCat.RequestResponse,
          "Update Structure Identification",
          response
        );
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const loadStructureCompositionByBrKey = async (brKey) => {
    let url = INVENTORY_ENDPOINT.GET_STRUCTURE_COMPOSITION_BY_BRKEY.replace("{brkey}", brKey);
    isSearching.value = true;
    return await axios
      .get(url)
      .then((response) => {
        isSearching.value = false;
        structureComposition.value = trimValues(response.data.data);
      })
      .catch((e) => {
        isSearching.value = false;
        LOGGER.logException(e);
      });
  };

  const updateStructureComposition = async (compositionData) => {
    let isSuccess = true;
    const url = INVENTORY_ENDPOINT.UPDATE_STRUCTURE_COMPOSITION;
    await axios
      .put(url, compositionData)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Update,
          LOGGER.EventCat.RequestResponse,
          "Update Structure Composition",
          response
        );
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const loadFeaturesByBrKey = async (brKey) => {
    let isSuccess = true;
    let url = INVENTORY_ENDPOINT.GET_FEATURES_BY_BRKEY.replace("{brkey}", brKey);
    isSearching.value = true;
    await axios
      .get(url)
      .then((response) => {
        isSearching.value = false;
        inventoryFeatures.value = response.data.data;
      })
      .catch((e) => {
        isSearching.value = false;
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const updateFeatures = async (featuresData) => {
    let isSuccess = true;
    const url = INVENTORY_ENDPOINT.UPDATE_FEATURES;
    await axios
      .put(url, featuresData)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Update,
          LOGGER.EventCat.RequestResponse,
          "Update Inventory Features",
          response
        );
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const downloadReport = async (reportId, request, reportFileName) => {
    let isSuccess = false;
    if (!reportId) {
      console.error("ReportCode/Id can't be null");
      return;
    }
    isPrinting.value = true;
    let url = REPORTS_ENDPOINT.EXPORT_REPORT.replace("{reportId}", reportId);
    try {
      await axios.post(url, request).then((response) => {
        if (response?.data?.data?.fileContent?.length > 0) {
          isSuccess = true;
          downloadItem({
            fileName: reportFileName,
            fileType: response.data.data.fileType ?? "application/pdf",
            content: response.data.data.fileContent,
          });
        } else {
          isSuccess = false;
          LOGGER.logEvent("Download", LOGGER.EventCat.RequestResponse, "Download Report", response?.data);
        }
      });
    } catch (e) {
      LOGGER.logException(e);
    }
    isPrinting.value = false;
    return isSuccess;
  };

  const loadPreservationRehabByBrKey = async (brKey) => {
    let isSuccess = false;
    let url = INVENTORY_ENDPOINT.GET_PRESERVATION_AND_REHAB.replace("{brkey}", brKey);
    isSearching.value = true;
    await axios
      .get(url)
      .then((resp) => {
        isSearching.value = false;
        isSuccess = true;
        preservationRehab.value = resp.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSearching.value = false;
        isSuccess = false;
      });
    return isSuccess;
  };

  const updatePreservationAndRehab = async (item) => {
    let isSuccess = false;
    let url = INVENTORY_ENDPOINT.UPDATE_PRESERVATION_AND_REHAB;
    isSearching.value = true;
    await axios
      .put(url, item)
      .then((resp) => {
        isSearching.value = false;
        isSuccess = true;
        preservationRehab.value = resp.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSearching.value = false;
        isSuccess = false;
      });
    return isSuccess;
  };

  const validateWorkPerfromed = async (prId, wtId, workPerformed) => {
    let url = INVENTORY_ENDPOINT.VALIDATE_PRESERVATION_AND_REHAB_WORK_PERFORMED;
    return await axios
      .post(url, workPerformed, {
        params: { prId: prId, wtId: wtId },
      })
      .then((resp) => {
        return resp.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const isValidCrossingId = async (crossingId) => {
    let isValid = true;
    let url = INVENTORY_ENDPOINT.VALIDATE_CROSSING_ID.replace("{crossingId}", crossingId);
    await axios.get(url).catch((e) => {
      isValid = false;
      LOGGER.logException(e);
    });
    return isValid;
  };

  const getCrossingDetails = async (crossingId) => {
    let url = INVENTORY_ENDPOINT.GET_CROSSING_DETAILS.replace("{crossingId}", crossingId);
    isSearching.value = true;
    return await axios
      .get(url)
      .then((resp) => {
        isSearching.value = false;
        return resp.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSearching.value = false;
      });
  };

  return {
    updatePreservationAndRehab,
    validateWorkPerfromed,
    isAddingOrEditingInspectionPlanning,
    updateInspectionPlanning,
    loadInspectionPlanningByBrKey,
    inspectionPlanning,
    updateRiskAssessmentComment,
    getRiskScoreByBrKey,
    getIsSaving,
    setIsSaving,
    setIsDirty,
    getIsDirty,
    setPageReference,
    getPageReference,
    getIsSearching,
    setIsSearching,
    loadStructureIdentificationByBrKey,
    structureIdentification,
    updateStructureIdentification,
    isAddingOrEditingStructureIdentification,
    getThinStructureIdentification,
    showValidationSnackbar,
    isAddingOrEditingStructureComposition,
    loadStructureCompositionByBrKey,
    structureComposition,
    updateStructureComposition,
    isAddingOrEditingFeatures,
    isEditingSignAndLight,
    isAddingOrEditingLoadRatingAndPosting,
    loadFeaturesByBrKey,
    updateFeatures,
    inventoryFeatures,
    downloadReport,
    isPrinting,
    isAddingOrEditingPreservationRehab,
    loadPreservationRehabByBrKey,
    preservationRehab,
    isValidCrossingId,
    getCrossingDetails,
    isAddingOrEditingPreservationRehabJoint,
    isAddingOrEditingPreservationRehabContractor,
    isAddingOrEditingPreservationRehabBearing,
    getIsAddingPreservationRehabProjectRecordSubTable,
    resetIsAddingPreservationRehabProjectRecordSubTable,
    isAddingOrEditing,
  };
});

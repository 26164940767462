import { defineStore } from "pinia";
import { ref } from "vue";
import { INSPECTION_LINKS_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import { clone } from "@/util/clone";
import { MODULE_TYPES } from "@/constants/InspectionConstants";
import { useInspectionLinksStore } from "@/stores/inspectionLinks";

export const useDeckStore = defineStore("deck", () => {
  //deck data is loaded either from idb(pouch db for inspection module) or service(for management module)
  let deckData = ref({});
  const inspectionLinksStore = useInspectionLinksStore();

  const loadDeckObject = async (moduleType, brkey, inspectionId, structure) => {
    if (moduleType === MODULE_TYPES.INSPECTION) {
      //if moduleType is inspection, load data from pouchDB
      convertToInspModuleObject(structure);
    } else if (moduleType === MODULE_TYPES.MANAGEMENT) {
      //if moduleType is management, load data from service call
      await getInspLinkDeck(brkey, inspectionId);
    }
  };

  const getDeckObject = () => {
    return clone(deckData.value);
  };

  const saveDeck = async (moduleType, deckValue, structure) => {
    let isSuccess = false;
    if (moduleType === MODULE_TYPES.INSPECTION) {
      convertToSaveInspModuleObject(deckValue, structure);
      isSuccess = true;
    } else if (moduleType === MODULE_TYPES.MANAGEMENT) {
      isSuccess = await updateDeck(convertToSaveInspLinkObject(deckValue));
    }
    return isSuccess;
  };

  const updateDeck = async (deck) => {
    let isSuccess = true;
    const url = INSPECTION_LINKS_ENDPOINT.UPDATE_DECK;
    await axios
      .put(url, deck)
      .then((response) => {
        LOGGER.logEvent(LOGGER.EventAction.Update, LOGGER.EventCat.RequestResponse, "Update Deck", response);
        convertToInspLinkObject(response.data.data);
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const convertToSaveInspLinkObject = (deck) => {
    return {
      brkey: deck.BRKEY,
      inspectionId: deck.INSPECTIONID,
      dkDuration: deck.DK_DURATION,
      dkRating: deck.DKRATING,
      deckWsSurfDuration: deck.DECK_WS_SURF_DURATION,
      deckWsCondRate: deck.DECK_WS_COND_RATE,
      dkSurfType: deck.DKSURFTYPE,
      dkMembTyp: deck.DKMEMBTYPE,
      dkProtect: deck.DKPROTECT,
      mainWsThickness: deck.MAIN_WS_THICKNESS,
      apprWsThickness: deck.APPR_WS_THICKNESS,
      mainWsThickDate: deck.MAIN_WS_THICK_DATE,
      apprWsThickDate: deck.APPR_WS_THICK_DATE,
      protectYear: deck.PROTECT_YEAR,
      protectNote: deck.PROTECT_NOTE,
      deckCrackingMetric: deck.DECK_CRACKING_METRIC,
      maxStructureHeight: deck.MAX_STRUCTURE_HEIGHT,
      dkStrucType: deck.DKSTRUCTYP,
      deckWidth: deck.DECKWIDTH,
      lftCurbSw: deck.LFTCURBSW,
      rtCurbSw: deck.RTCURBSW,
      bridgeMed: deck.BRIDGEMED,
      inspectionComments: deck.inspectionComments,
    };
  };

  const convertToSaveInspModuleObject = (deckValue, structure) => {
    structure.T_Insp_Condition_Hist.DK_DURATION = deckValue.DK_DURATION;
    structure.T_Insp_Condition_Hist.DK_DURATION_CHANGES = deckValue.DK_DURATION_CHANGES;
    structure.InspEvnt.DKRATING = deckValue.DKRATING;

    structure.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION = deckValue.DECK_WS_SURF_DURATION;

    structure.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION_CHANGES = deckValue.DECK_WS_SURF_DURATION_CHANGES;
    structure.UserInsp.DECK_WS_COND_RATE = deckValue.DECK_WS_COND_RATE;
    structure.Bridge.DKSURFTYPE = deckValue.DKSURFTYPE;
    structure.Bridge.DKMEMBTYPE = deckValue.DKMEMBTYPE;
    structure.Bridge.DKPROTECT = deckValue.DKPROTECT;
    structure.UserBrdg.MAIN_WS_THICKNESS = deckValue.MAIN_WS_THICKNESS;
    structure.UserBrdg.APPR_WS_THICKNESS = deckValue.APPR_WS_THICKNESS;
    structure.UserBrdg.MAIN_WS_THICK_DATE = deckValue.MAIN_WS_THICK_DATE;
    structure.UserBrdg.APPR_WS_THICK_DATE = deckValue.APPR_WS_THICK_DATE;
    structure.UserBrdg.PROTECT_YEAR = deckValue.PROTECT_YEAR;
    structure.UserBrdg.PROTECT_NOTE = deckValue.PROTECT_NOTE;
    structure.UserInsp.DECK_CRACKING_METRIC = deckValue.DECK_CRACKING_METRIC;
    structure.UserBrdg.MAX_STRUCTURE_HEIGHT = deckValue.MAX_STRUCTURE_HEIGHT;
    structure.Bridge.DKSTRUCTYP = deckValue.DKSTRUCTYP;
    structure.Bridge.DECKWIDTH = deckValue.DECKWIDTH;
    structure.Bridge.LFTCURBSW = deckValue.LFTCURBSW;
    structure.Bridge.RTCURBSW = deckValue.RTCURBSW;
    structure.Bridge.BRIDGEMED = deckValue.BRIDGEMED;
  };

  const convertToInspModuleObject = (structure) => {
    deckData.value = {
      DK_DURATION: structure?.T_Insp_Condition_Hist?.DK_DURATION,
      DK_DURATION_CHANGES: structure?.T_Insp_Condition_Hist?.DK_DURATION_CHANGES,
      DKRATING: structure?.InspEvnt?.DKRATING,
      DECK_WS_SURF_DURATION: structure?.T_Insp_Condition_Hist?.DECK_WS_SURF_DURATION,
      DECK_WS_SURF_DURATION_CHANGES: structure?.T_Insp_Condition_Hist?.DECK_WS_SURF_DURATION_CHANGES,
      DECK_WS_COND_RATE: structure?.UserInsp?.DECK_WS_COND_RATE,
      DKSURFTYPE: structure.Bridge.DKSURFTYPE,
      DKMEMBTYPE: structure.Bridge.DKMEMBTYPE,
      DKPROTECT: structure.Bridge.DKPROTECT,
      MAIN_WS_THICKNESS: structure.UserBrdg.MAIN_WS_THICKNESS,
      APPR_WS_THICKNESS: structure.UserBrdg.APPR_WS_THICKNESS,
      MAIN_WS_THICK_DATE: structure.UserBrdg.MAIN_WS_THICK_DATE,
      APPR_WS_THICK_DATE: structure.UserBrdg.APPR_WS_THICK_DATE,
      PROTECT_YEAR: structure.UserBrdg.PROTECT_YEAR,
      PROTECT_NOTE: structure.UserBrdg.PROTECT_NOTE,
      DECK_CRACKING_METRIC: structure.UserInsp.DECK_CRACKING_METRIC,
      MAX_STRUCTURE_HEIGHT: structure.UserBrdg.MAX_STRUCTURE_HEIGHT,
      DKSTRUCTYP: structure.Bridge.DKSTRUCTYP,
      DECKWIDTH: structure.Bridge.DECKWIDTH,
      LFTCURBSW: structure.Bridge.LFTCURBSW,
      RTCURBSW: structure.Bridge.RTCURBSW,
      BRIDGEMED: structure.Bridge.BRIDGEMED,
    };
  };

  const getInspLinkDeck = async (brkey, inspectionId) => {
    //clear idb object
    deckData.value.inspection = {};

    let url = INSPECTION_LINKS_ENDPOINT.GET_DECK.replace("{brkey}", brkey).replace("{inspectionId}", inspectionId);

    inspectionLinksStore.isSearching = true;
    await axios
      .get(url)
      .then((resp) => {
        inspectionLinksStore.isSearching = false;
        convertToInspLinkObject(resp.data.data);
      })
      .catch((e) => {
        LOGGER.logException(e);
        inspectionLinksStore.isSearching = false;
      });
  };

  const convertToInspLinkObject = (deck) => {
    deckData.value = {
      BRKEY: deck.brkey,
      INSPECTIONID: deck.inspectionId,
      DK_DURATION: deck.dkDuration,
      DK_DURATION_CHANGES: "",
      DKRATING: deck.dkRating,
      DECK_WS_SURF_DURATION: deck.deckWsSurfDuration,
      DECK_WS_SURF_DURATION_CHANGES: "",
      DECK_WS_COND_RATE: deck.deckWsCondRate,
      DKSURFTYPE: deck.dkSurfType,
      DKMEMBTYPE: deck.dkMembTyp,
      DKPROTECT: deck.dkProtect,
      MAIN_WS_THICKNESS: deck.mainWsThickness,
      APPR_WS_THICKNESS: deck.apprWsThickness,
      MAIN_WS_THICK_DATE: deck.mainWsThickDate,
      APPR_WS_THICK_DATE: deck.apprWsThickDate,
      PROTECT_YEAR: deck.protectYear,
      PROTECT_NOTE: deck.protectNote,
      DECK_CRACKING_METRIC: deck.deckCrackingMetric,
      MAX_STRUCTURE_HEIGHT: deck.maxStructureHeight,
      DKSTRUCTYP: deck.dkStrucType,
      DECKWIDTH: deck.deckWidth,
      LFTCURBSW: deck.lftCurbSw,
      RTCURBSW: deck.rtCurbSw,
      BRIDGEMED: deck.bridgeMed,
      inspectionComments: deck.inspectionComments,
    };
  };

  return {
    getDeckObject,
    saveDeck,
    loadDeckObject,
  };
});

<template>
  <v-row>
    <ExpandCollapseToggle id="culvertPanelToggle" @toggleAllPanels="toggleAllPanels" styleClass="mt-3" :panel="panel" />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Culvert Conditions</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text eager>
          <v-form ref="culvertConditions" v-model="valid">
            <LabelSelect
              id="sel_Culvert_Condition_Rating"
              label="1A03 Culvert Condition Rating"
              showDurationIcon
              :refTable="REFERENCE_TABLE.CONDITION_RATING"
              :isEditable="isEditable"
              isRequired
              v-model="culvertValue.CULVRATING"
              :tooltip="getHistDuration(culvertValue.CULV_DURATION, culvertValue.CULV_DURATION_CHANGES)"
              @update:modelValue="
                {
                  culvertConditionUpdated = true;
                  conditionUpdated = true;
                }
              "
            ></LabelSelect>

            <CommentTypeTextField
              v-if="moduleType == MODULE_TYPES.INSPECTION"
              label="Overall Culvert Notes"
              id="field_Overall_Culvert_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.CULVERT"
              :readonly="!isEditable"
              :counter="inspCommentMaxLength"
              :maxlength="inspCommentMaxLength"
            />
            <ManagementCommentTypeTextField
              v-else
              label="Overall Culvert Notes"
              id="field_Overall_Culvert_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.CULVERT"
              :readonly="!isEditable"
              :counter="inspCommentMaxLength"
              :maxlength="inspCommentMaxLength"
              :comments="culvertValue.inspectionComments"
              :brkey="culvertValue.BRKEY"
              :inspectionId="culvertValue.INSPECTIONID"
              @updateComment="(comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.CULVERT)"
            />
            <LabelSelect
              id="combustible_Material_Under_Bridge"
              label="6B48 Combustible Material Under Bridge"
              isRequired
              :refTable="REFERENCE_TABLE.MATERIAL_STORED_UNDER"
              :isEditable="isEditable"
              v-model="culvertValue.MAT_STORE_UND"
            ></LabelSelect>

            <CommentTypeTextField
              v-if="moduleType == MODULE_TYPES.INSPECTION"
              label="Combustible Material Under Bridge Notes"
              id="field_Combustible_Material_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.COMBUSTIBLE_MATERIALS_COMMENTS"
              :readonly="!isEditable"
              :counter="inspCommentMaxLength"
              :maxlength="inspCommentMaxLength"
            />
            <ManagementCommentTypeTextField
              v-else
              label="Combustible Material Under Bridge Notes"
              id="field_Combustible_Material_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.COMBUSTIBLE_MATERIALS_COMMENTS"
              :readonly="!isEditable"
              :counter="inspCommentMaxLength"
              :maxlength="inspCommentMaxLength"
              :comments="culvertValue.inspectionComments"
              :brkey="culvertValue.BRKEY"
              :inspectionId="culvertValue.INSPECTIONID"
              @updateComment="
                (comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.COMBUSTIBLE_MATERIALS_COMMENTS)
              "
            />

            <LabelCheckbox
              labelXl="3"
              labelLg="4"
              labelMd="5"
              labelSm="6"
              class="labelCheckbox"
              id="checkbox_New_Coating_Since_Last_Inspection"
              label="6B35 New Coating Since Last Inspection"
              :isEditable="isEditable"
              v-model="culvertValue.NEW_PAINT"
            >
            </LabelCheckbox>

            <LabelSelect
              id="sel_Protective_Coating_Rating"
              label="6B36 Protective Coating Rating"
              showDurationIcon
              :refTable="REFERENCE_TABLE.PAINT_CONDITION_RATING"
              :isEditable="isEditable"
              isRequired
              v-model="culvertValue.PAINT_COND_RATE"
              :tooltip="getHistDuration(culvertValue.PANIT_DURATION, culvertValue.PANIT_DURATION_CHANGES)"
              @update:modelValue="
                {
                  protectiveCoatingUpdated = true;
                  conditionUpdated = true;
                }
              "
            ></LabelSelect>

            <LabelSelect
              id="sel_Protective_Coating_Extent_Rating"
              label="6B37 Protective Coating (Extent) Rating"
              showDurationIcon
              isRequired
              :refTable="REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT"
              :isEditable="isEditable"
              v-model="culvertValue.PAINT_EXTENT_RATE"
              :tooltip="getHistDuration(culvertValue.PAINT_EXT_DURATION, culvertValue.PAINT_EXT_DURATION_CHANGES)"
              @update:modelValue="
                {
                  protectiveCoatingExtentUpdated = true;
                  conditionUpdated = true;
                }
              "
            ></LabelSelect>
            <CommentTypeTextField
              v-if="moduleType == MODULE_TYPES.INSPECTION"
              label="Overall Protective Coating Notes"
              id="field_Overall_Protective_Coating_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.PAINT_OVERALL"
              :readonly="!isEditable"
              :counter="inspCommentMaxLength"
              :maxlength="inspCommentMaxLength"
            />
            <ManagementCommentTypeTextField
              v-else
              label="Overall Protective Coating Notes"
              id="field_Overall_Protective_Coating_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.PAINT_OVERALL"
              :readonly="!isEditable"
              :counter="inspCommentMaxLength"
              :maxlength="inspCommentMaxLength"
              :comments="culvertValue.inspectionComments"
              :brkey="culvertValue.BRKEY"
              :inspectionId="culvertValue.INSPECTIONID"
              @updateComment="(comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.PAINT_OVERALL)"
            />
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />

          <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />

          <h3>Culvert Details</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <v-row class="ma-0" v-if="moduleType == MODULE_TYPES.INSPECTION">
            <v-col sm="6" md="6" lg="6">
              <LabelText
                labelXl="6"
                labelLg="7"
                noGrid
                labelMd="7"
                labelSm="7"
                textXl="6"
                textLg="5"
                textMd="5"
                textSm="5"
                id="number_Main_Spans"
                label="5B11 Main Spans"
                v-model="culvertValue.MAINSPANS"
                type="number"
                maxlength="22"
              >
              </LabelText>
            </v-col>
            <v-col sm="6" md="6" lg="6">
              <LabelText
                labelXl="6"
                noGrid
                labelLg="7"
                labelMd="7"
                labelSm="7"
                textXl="6"
                textLg="5"
                textMd="5"
                textSm="5"
                id="number_Approach_Spans"
                label="5B14 Approach Spans"
                v-model="culvertValue.APPSPANS"
                type="number"
                maxlength="22"
              >
              </LabelText>
            </v-col>
          </v-row>
          <v-row class="ma-0" v-if="moduleType == MODULE_TYPES.MANAGEMENT">
            <v-col sm="6" md="6" lg="6">
              <LabelText
                labelXl="6"
                labelLg="7"
                noGrid
                labelMd="7"
                labelSm="7"
                textXl="6"
                textLg="5"
                textMd="5"
                textSm="5"
                id="total_number_of_spans"
                label="#### Total Number of Spans"
                v-model="culvertValue.totalNumberOfSpans"
                type="number"
                maxlength="22"
              >
              </LabelText>
            </v-col>
          </v-row>

          <v-data-table
            v-if="moduleType == MODULE_TYPES.INSPECTION"
            class="noScrollTable compactTable"
            :items-per-page="-1"
            :headers="headers"
            :items="culvertInformation"
            return-object
            hide-default-footer
          >
            <template v-slot:[`header.OPENING_TYPE`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.CULVERT_LENGTH_FT`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.MIN_FILL_HEIGHT_FT`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.MAX_FILL_HEIGHT_FT`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.EFF_WIDTH_FT`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.TIE_TYPE`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.FLOOR_TYPE`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">
                  {{
                    item?.OPENING_TYPE
                      ? configStore.getReferenceValue(REFERENCE_TABLE.CULVERT_OPENING_TYPE, item?.OPENING_TYPE)
                      : ""
                  }}
                </td>
                <td class="text-center">
                  {{ item?.CULVERT_LENGTH_FT ? item.CULVERT_LENGTH_FT + " ft" : "" }}
                </td>
                <td class="text-center">
                  {{ item?.MIN_FILL_HEIGHT_FT ? item.MIN_FILL_HEIGHT_FT + " ft" : "" }}
                </td>
                <td class="text-center">
                  {{ item?.MAX_FILL_HEIGHT_FT ? item.MAX_FILL_HEIGHT_FT + " ft" : "" }}
                </td>
                <td class="text-center">
                  {{ item?.EFF_WIDTH_FT ? item.EFF_WIDTH_FT + " ft" : "" }}
                </td>
                <td class="text-center">
                  {{
                    item?.TIE_TYPE
                      ? configStore.getReferenceValue(REFERENCE_TABLE.CULVERT_TIE_TYPE, item?.TIE_TYPE)
                      : ""
                  }}
                </td>
                <td class="text-center">
                  {{
                    item?.FLOOR_TYPE
                      ? configStore.getReferenceValue(REFERENCE_TABLE.CULVERT_FLOOR_TYPE, item?.FLOOR_TYPE)
                      : ""
                  }}
                </td>
              </tr>
            </template>
            <template v-slot:bottom> </template>
          </v-data-table>
          <v-data-table
            v-if="moduleType == MODULE_TYPES.MANAGEMENT"
            class="noScrollTable compactTable"
            :items-per-page="-1"
            :headers="headers"
            :items="culvertValue.culvertStructureSpanSets"
            return-object
            hide-default-footer
          >
            <template v-slot:[`header.spanConfigDesignation`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.culvertFloorType`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.culvertOpeningType`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.culvertLengthCenterline`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.minFillHeight`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.maxFillHeight`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.hydraulicOpeningEffWidth`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">
                  {{ item.spanConfigDesignation }}
                </td>
                <td class="text-center">
                  {{ configStore.getReferenceValue(REFERENCE_TABLE.CULVERT_FLOOR_TYPE, item.culvertFloorType) }}
                </td>
                <td class="text-center">
                  {{ configStore.getReferenceValue(REFERENCE_TABLE.CULVERT_OPENING_TYPE, item.culvertOpeningType) }}
                </td>
                <td class="text-center">
                  {{ item.culvertLengthCenterline ? item.culvertLengthCenterline + " ft" : "" }}
                </td>
                <td class="text-center">
                  {{ item.minFillHeight ? item.minFillHeight + " ft" : "" }}
                </td>
                <td class="text-center">
                  {{ item.maxFillHeight ? item.maxFillHeight + " ft" : "" }}
                </td>
                <td class="text-center">
                  {{ item.hydraulicOpeningEffWidth ? item.hydraulicOpeningEffWidth + " ft" : "" }}
                </td>
              </tr>
            </template>
            <template v-slot:bottom> </template>
          </v-data-table>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(2)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(2)" icon="fa-solid fa-minus" />
          <h3>Culvert Inspection Notes</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <CommentTypeTextField
            v-if="moduleType == MODULE_TYPES.INSPECTION"
            label="Top Slab"
            id="field_Top_Slab"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_TOP_SLAB"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />
          <ManagementCommentTypeTextField
            v-else
            label="Top Slab"
            id="field_Top_Slab"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_TOP_SLAB"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="culvertValue.inspectionComments"
            :brkey="culvertValue.BRKEY"
            :inspectionId="culvertValue.INSPECTIONID"
            @updateComment="(comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.CULVERT_TOP_SLAB)"
          />

          <CommentTypeTextField
            v-if="moduleType == MODULE_TYPES.INSPECTION"
            label="Barrel"
            id="field_Barrel"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_BARREL"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />
          <ManagementCommentTypeTextField
            v-else
            label="Barrel"
            id="field_Barrel"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_BARREL"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="culvertValue.inspectionComments"
            :brkey="culvertValue.BRKEY"
            :inspectionId="culvertValue.INSPECTIONID"
            @updateComment="(comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.CULVERT_BARREL)"
          />

          <CommentTypeTextField
            v-if="moduleType == MODULE_TYPES.INSPECTION"
            label="Floor/Paving"
            id="field_Floor_Paving"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_FLOOR_PAVE"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />
          <ManagementCommentTypeTextField
            v-else
            label="Floor/Paving"
            id="field_Floor_Paving"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_FLOOR_PAVE"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="culvertValue.inspectionComments"
            :brkey="culvertValue.BRKEY"
            :inspectionId="culvertValue.INSPECTIONID"
            @updateComment="(comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.CULVERT_FLOOR_PAVE)"
          />

          <CommentTypeTextField
            v-if="moduleType == MODULE_TYPES.INSPECTION"
            label="Headwall"
            id="field_Headwall"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_HEADWALL"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />
          <ManagementCommentTypeTextField
            v-else
            label="Headwall"
            id="field_Headwall"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_HEADWALL"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="culvertValue.inspectionComments"
            :brkey="culvertValue.BRKEY"
            :inspectionId="culvertValue.INSPECTIONID"
            @updateComment="(comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.CULVERT_HEADWALL)"
          />

          <CommentTypeTextField
            v-if="moduleType == MODULE_TYPES.INSPECTION"
            label="Wings"
            id="field_Wings"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_WINGS"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />
          <ManagementCommentTypeTextField
            v-else
            label="Wings"
            id="field_Wings"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_WINGS"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="culvertValue.inspectionComments"
            :brkey="culvertValue.BRKEY"
            :inspectionId="culvertValue.INSPECTIONID"
            @updateComment="(comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.CULVERT_WINGS)"
          />

          <CommentTypeTextField
            v-if="moduleType == MODULE_TYPES.INSPECTION"
            label="Settlement"
            id="field_Settlement"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_SETTLEMENT"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />
          <ManagementCommentTypeTextField
            v-else
            label="Settlement"
            id="field_Settlement"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_SETTLEMENT"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="culvertValue.inspectionComments"
            :brkey="culvertValue.BRKEY"
            :inspectionId="culvertValue.INSPECTIONID"
            @updateComment="(comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.CULVERT_SETTLEMENT)"
          />

          <CommentTypeTextField
            v-if="moduleType == MODULE_TYPES.INSPECTION"
            label="Debris"
            id="field_Debris"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_DEBRIS"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />
          <ManagementCommentTypeTextField
            v-else
            label="Debris"
            id="field_Debris"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_DEBRIS"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="culvertValue.inspectionComments"
            :brkey="culvertValue.BRKEY"
            :inspectionId="culvertValue.INSPECTIONID"
            @updateComment="(comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.CULVERT_DEBRIS)"
          />

          <CommentTypeTextField
            v-if="moduleType == MODULE_TYPES.INSPECTION"
            label="Footing"
            id="field_Footing"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_FOOTING"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />
          <ManagementCommentTypeTextField
            v-else
            label="Footing"
            id="field_Footing"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_FOOTING"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :comments="culvertValue.inspectionComments"
            :brkey="culvertValue.BRKEY"
            :inspectionId="culvertValue.INSPECTIONID"
            @updateComment="(comment) => setUpdatedComment(comment, INSPECTION_COMMENTS.CULVERT_FOOTING)"
          />
        </v-expansion-panel-text>
      </v-expansion-panel> </v-expansion-panels
  ></v-row>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import { getHistDuration } from "@/composables/util";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { clone } from "@/util/clone";
import { useConfigStore } from "@/stores/config";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import LabelText from "@/components/common/LabelText.vue";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";
import { useCulvertStore } from "@/components/inspection/store/culvert";
import { useQuickSearchStore } from "@/stores/quickSearch";
import { MODULE_TYPES } from "@/constants/InspectionConstants";
import { storeToRefs } from "pinia";
import ManagementCommentTypeTextField from "@/components/common/ManagementCommentTypeTextField.vue";
import { INSPECTION_LINKS_PERMISSIONS } from "@/rbac";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";
import { useUserPermissionStore } from "@/stores/userPermission";
import { useInspectionLinksStore } from "@/stores/inspectionLinks";
import { CULVERT_DETAILS_TABLE_HEADER } from "@/constants/culvert";

export default {
  name: "InspectionCulvert",
  props: {
    moduleType: { default: MODULE_TYPES.INSPECTION },
  },
  setup(props) {
    const allPanels = [0, 1, 2];
    const panel = ref(allPanels);
    const inspectionStore = useInspectionStore();
    const userPermissionStore = useUserPermissionStore();
    let structure = computed(() => inspectionStore.selectedInspection);
    let culvertInformation = ref([]);
    const configStore = useConfigStore();
    let valid = ref(true);
    const culvertStore = useCulvertStore();
    let culvertConditions = ref(null);
    let culvertValue = ref({});
    const quickSearchStore = useQuickSearchStore();
    const inspectionLinksStore = useInspectionLinksStore();
    const structureHeader = computed(() => quickSearchStore.getStructure());
    const { hasQuickSearchSucceeded } = storeToRefs(quickSearchStore);
    let resetDirty = ref(false);
    const headers = ref(CULVERT_DETAILS_TABLE_HEADER[props.moduleType]);

    const hasAccessToEdit = computed(() =>
      userPermissionStore.checkUserPermission(
        INSPECTION_LINKS_PERMISSIONS[ACCESS_LEVELS.EDIT],
        userPermissionStore.getUserPermissionByBrkeyFromCache(culvertValue.value.BRKEY)
      )
    );

    const isEditable = computed(() => {
      if (props.moduleType == MODULE_TYPES.MANAGEMENT) {
        return quickSearchStore.hasQuickSearchSucceeded && culvertValue.value?.BRKEY && hasAccessToEdit.value;
      } else {
        return inspectionStore.isEditable;
      }
    });

    if (!isEditable.value) {
      panel.value = allPanels;
    }
    const inspCommentMaxLength = computed(() => {
      return configStore.getEnvConfigValue(ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH);
    });
    let culvertConditionUpdated = ref(false);
    let protectiveCoatingUpdated = ref(false);
    let protectiveCoatingExtentUpdated = ref(false);
    let conditionUpdated = ref(false);

    const updateDuration = () => {
      if (!structure.value.T_Insp_Condition_Hist) {
        structure.value.T_Insp_Condition_Hist = {};
      }
      if (culvertConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.CULV_DURATION_CHANGES = inspectionStore.setDurationChanges(
          structure.value.T_Insp_Condition_Hist.CULV_DURATION_CHANGES
        );
        structure.value.T_Insp_Condition_Hist.CULV_DURATION = inspectionStore.setDuration(
          structure.value.T_Insp_Condition_Hist.CULV_DURATION,
          structure.value.T_Insp_Condition_Hist.CULV_DURATION_CHANGES
        );
      }
      if (protectiveCoatingUpdated.value) {
        structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES = inspectionStore.setDurationChanges(
          structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES
        );
        structure.value.T_Insp_Condition_Hist.PANIT_DURATION = inspectionStore.setDuration(
          structure.value.T_Insp_Condition_Hist.PANIT_DURATION,
          structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES
        );
      }

      if (protectiveCoatingExtentUpdated.value) {
        structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES = inspectionStore.setDurationChanges(
          structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES
        );
        structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION = inspectionStore.setDuration(
          structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION,
          structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES
        );
      }

      culvertConditionUpdated.value = false;
      protectiveCoatingUpdated.value = false;
      protectiveCoatingExtentUpdated.value = false;
      conditionUpdated.value = false;
    };

    const sort = (arr) => {
      arr.value = arr.value?.sort(function (a, b) {
        return a.OPENING_KEY - b.OPENING_KEY;
      });
    };
    async function validate() {
      culvertConditions.value.validate();
    }

    onMounted(async () => {
      await validate();
      await readData();
    });

    const getCulvertInformationDetails = () => {
      if (structure.value.T_Culvert_Opening?.[0]) {
        culvertInformation.value = clone(structure.value.T_Culvert_Opening);
        for (const element of culvertInformation.value) {
          element.CULVERT_LENGTH_FT = configStore.getMetricToEnglishConversions(
            PAIR_CODE.METERS_TO_FEET,
            element.CULVERT_LENGTH,
            ROUNDING_FACTOR.ROUND
          );
          element.MIN_FILL_HEIGHT_FT = configStore.getMetricToEnglishConversions(
            PAIR_CODE.METERS_TO_FEET,
            element.MIN_FILL_HEIGHT,
            ROUNDING_FACTOR.TENTH
          );
          element.MAX_FILL_HEIGHT_FT = configStore.getMetricToEnglishConversions(
            PAIR_CODE.METERS_TO_FEET,
            element.MAX_FILL_HEIGHT,
            ROUNDING_FACTOR.TENTH
          );
          element.EFF_WIDTH_FT = configStore.getMetricToEnglishConversions(
            PAIR_CODE.METERS_TO_FEET,
            element.EFF_WIDTH,
            ROUNDING_FACTOR.TENTH
          );

          element.readOnly = true;
        }
        sort(culvertInformation);
      }
    };

    getCulvertInformationDetails();
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    const setUpdatedComment = (comment, commentType) => {
      const foundComment = culvertValue.value.inspectionComments.find((c) => {
        return c.commentType === commentType;
      });
      if (foundComment) {
        foundComment.notes = comment.notes;
        foundComment.updateUserId = comment.updateUserId;
        foundComment.updateDateTime = comment.updateDateTime;
      } else {
        culvertValue.value.inspectionComments.push(comment);
      }
    };

    const readData = async () => {
      culvertValue.value = {};
      if (props.moduleType == MODULE_TYPES.MANAGEMENT && structureHeader.value?.BRKEY) {
        if (!hasQuickSearchSucceeded.value) {
          resetDirty.value = true;
          return;
        }
        await culvertStore.loadCulvertObject(
          props.moduleType,
          structureHeader.value.BRKEY,
          structureHeader.value.INSPECTIONID
        );

        culvertValue.value = culvertStore.getCulvertObject();
      } else if (props.moduleType == MODULE_TYPES.INSPECTION) {
        const structure = computed(() => inspectionStore.selectedInspection);
        await culvertStore.loadCulvertObject(props.moduleType, null, null, structure.value);
        culvertValue.value = culvertStore.getCulvertObject();
        inspectionStore.setDirtyFlag(false);
      }
      resetDirty.value = true;
    };

    const saveChanges = async () => {
      if (!valid.value) {
        inspectionStore.setShowValidationSnackbar(true);
      }
      inspectionStore.setShowValidationSnackbar(false);
      const structure = inspectionStore.selectedInspection;
      const isSuccess = await culvertStore.saveCulvert(props.moduleType, culvertValue.value, structure);
      if (isSuccess) {
        inspectionLinksStore.isDirty = false;
        readData();
        resetDirty.value = true;
      }
      return isSuccess;
    };

    const cancelChanges = async () => {
      //only called from Inspection Links
      culvertValue.value = culvertStore.getCulvertObject();

      // Using this flag to not set the dirty flag in watch
      resetDirty.value = true;
      inspectionLinksStore.isDirty = false;
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [structure.value.InspEvnt, structure.value.UserInsp, structure.value.T_Insp_Comment],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      [culvertValue],
      () => {
        // This watch will NOT be triggered during initial onMounted()
        if (props.moduleType === MODULE_TYPES.MANAGEMENT) {
          // for management module
          if (resetDirty.value) {
            inspectionLinksStore.isDirty = false;
            resetDirty.value = false;
          } else {
            inspectionLinksStore.isDirty = true;
          }
        } else if (props.moduleType === MODULE_TYPES.INSPECTION) {
          // for inspection  module
          if (resetDirty.value) {
            inspectionStore.setDirtyFlag(false);
            resetDirty.value = false;
          } else {
            inspectionStore.setDirtyFlag(true);
          }
        }
      },
      { deep: true }
    );

    watch(
      () => [props],
      () => {
        readData();
      },
      { deep: true }
    );

    return {
      protectiveCoatingUpdated,
      protectiveCoatingExtentUpdated,
      conditionUpdated,
      culvertConditionUpdated,
      updateDuration,
      culvertConditions,
      valid,
      validate,
      headers,
      panel,
      REFERENCE_TABLE,
      isEditable,
      structure,
      INSPECTION_COMMENTS,
      configStore,
      inspCommentMaxLength,
      getHistDuration,
      culvertInformation,
      toggleAllPanels,
      readData,
      saveChanges,
      cancelChanges,
      culvertValue,
      setUpdatedComment,
      MODULE_TYPES,
    };
  },
  components: {
    LabelSelect,
    CommentTypeTextField,
    LabelCheckbox,
    LabelText,
    DataTableHeader,
    ExpandCollapseToggle,
    ManagementCommentTypeTextField,
  },
};
</script>
<style scoped lang="scss">
.v-data-table :deep(th) {
  margin: 0 auto !important;
  text-align: center !important;
}
</style>

function buildFutureSchedule(structure) {
  return [
    {
      inspType: "1",
      show7A57Checkbox: false,
      showInterval: false,
      showInspDueDate: false,
      is7A57RequiredValue: null,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.FT_PREVIOUS_DATE,
      inspectionInterval: 0,
      inspectionDueDate: null,
    },
    {
      inspType: "2",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule?.ROU_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.ROU_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule?.ROU_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule?.ROU_NEXT_DATE,
    },
    {
      inspType: "3",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule?.UW_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.UW_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule?.UW_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule?.UW_NEXT_DATE,
    },
    {
      inspType: "4",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule?.NSTM_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.NSTM_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule?.NSTM_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule?.NSTM_NEXT_DATE,
    },
    {
      inspType: "5",
      show7A57Checkbox: false,
      showInterval: false,
      showInspDueDate: false,
      is7A57RequiredValue: null,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.DMG_PREVIOUS_DATE,
      inspectionInterval: 0,
      inspectionDueDate: null,
    },
    {
      inspType: "6",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule?.IND_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.IND_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule?.IND_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule?.IND_NEXT_DATE,
    },
    {
      inspType: "7",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule?.OS_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.OS_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule?.OS_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule?.OS_NEXT_DATE,
    },
    {
      inspType: "8",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule?.SERV_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.SERV_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule?.SERV_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule?.SERV_NEXT_DATE,
    },
    {
      inspType: "9",
      show7A57Checkbox: false,
      showInterval: false,
      showInspDueDate: false,
      is7A57RequiredValue: null,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.SCM_PREVIOUS_DATE,
      inspectionInterval: 0,
      inspectionDueDate: null,
    },
    {
      inspType: "P",
      show7A57Checkbox: false,
      showInterval: false,
      showInspDueDate: false,
      is7A57RequiredValue: null,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.PROB_PREVIOUS_DATE,
      inspectionInterval: 0,
      inspectionDueDate: null,
    },
    {
      inspType: "E",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule?.ELEM_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.ELEM_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule?.ELEM_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule?.ELEM_NEXT_DATE,
    },
    {
      inspType: "Q",
      show7A57Checkbox: false,
      showInterval: false,
      showInspDueDate: false,
      is7A57RequiredValue: null,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.QA_PREVIOUS_DATE,
      inspectionInterval: 0,
      inspectionDueDate: null,
    },
  ];
}
export { buildFutureSchedule };

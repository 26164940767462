export const TOKEN = {
  NUMBER_COMMA_SPACE: { A: { pattern: /^[0-9,\s]/, repeated: true } },
  NUMBER: { A: { pattern: /^\d/, repeated: true } },
  HIGHWAY_FEATURE: {
    H: {
      pattern: /^H/,
      transform: (v) => v.toUpperCase(),
    },
  },
  WATERWAY_FEATURE: {
    W: {
      pattern: /^[WF]/,
      transform: (v) => v.toUpperCase(),
    },
  },
  RAILROAD_FEATURE: {
    R: {
      pattern: /^R/,
      transform: (v) => v.toUpperCase(),
    },
  },
  UTILITY_FEATURE: {
    U: {
      pattern: /^U/,
      transform: (v) => v.toUpperCase(),
    },
  },
  OTHER_FEATURE: {
    O: {
      pattern: /^[PBDX]/,
      transform: (v) => v.toUpperCase(),
    },
  },
};
export const MASK = {
  NUMBER_COMMA_SPACE_REPEATING: {
    mask: "A",
    tokens: TOKEN.NUMBER_COMMA_SPACE,
  },
  NUMBER: { mask: "A", tokens: TOKEN.NUMBER },
  LATLONG_SECONDS: { mask: "##.##" },
  PHONE_NUMBER: { mask: "###-###-####" },
  FEATURES_TYPE_MASK_HIGHWAY: {
    mask: "HA",
    tokens: { ...TOKEN.HIGHWAY_FEATURE, ...TOKEN.NUMBER },
  },
  FEATURES_TYPE_MASK_WATERWAY: {
    mask: "WA",
    tokens: { ...TOKEN.WATERWAY_FEATURE, ...TOKEN.NUMBER },
  },
  FEATURES_TYPE_MASK_RAILROAD: {
    mask: "RA",
    tokens: { ...TOKEN.RAILROAD_FEATURE, ...TOKEN.NUMBER },
  },
  FEATURES_TYPE_MASK_UTILITY: {
    mask: "UA",
    tokens: { ...TOKEN.UTILITY_FEATURE, ...TOKEN.NUMBER },
  },
  FEATURES_TYPE_MASK_OTHER: {
    mask: "OA",
    tokens: { ...TOKEN.OTHER_FEATURE, ...TOKEN.NUMBER },
  },
};
